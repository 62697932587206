import React, { useMemo } from 'react';

import useDetail from 'components/event/useDetail';
import { useNavigate, useParams } from 'react-router-dom';
import { UiComponent } from 'components';
import { IQuestion, QuestionType } from 'types';
import * as API from 'api/event';

function Question() {
  const navigate = useNavigate();
  const { id, type } = useParams();
  const { event } = useDetail();
  const { title, questions } = useMemo(() => {
    if (!event) return { title: undefined, questions: undefined };

    if (type === 'additional') {
      return {
        title: event.additionalInfo?.title,
        questions: event.additionalInfo?.questions,
      };
    }

    return {
      title: event.feedBackInfo?.title,
      questions: event.feedBackInfo?.questions,
    };
  }, [event]);

  const handleDone = () => {
    alert('저장완료');
    navigate(-1);
  };

  const handleAdditional = (title: string, questions: IQuestion[]) => {
    API.putAdditional({
      id: Number(id),
      additionalInfo: { title, questions },
    }).then(handleDone);
  };

  const handleFeedback = (title: string, questions: IQuestion[]) => {
    API.putFeedBack({
      id: Number(id),
      feedBackInfo: { title, questions },
    }).then(handleDone);
  };

  const handleSave = (title: string, questions: IQuestion[]) => {
    if (type === 'additional') handleAdditional(title, questions);
    else if (type === 'feedback') handleFeedback(title, questions);
  };

  if (!event) return null;
  return (
    <div>
      <UiComponent.Forms
        title={title}
        questions={questions}
        type={type ? (type as QuestionType) : 'additional'}
        onSave={handleSave}
      />
    </div>
  );
}

export default Question;
