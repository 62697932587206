import { useMemo, useState } from 'react';
import { IEvent } from 'types';
import * as API from 'api/event';

interface Props {
  event: IEvent | null;
}

function useEditor({ event }: Props) {
  const [value, setValue] = useState('');
  const [isOpenEditor, setIsOpenEditor] = useState(false);
  const [type, setType] = useState<'desc' | 'notice'>('desc');

  const title = useMemo(
    () => (type === 'desc' ? '행사 상세' : '행사 유의사항'),
    [type],
  );

  const handleOpen = (type: 'desc' | 'notice') => {
    setIsOpenEditor(true);
    setType(type);
    const value = type === 'desc' ? event?.description : event?.notice;
    setValue(value || '');
  };

  const handleClose = () => setIsOpenEditor(false);

  const handleDesc = (data: string) => {
    API.putDescription({ id: event?.id || 0, description: data }).then(() => {
      alert('저장되었습니다.');
      setIsOpenEditor(false);
    });
  };

  const handleNotice = (data: string) => {
    API.putNotice({ id: event?.id || 0, notice: data }).then(() => {
      alert('저장되었습니다.');
      setIsOpenEditor(false);
    });
  };

  const handleSaveEditor = (data: string) => {
    if (type === 'desc') handleDesc(data);
    else if (type === 'notice') handleNotice(data);
  };

  return {
    title,
    value,
    isOpenEditor,
    handleOpen,
    handleClose,
    handleSaveEditor,
  };
}

export default useEditor;
