import React from 'react';

import { FormComponent, UiComponent } from 'components';
import * as BoxStyles from 'components/ui/Box/style/box';
import { useCheck, useDate } from 'hooks';

const CHECK_LIST: {
  id: string;
  displayValue: string;
}[] = [
  { id: '1', displayValue: '상세' },
  { id: '2', displayValue: '외부' },
  { id: '3', displayValue: 'instagram' },
  { id: '4', displayValue: 'youtube' },
];

function Filter() {
  const dateState = useDate({});
  const checkListProps = useCheck({
    uniqueKey: 'id',
    data: CHECK_LIST,
    isAllChecked: true,
  });

  return (
    <UiComponent.Box>
      <FormComponent.Form>
        <div css={BoxStyles.rows}>
          <UiComponent.FilterTitle title="검색어" />
          <FormComponent.Select
            options={[
              { name: '콘텐츠명', value: '1' },
              { name: 'ID', value: '2' },
            ]}
          />
          <FormComponent.Input css={{ width: '500px' }} />
        </div>
        <div css={BoxStyles.rows}>
          <UiComponent.FilterTitle title="기간" />
          <FormComponent.Select options={[{ name: '등록일', value: '1' }]} />
          <FormComponent.DateRangePicker {...dateState} />
        </div>
        <div css={[BoxStyles.rows]}>
          <UiComponent.FilterTitle title="콘텐츠 상세유형" />
          <div css={[BoxStyles.rows, { gap: '0 24px' }]}>
            <FormComponent.CheckList {...checkListProps} uniqueKey="id" />
          </div>
        </div>
      </FormComponent.Form>
    </UiComponent.Box>
  );
}

export default Filter;
