import React, { useEffect } from 'react';
import { UiComponent } from 'components';
import * as API from 'api/additional';
import { IAdditional, IQuestion } from 'types';
import { useNavigate, useParams } from 'react-router-dom';
import { EVENT_MENU_URL } from 'data/menu';

function Form() {
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [additional, setAdditional] = React.useState<IAdditional | null>(null);
  const navigate = useNavigate();

  const onCreate = (title: string, questions: IQuestion[]) => {
    API.post({ title, questions }).then(() =>
      navigate(`${EVENT_MENU_URL['추가정보 템플릿']}`),
    );
  };

  const onUpdate = (title: string, questions: IQuestion[]) => {
    if (!additional) return;

    API.put({ ...additional, title, questions }).then(() =>
      navigate(`${EVENT_MENU_URL['추가정보 템플릿']}`),
    );
  };

  const onSave = (title: string, questions: IQuestion[]) => {
    if (id === undefined) onCreate(title, questions);
    else onUpdate(title, questions);
  };

  useEffect(() => {
    if (!id) {
      setIsLoaded(true);
      return;
    }

    API.get(Number(id))
      .then((res) => {
        if (res.data) setAdditional(res.data);
      })
      .finally(() => setIsLoaded(true));
  }, []);

  if (!isLoaded) return null;

  return (
    <div>
      <UiComponent.Forms
        title={additional?.title}
        questions={additional?.questions}
        type="additional"
        onSave={onSave}
      />
    </div>
  );
}

export default Form;
