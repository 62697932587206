import { CSSObject } from '@emotion/react';
import icSelect from '../static/select.svg';
import icSelectDisabled from '../static/select_disabled.svg';

interface IWrapStyleOptions {
  readonly?: boolean;
}

const wrap =
  ({ readonly }: IWrapStyleOptions) =>
  (): CSSObject => ({
    width: '218px',
    height: '36px',
    border: '1px solid #ddd',
    borderRadius: '3px',
    fontSize: '14px',
    color: '#333',
    appearance: 'none',
    padding: '0 20px 0 14px',
    background: `url(${icSelect}) no-repeat`,
    backgroundPosition: 'right 10px center',
    ':focus': {
      border: '1px solid #333',
      outline: 'none',
    },
    ':disabled': {
      color: '#999',
      background: `#fafafa url(${icSelectDisabled}) no-repeat 92% 50%`,
    },

    ...(readonly && {
      fontWeight: 'bolder',
      outline: 'none',
      pointerEvents: 'none',
    }),
  });

export { wrap };
