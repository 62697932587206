import dayjs from 'dayjs';
import { IContent } from 'types';
import { getImageUrl } from 'utils';

const interviewContent: IContent[] = [
  {
    id: 10001,
    title: '저에게 연봉인상은 활력소 입니다!',
    externalUrl: '',
    pageTitle: '열 네번째 봉터뷰',
    thumbnail: getImageUrl('i14.jpeg', 1),
    desc: new Array(4)
      .fill('')
      .map((_, i) => getImageUrl(`i14-${i + 1}.jpeg`, 1)),
    type: 'internal',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 10002,
    title: '정말 좋게 미친 사람들이 모인 곳이에요.',
    pageTitle: '열세 번째 봉터뷰',
    externalUrl: '',
    thumbnail: getImageUrl('i13.jpeg', 1),
    desc: new Array(4)
      .fill('')
      .map((_, i) => getImageUrl(`i13-${i + 1}.jpeg`, 1)),
    type: 'internal',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 10003,
    title: '연봉인상은 취미이다',
    pageTitle: '열두 번째 봉터뷰',
    externalUrl: '',
    thumbnail: getImageUrl('i12.jpeg', 1),
    desc: new Array(3)
      .fill('')
      .map((_, i) => getImageUrl(`i12-${i + 1}.jpeg`, 1)),
    type: 'internal',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 10004,
    title:
      '제게 연봉인상은 휴가처럼 지루한 일상을 견딜 수 있게 하는 원동력입니다.',
    pageTitle: '열한 번째 봉터뷰',
    externalUrl: '',
    thumbnail: getImageUrl('i11.jpeg', 1),
    desc: new Array(3)
      .fill('')
      .map((_, i) => getImageUrl(`i11-${i + 1}.jpeg`, 1)),
    type: 'internal',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 10005,
    title: '나에게 연봉인상이란 맛집이다!',
    pageTitle: '열 번째 봉터뷰',
    externalUrl: '',
    thumbnail: getImageUrl('i10.jpeg', 1),
    desc: new Array(3)
      .fill('')
      .map((_, i) => getImageUrl(`i10-${i + 1}.jpeg`, 1)),
    type: 'internal',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 10006,
    title: '연봉인상은 에너지 드링크이다!',
    pageTitle: '아홉 번째 봉터뷰',
    externalUrl: '',
    thumbnail: getImageUrl('i9.jpeg', 1),
    desc: new Array(3)
      .fill('')
      .map((_, i) => getImageUrl(`i9-${i + 1}.jpeg`, 1)),
    type: 'internal',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 10007,
    title: '연봉인상은 비타민이다!🍋',
    pageTitle: '여덟 번째 봉터뷰',
    externalUrl: '',
    thumbnail: getImageUrl('i8.jpeg', 1),
    desc: new Array(3)
      .fill('')
      .map((_, i) => getImageUrl(`i8-${i + 1}.jpeg`, 1)),
    type: 'internal',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
];

export { interviewContent };
