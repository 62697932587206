import { CSSObject } from '@emotion/react';
import * as HeaderStyle from '../ui/Header/style';

const wrap: CSSObject = {
  display: 'flex',
  marginTop: HeaderStyle.wrap.height,
  height: `calc(100vh - ${HeaderStyle.wrap.height})`,
  background: '#f7f7f7',
};

const contents: CSSObject = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'scroll',
};

const contentsWrap: CSSObject = {
  minWidth: '1050px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  background: '#f7f7f7',
  overflow: 'scroll',
  '> div': {
    paddingLeft: '24px',
    paddingRight: '24px',
  },
};

export { wrap, contents, contentsWrap };
