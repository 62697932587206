import { IPartner } from 'types';
import * as API from 'api/partner';
import useModal from 'hooks/useModal';

interface Props {
  refetch: () => void;
  onDone: () => void;
}

function useAdd({ refetch, onDone }: Props) {
  const { handleAlert } = useModal();
  const onSave = (partner: IPartner) => {
    API.post(partner).then(() => {
      handleAlert('저장되었습니다.');
      onDone();
      refetch?.();
    });
  };

  return { onSave };
}

export default useAdd;
