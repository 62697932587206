import dayjs from 'dayjs';
import { IContent } from 'types';
import { getImageUrl } from 'utils';

const yvisContent: IContent[] = [
  {
    id: 30001,
    title: '연봉인상만의 연말파티 ‘연봉협상’의 현장🎉',
    pageTitle: '',
    externalUrl: 'https://www.instagram.com/p/C3EKbtVPhFG',
    thumbnail: getImageUrl('t1.jpeg', 3),
    desc: [],
    type: 'external',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 30002,
    title: '봉사단체 ’연봉인상‘에서 새 팀원을 찾습니다!',
    pageTitle: '',
    externalUrl: 'https://www.instagram.com/p/C4X6KABvwQw',
    thumbnail: getImageUrl('t2.jpeg', 3),
    desc: [],
    type: 'external',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 30003,
    title: '연봉인상, 우리의 여정, 그 시작🚩',
    pageTitle: '',
    externalUrl: 'https://www.instagram.com/p/CbIE4QPLxg9',
    thumbnail: getImageUrl('t3.jpeg', 3),
    desc: [],
    type: 'external',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
];

export { yvisContent };
