import { IPartner } from 'types';
import * as API from 'api/partner';
import useModal from 'hooks/useModal';

interface Props {
  partner: IPartner | null;
  refetch: () => void;
  onDone: () => void;
}

function useEdit({ partner, refetch, onDone }: Props) {
  const { handleAlert } = useModal();
  const onSave = (data: IPartner) => {
    if (!partner) return;

    API.put({ ...data, id: partner.id }).then(() => {
      handleAlert('저장되었습니다.');
      onDone();
      refetch();
    });
  };

  return { onSave };
}

export default useEdit;
