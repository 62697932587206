import React from 'react';
import { RouteObject } from 'react-router-dom';
import { Login } from 'pages';
import { LayoutComponent } from 'components';

const unauthorizedApp: RouteObject = {
  element: <LayoutComponent.Unauthorize />,
  children: [
    {
      path: '/login',
      element: <Login />,
    },
  ],
};

export { unauthorizedApp };
