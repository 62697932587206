// import { NavLink, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'stores';
import useLoginForm from './useLoginForm';
import { login } from 'stores/auth';
import { ID, PW } from './constants';

function useLogin() {
  const dispatch = useAppDispatch();
  const formData = useLoginForm();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formData.validate()) return;
    const id = formData.idRef.current?.value || '';
    const pw = formData.pwRef.current?.value || '';
    if (ID === id && PW === pw) {
      dispatch(login());
    } else {
      alert('계정정보를 확인해주세요');
    }
  };

  return { ...formData, handleSubmit };
}

export default useLogin;
