import dayjs from 'dayjs';
import { INotice } from 'types';

export const data: INotice[] = [
  {
    id: 1,
    title: 'content-1',
    content: 'content-1',
    creator: 'admin',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updater: 'admin',
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    fixedTop: true,
  },
];
