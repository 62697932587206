// import { base } from './';
import { bannerContent } from 'data/banner';
import { yvisContent } from 'data/yvis';
import { interviewContent } from 'data/interview';
import { IContent } from 'types';

// const PATH = '/content';

const MOCK_DATA: IContent[] = [
  ...interviewContent,
  ...bannerContent,
  ...yvisContent,
];

const find = (id: number) => MOCK_DATA.find((item) => item.id === id);

// const get = (id: number) => base.get(`${PATH}/${id}`);
const get = (id: number) =>
  new Promise<{ data?: IContent }>((resolve) => resolve({ data: find(id) }));

// const getAll = () => base.get(PATH);
const getAll = () =>
  new Promise<{ data: IContent[] }>((resolve) => resolve({ data: MOCK_DATA }));

// const filter = (ids: number[]) => base.get(`${PATH}?ids=${ids.join(',')}`);
const filter = (ids: number[]) => {
  const data = MOCK_DATA.filter((item) => ids.includes(item.id));
  return new Promise<{ data: IContent[] }>((resolve) => resolve({ data }));
};

export { get, getAll, filter };
