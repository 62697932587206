import { ReactNode, useCallback } from 'react';
import { useAppDispatch } from 'stores';
import {
  closeModal,
  openAlert,
  openConfirm,
  openModal,
  clearModal,
} from 'stores/ui';
import { IConfirmModal } from 'types';

function useModal() {
  const dispatch = useAppDispatch();

  const handleAlert = useCallback(
    (alertContent: ReactNode, alertNext?: () => void) =>
      dispatch(
        openAlert({
          alertContent,
          alertNext,
        }),
      ),
    [],
  );

  const handleModal = (
    title: ReactNode,
    body: ReactNode,
    footer?: ReactNode,
    size?: 'small' | 'medium',
  ) =>
    dispatch(
      openModal({
        title,
        body,
        footer,
        size,
      }),
    );

  const handleConfirm = useCallback(
    (confirm: IConfirmModal) =>
      dispatch(
        openConfirm({
          ...confirm,
        }),
      ),
    [],
  );

  const handleClose = useCallback(() => dispatch(closeModal()), []);

  const handleClearModal = useCallback(() => dispatch(clearModal()), []);

  return {
    handleAlert,
    handleModal,
    handleConfirm,
    handleClose,
    handleClearModal,
  };
}

export default useModal;
