import React from 'react';
import icSidebar from '../static/btn_sidebar.svg';
import { wrap, side, contentsWrap } from './style';
import Menus from './Menus';

function SideNav() {
  const [isOpen, setIsOpen] = React.useState(true);

  const handleToggle = React.useCallback(() => setIsOpen((prev) => !prev), []);

  return (
    <nav css={wrap(isOpen)}>
      <img
        src={icSidebar}
        alt=""
        width={26}
        css={side(isOpen)}
        onClick={handleToggle}
      />
      <div css={contentsWrap(isOpen)}>
        <Menus />
      </div>
    </nav>
  );
}

export default SideNav;
