import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { FormComponent, ModalComponent, UiComponent } from 'components';
import { usePageOption } from 'hooks';
import { tableWrap, tableTop } from 'styles/table';
import { COUPON_STATUS_NAME, ICoupon } from 'types';
import { Link } from 'react-router-dom';
import { BsPlusLg } from 'react-icons/bs';
import * as API from 'api/coupon';

const COLS: string[] = [
  '쿠폰번호',
  '쿠폰유형',
  '상태',
  '지급대상',
  '사용처',
  '사용기한',
  '생성자',
  '생성일',
];

function List() {
  const [data, setData] = useState<ICoupon[]>([]);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [selectedCouponId, setSelectedCouponId] = useState<number>(-1);

  const pageState = usePageOption();
  const renderer: Record<
    (typeof COLS)[number],
    (data: ICoupon & { row: number }) => ReactNode
  > = useMemo(
    () => ({
      쿠폰번호: ({ id }: ICoupon) => <>{id}</>,
      쿠폰유형: ({ type }: ICoupon) => <>{type}</>,
      상태: ({ status }: ICoupon) => <>{COUPON_STATUS_NAME[status]}</>,
      지급대상: ({ id, issuedUserId, issuedUserName }: ICoupon) => (
        <>
          {issuedUserId && issuedUserName ? (
            <>
              {issuedUserName}
              <span>{'('}</span>
              <Link to="/users/1">{issuedUserId}</Link>
              <span>{')'}</span>
            </>
          ) : (
            <>
              <UiComponent.Button
                color="primary"
                variant="contained"
                onClick={() => setSelectedCouponId(id)}
              >
                지급
              </UiComponent.Button>
            </>
          )}
        </>
      ),
      사용처: ({ usedId }: ICoupon) => <>{usedId}</>,
      사용기한: ({ startAt, endAt }: ICoupon) => (
        <>{startAt && endAt ? `${startAt} - ${endAt}` : '-'}</>
      ),
      생성자: ({ creator }: ICoupon) => <>{creator}</>,
      생성일: ({ createdAt }: ICoupon) => <>{createdAt}</>,
    }),
    [],
  );

  const handleAdd = () => {
    if (data.length > 2) {
      setAlertMessage('쿠폰은 최대 3개까지 생성 가능합니다.');
      return;
    }

    API.post().then(() => {
      setAlertMessage('쿠폰이 발행되었습니다.');
      fetch();
    });
  };

  const fetch = () => {
    API.getAll().then((res) => {
      setData(res.data);
    });
  };

  const select = () => {
    if (selectedCouponId === -1) return;

    API.put(selectedCouponId).then(() => {
      setAlertMessage('쿠폰이 지급되었습니다.');
      fetch();
      setSelectedCouponId(-1);
    });
  };

  useEffect(() => fetch(), []);

  return (
    <>
      <UiComponent.Box>
        <div css={tableTop}>
          <p>{`총 ${data.length} 건`}</p>
          <div>
            <UiComponent.Button
              variant="contained"
              color="primary"
              onClick={handleAdd}
            >
              <BsPlusLg size={18} />
            </UiComponent.Button>
            <FormComponent.Select
              options={[
                { name: '생성일', value: '1' },
                { name: '사용일', value: '2' },
                { name: '발급일', value: '3' },
              ]}
            />
            <FormComponent.Select
              options={[
                { name: '20', value: '20' },
                { name: '50', value: '50' },
                { name: '100', value: '100' },
              ]}
            />
          </div>
        </div>
        <div css={tableWrap}>
          <UiComponent.Table
            cols={COLS}
            renderer={renderer}
            data={data}
            sticky
          />
        </div>
        <UiComponent.Pagination {...pageState} />
      </UiComponent.Box>
      {alertMessage !== '' && (
        <ModalComponent.Portal>
          <ModalComponent.Background>
            <ModalComponent.Alert onClose={() => setAlertMessage('')}>
              {alertMessage}
            </ModalComponent.Alert>
          </ModalComponent.Background>
        </ModalComponent.Portal>
      )}
      {selectedCouponId !== -1 && (
        <ModalComponent.Portal>
          <ModalComponent.Background>
            <ModalComponent.Modal
              title=""
              handleClose={() => setSelectedCouponId(-1)}
              footer={<></>}
            >
              <div
                css={{
                  display: 'flex',
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <p>최*수</p>
                <UiComponent.Button
                  color="primary"
                  variant="contained"
                  onClick={select}
                >
                  선택
                </UiComponent.Button>
              </div>
            </ModalComponent.Modal>
          </ModalComponent.Background>
        </ModalComponent.Portal>
      )}
    </>
  );
}

export default List;
