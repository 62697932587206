import { CSSObject } from '@emotion/react';
import { theme } from 'styles';

export const inputBase: CSSObject = {
  flex: 1,
  outline: 'none',
  borderWidth: '0 0 2px 0',
  borderStyle: 'solid',
  borderColor: '#eee',
  ':focus': {
    borderColor: theme.color.primary,
  },
};

export const inputSt: CSSObject = {
  flex: 1,
  outline: 'none',
  borderWidth: '0 0 2px 0',
  borderStyle: 'solid',
  borderColor: '#eee',
  height: '36px',
  fontSize: '18px',
  ':focus': {
    borderColor: theme.color.primary,
  },
};

export const modalWrap: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  width: '600px',
  '> li': {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px solid #eee',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '8px 0',
  },
};
