import React, { ForwardedRef, forwardRef } from 'react';
import { wrap } from './style/box';

function Box(
  { children }: React.PropsWithChildren<unknown>,
  ref: ForwardedRef<HTMLDivElement>,
) {
  return (
    <div css={wrap} ref={ref}>
      {children}
    </div>
  );
}

export default forwardRef(Box);
