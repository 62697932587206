import { ModalComponent } from 'components';
import React, { useEffect } from 'react';

import { Outlet, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'stores';
import {
  clearAll,
  closeAlert,
  closeConfirm,
  closeModal,
  confirm,
} from 'stores/ui';

function Base() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const {
    loading,
    isOpenAlert,
    alertContent,
    isOpenConfirm,
    confirmContent,
    modals,
    yesTitle,
    noTitle,
  } = useAppSelector((state) => state.ui);

  useEffect(() => {
    dispatch(clearAll());
  }, [location]);

  return (
    <>
      <Outlet />
      <ModalComponent.Portal>
        {modals.map((data, idx) => (
          <ModalComponent.Background key={idx}>
            <ModalComponent.Modal
              title={data.title}
              footer={data.footer}
              size={data.size}
              handleClose={() => dispatch(closeModal())}
            >
              {data.body}
            </ModalComponent.Modal>
          </ModalComponent.Background>
        ))}

        {isOpenAlert && (
          <ModalComponent.Background>
            <ModalComponent.Alert onClose={() => dispatch(closeAlert())}>
              {alertContent}
            </ModalComponent.Alert>
          </ModalComponent.Background>
        )}
        {isOpenConfirm && (
          <ModalComponent.Background>
            <ModalComponent.Confirm
              yesTitle={yesTitle}
              noTitle={noTitle}
              onConfirm={() => dispatch(confirm())}
              onClose={() => dispatch(closeConfirm())}
            >
              {confirmContent}
            </ModalComponent.Confirm>
          </ModalComponent.Background>
        )}

        {loading && (
          <ModalComponent.Background>
            <ModalComponent.Spiner />
          </ModalComponent.Background>
        )}
      </ModalComponent.Portal>
    </>
  );
}

export default Base;
