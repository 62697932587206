import React from 'react';
import { ModalComponent } from 'components';

interface Props {
  desc: string[];
  handleCancel: () => void;
}

function Preview({ desc, handleCancel }: Props) {
  return (
    <ModalComponent.Portal>
      <ModalComponent.Background>
        <ModalComponent.Modal
          title=""
          handleClose={handleCancel}
          footer={<></>}
        >
          <div
            css={{
              width: '400px',
              display: 'flex',
              flexDirection: 'column',
              gap: 0,
              '> img': { width: '100%' },
            }}
          >
            {desc.map((d, i) => (
              <img src={d} alt="" key={i} />
            ))}
          </div>
        </ModalComponent.Modal>
      </ModalComponent.Background>
    </ModalComponent.Portal>
  );
}

export default Preview;
