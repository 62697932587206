import { CSSObject, Theme } from '@emotion/react';

const wrap = (theme: Theme): CSSObject => ({
  background: theme.color.white,
  borderRadius: '12px',
  minWidth: '300px',

  '> hr': {
    width: '100%',
    height: '1px',
    background: '#f7f7f7',
    margin: 0,
    border: 0,
  },
});

const content: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px 30px',
  fontSize: '16px',
};

const buttonArea = (theme: Theme): CSSObject => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0 !important',

  '& > button': {
    position: 'relative',
    flex: 1,
    height: '56px',
    fontWeight: 500,
    fontSize: '16px',
    textAlign: 'center',
    color: theme.color.primary,

    '+ button::before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      left: 0,
      transform: 'translate(-50%, -50%)',
      width: 1,
      height: 24,
      borderLeft: `solid 1px #f1f1f1`,
    },
  },
});

export { wrap, content, buttonArea };
