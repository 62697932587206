import { CSSObject } from '@emotion/react';
import icOn from '../static/radio_on.svg';
import icOff from '../static/radio_off.svg';
import icDisabled from '../static/radio_disabled.svg';

const wrap: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px',
  lineHeight: '15px',
  '> input': {
    appearance: 'none',
    background: `url(${icOff}) !important`,
    width: 24,
    height: 24,
    margin: '0 4px 0 0',
    ':checked': {
      background: `url(${icOn}) !important`,
    },
    ':disabled': {
      background: `url(${icDisabled}) !important`,
    },
  },

  ':has(input[type="radio"]:disabled)': {
    color: '#9D9C9C',
  },
};

export { wrap };
