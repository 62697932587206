import React, { PropsWithChildren } from 'react';
import { CSSObject } from '@emotion/react';
import { checkbox } from './style';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  handleCheck?: (checked: boolean) => void;
  css?: CSSObject;
  readOnly?: boolean;
}

function CheckBox({
  children,
  checked,
  handleCheck,
  css,
  onChange,
  readOnly,
  ...props
}: PropsWithChildren<Props>) {
  return (
    <label css={checkbox}>
      <input
        {...props}
        css={css}
        type="checkbox"
        checked={checked}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (readOnly) return;

          if (handleCheck) handleCheck(e.target.checked);
          else onChange?.(e);
        }}
      />
      {children}
    </label>
  );
}

export default CheckBox;
