import { useState } from 'react';
import { IUser } from 'types';
import * as API from 'api/user';
import useModal from 'hooks/useModal';

interface Props {
  user: IUser;
  refetch: () => void;
}

function useEdit({ user, refetch }: Props) {
  const { handleAlert } = useModal();
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const handleSave = (data: IUser) => {
    API.put({ ...data, id: user.id }).then(() => {
      handleAlert('저장되었습니다.');
      setIsOpenEdit(false);
      refetch();
    });
  };

  return { isOpenEdit, setIsOpenEdit, handleSave };
}

export default useEdit;
