import React, { ReactNode, useMemo } from 'react';

import { FormComponent, UiComponent } from 'components';
import { usePageOption } from 'hooks';
import { tableWrap, tableTop } from 'styles/table';
import { data } from './data';
import { INotice } from 'types';
import { Link } from 'react-router-dom';
const COLS: string[] = ['ID', '타이틀', '상단고정', '등록자', '등록일'];

function List() {
  const pageState = usePageOption();
  const renderer: Record<
    (typeof COLS)[number],
    (data: INotice & { row: number }) => ReactNode
  > = useMemo(
    () => ({
      ID: ({ id }: INotice) => <Link to={'/users/1'}>{id}</Link>,
      타이틀: ({ title }: INotice) => <>{title}</>,
      상단고정: ({ fixedTop }: INotice) => <>{fixedTop ? 'Y' : 'N'}</>,
      등록자: ({ creator }: INotice) => <>{creator}</>,
      등록일: ({ createdAt }: INotice) => <>{createdAt}</>,
    }),
    [],
  );

  return (
    <UiComponent.Box>
      <div css={tableTop}>
        <p>총 24 건</p>
        <div>
          <FormComponent.Select options={[{ name: '등록일', value: '1' }]} />
          <FormComponent.Select
            options={[
              { name: '20', value: '20' },
              { name: '50', value: '50' },
              { name: '100', value: '100' },
            ]}
          />
        </div>
      </div>
      <div css={tableWrap}>
        <UiComponent.Table cols={COLS} renderer={renderer} data={data} sticky />
      </div>
      <UiComponent.Pagination {...pageState} />
    </UiComponent.Box>
  );
}

export default List;
