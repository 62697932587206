import React, { HTMLAttributes, PropsWithChildren } from 'react';
import Item from './Item';
import { DirectionType } from './types';
import { list } from './style';

interface Props extends PropsWithChildren<HTMLAttributes<HTMLUListElement>> {
  direction?: DirectionType;
  gap?: number;
}

function List({ children, direction = 'column', gap = 4, ...props }: Props) {
  return (
    <ul {...props} css={list({ direction, gap })}>
      {children}
    </ul>
  );
}

List.Item = Item;

export default List;
