import { DateItemType, ISelectOption } from 'types';
import dayjs from 'dayjs';

const END_TIME_VALUE = '24' as const;

const ITEMS: DateItemType[] = [
  {
    label: '오늘',
    getStart: (date: Date) => dayjs(date).startOf('day').toDate(),
  },
  {
    label: '1주일',
    getStart: (date: Date) =>
      dayjs(date).add(1, 'days').startOf('day').subtract(7, 'days').toDate(),
  },
  {
    label: '1개월',
    getStart: (date: Date) =>
      dayjs(date).add(1, 'days').startOf('day').subtract(1, 'months').toDate(),
  },
  {
    label: '3개월',
    getStart: (date: Date) =>
      dayjs(date).add(1, 'days').startOf('day').subtract(3, 'months').toDate(),
  },
  {
    label: '6개월',
    getStart: (date: Date) =>
      dayjs(date).add(1, 'days').startOf('day').subtract(6, 'months').toDate(),
  },
  {
    label: '1년',
    getStart: (date: Date) =>
      dayjs(date).add(1, 'days').startOf('day').subtract(1, 'years').toDate(),
  },
  { label: '전체', getStart: () => new Date(NaN), getEnd: () => new Date(NaN) },
];

const END_TIME_ITEM: ISelectOption<string>[] = [
  { name: '23:59', value: END_TIME_VALUE },
];

const TIME_ITEMS: ISelectOption<string>[] = new Array(24)
  .fill('')
  .map((_, i) => {
    const n = `0${i}`.slice(-2);

    return { name: `${n}:00`, value: String(i) };
  })
  .concat(END_TIME_ITEM);

export { END_TIME_VALUE, ITEMS, TIME_ITEMS };
