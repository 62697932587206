import { MainComponent } from 'components';
import React from 'react';

function List() {
  return (
    <>
      <MainComponent.Manage />
    </>
  );
}

export default List;
