import { useState } from 'react';
import { IAdditional, IFeedback, IQuestion, QuestionType } from 'types';
import * as AdditionalAPI from 'api/additional';
import * as FeedbackAPI from 'api/feedback';

interface Props {
  load: (title?: string, questions?: IQuestion[]) => void;
  type: QuestionType;
}

function useLoadForms({ load, type }: Props) {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [data, setData] = useState<IAdditional[] | IFeedback[]>([]);

  const handleSelect = (data: IAdditional | IFeedback) => {
    load(data.title, data.questions);
    setIsOpenModal(false);
  };

  const getAdditional = () => {
    AdditionalAPI.getAll().then((res) => setData(res.data));
  };
  const getFeedback = () => {
    FeedbackAPI.getAll().then((res) => setData(res.data));
  };

  const get = () => {
    if (type === 'additional') getAdditional();
    else if (type === 'feedback') getFeedback();
  };

  const handleOpen = () => {
    get();
    setIsOpenModal(true);
  };

  const handleClose = () => {
    setIsOpenModal(false);
  };

  return {
    data,
    isOpenModal,
    setIsOpenModal,
    handleClose,
    handleOpen,
    handleSelect,
  };
}

export default useLoadForms;
