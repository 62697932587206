import React from 'react';
import { EventComponent } from 'components';
import useDetail from 'components/event/useDetail';

function Edit() {
  const { event } = useDetail();

  if (!event) return null;

  return (
    <>
      <EventComponent.Form event={event} />
    </>
  );
}

export default Edit;
