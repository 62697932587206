import React, { useEffect, useState } from 'react';

import { ModalComponent } from 'components';
import { IFeedback } from 'types';
import * as API from 'api/feedback';

interface Props {
  id: number;
  onClose: () => void;
}

function ViewModal({ id, onClose }: Props) {
  const [feedback, setFeedback] = useState<IFeedback | null>(null);

  const fetch = (id: number) => {
    API.get(id).then((res) => {
      if (res?.data) setFeedback({ ...res.data });
    });
  };

  useEffect(() => {
    fetch(id);
  }, []);
  if (!feedback) return null;

  return (
    <ModalComponent.Portal>
      <ModalComponent.Background>
        <ModalComponent.Modal
          title="추가정보"
          handleClose={onClose}
          footer={<></>}
        >
          <div>
            {feedback.questions.map((q, i) => (
              <div key={q.id}>
                <p css={{ margin: '12px 0', fontWeight: 'bold' }}>
                  {`${i + 1}: ${q.title}`}
                </p>
                {q.type !== '4' ? <p>{q.options?.[0]}</p> : <p>-</p>}
              </div>
            ))}
          </div>
        </ModalComponent.Modal>
      </ModalComponent.Background>
    </ModalComponent.Portal>
  );
}

export default ViewModal;
