import { UserComponent } from 'components';
import React from 'react';

function Detail() {
  return (
    <>
      <UserComponent.Detail />
    </>
  );
}

export default Detail;
