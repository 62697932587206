import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { FormComponent, UiComponent } from 'components';
import { usePageOption } from 'hooks';
import { tableWrap, tableTop } from 'styles/table';
import { IContent } from 'types';
import * as API from 'api/content';
import Preview from './Preview';
import { BsPlusLg } from 'react-icons/bs';

const COLS: string[] = [
  '썸네일',
  'ID',
  '콘텐츠명',
  '유형',
  '미리보기',
  '등록자',
  '등록일',
];

function List() {
  const [selectedData, setSelectedData] = useState<IContent | null>(null);

  const [data, setData] = useState<IContent[]>([]);

  const pageState = usePageOption();
  const renderer: Record<
    (typeof COLS)[number],
    (data: IContent & { row: number }) => ReactNode
  > = useMemo(
    () => ({
      썸네일: ({ thumbnail }: IContent) => (
        <img src={thumbnail} alt="thumbnail" css={{ width: '32px' }} />
      ),
      ID: ({ id }: IContent) => <>{id}</>,
      콘텐츠명: ({ title }: IContent) => <>{title}</>,
      유형: ({ type }: IContent) => <>{getTypeText(type)}</>,
      미리보기: (content: IContent) => (
        <button
          css={{
            border: '1px solid black',
            padding: '2px 8px',
            borderRadius: '4px',
          }}
          onClick={() => setSelectedData(content)}
        >
          {content.desc.length > 0 ? '미리보기' : '외부링크'}
        </button>
      ),
      등록자: ({ creator }: IContent) => <>{creator}</>,
      등록일: ({ createdAt }: IContent) => <>{createdAt}</>,
    }),
    [],
  );

  const fetch = () => {
    API.getAll().then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <UiComponent.Box>
        <div css={tableTop}>
          <p>총 500 건</p>
          <div>
            <UiComponent.Button variant="contained" color="primary">
              <BsPlusLg size={18} />
            </UiComponent.Button>
            <FormComponent.Select options={[{ name: '등록일', value: '1' }]} />
            <FormComponent.Select
              options={[
                { name: '20', value: '20' },
                { name: '50', value: '50' },
                { name: '100', value: '100' },
              ]}
            />
          </div>
        </div>
        <div css={tableWrap}>
          <UiComponent.Table
            cols={COLS}
            renderer={renderer}
            data={data}
            sticky
          />
        </div>
        <UiComponent.Pagination {...pageState} />
      </UiComponent.Box>
      {selectedData !== null && (
        <Preview
          desc={selectedData.desc}
          handleCancel={() => setSelectedData(null)}
        />
      )}
    </>
  );
}

const getTypeText = (type: IContent['type']) => {
  switch (type) {
    case 'internal':
      return '상세페이지';
    case 'external':
      return '외부';
    case 'youtube':
      return 'youtube';
    case 'instagram':
      return 'instagram';
    default:
      return '';
  }
};

export default List;
