const DATA = {
  left: {
    single: ['36 24 28 32 36 40'],
    double: ['40 24 32 32 40 40', '32 24 24 32 32 40'],
  },
  right: {
    single: ['28 40 36 32 28 24'],
    double: ['24 40 32 32 24 24', '32 40 40 32 32 24'],
  },
} as const;

export { DATA };
