import React, { useState } from 'react';

import { FormComponent, UiComponent } from 'components';
import useAdd from '../Modal/useAdd';
import Modal from '../Modal';

interface Props {
  refetch: () => void;
}

function Filter({ refetch }: Props) {
  const [isOpen, setIsOpen] = useState(false);
  const { onSave } = useAdd({
    refetch,
    onDone: () => setIsOpen(false),
  });

  return (
    <>
      <div css={{ marginTop: '24px' }}>
        <UiComponent.Box>
          <div css={{ display: 'flex', justifyContent: 'space-between' }}>
            <div css={{ display: 'flex', alignItems: 'center', gap: '0 8px' }}>
              <p>검색어</p>
              <FormComponent.Input css={{ width: '500px' }} />
            </div>
            <UiComponent.Button
              variant="contained"
              color="black"
              onClick={() => setIsOpen(true)}
            >
              추가
            </UiComponent.Button>
          </div>
        </UiComponent.Box>
      </div>
      {isOpen && <Modal onClose={() => setIsOpen(false)} onSave={onSave} />}
    </>
  );
}

export default Filter;
