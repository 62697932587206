// import { AxiosResponse } from 'axios';
// import { base } from './';
import { IAdditional, IQuestion } from 'types';
import dayjs from 'dayjs';

// const PATH = '/additional';

const MOCK_DATA: IAdditional[] = [
  {
    id: 1,
    title: '연봉인상 추가정보 템플릿1',
    questions: [
      {
        id: 1,
        type: '1',
        title:
          '봉사하는 여러분들의 아름다운 모습을 사진으로 담고자 합니다. 사진 촬영에 동의하시나요?',
        description:
          '연봉인상 공식 SNS 계정에서 홍보 등의 목적으로 활용될 수 있습니다. 동의하지 않는 경우 참여가 어렵습니다',
        options: ['예, 동의합니다', '미동의'],
        isRequired: true,
      },
      {
        id: 2,
        type: '1',
        title: '연봉인상이 처음이신가요?',
        description: '',
        options: ['네! 처음입니다 ', '아뇨. 봉사하러 또 왔어요'],
        isRequired: true,
      },
      {
        id: 3,
        type: '1',
        title: '어르신들과 편하게 대화하시는 편인가요?',
        description: '',
        options: ['편하게 대화하는 편입니다.', '조금 어려운 편입니다.'],
        isRequired: true,
      },
    ],
    creator: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
];

const find = (id: number) => MOCK_DATA.find((item) => item.id === id);

// const get = (id: number) => base.get(`${PATH}/${id}`);
const get = (id: number) =>
  new Promise<{ data: IAdditional | undefined }>((resolve) =>
    resolve({ data: find(id) }),
  );

// const getAll = () => base.get<AxiosResponse<IAdditional[]>>(PATH);
const getAll = () =>
  new Promise<{ data: IAdditional[] }>((resolve) =>
    resolve({ data: MOCK_DATA }),
  );

//   const post = (data: IAdditional) => base.post(PATH, data);
const post = ({
  title,
  questions,
}: {
  title: string;
  questions: IQuestion[];
}) => {
  const additional: IAdditional = {
    id: MOCK_DATA.length + 1,
    title,
    questions,
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    creator: '최*수',
  };

  MOCK_DATA.push(additional);

  return new Promise((resolve) => resolve(true));
};

const put = (data: IAdditional) => {
  const idx = MOCK_DATA.findIndex((item) => item.id === data.id);
  MOCK_DATA[idx] = { ...data };
  return new Promise((resolve) => resolve(true));
};

export { get, getAll, post, put };
