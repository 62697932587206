import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IEvent } from 'types';
import * as API from 'api/event';

function useDetail() {
  const { id } = useParams<{ id: string }>();

  const [event, setEvent] = useState<IEvent | null>(null);

  const fetch = (id: number) => {
    API.get(id).then((res) => {
      if (res?.data) setEvent({ ...res.data });
    });
  };

  const refetch = () => {
    if (id) {
      fetch(Number(id));
    }
  };

  useEffect(() => {
    if (id) {
      fetch(Number(id));
    }
  }, []);

  return { event, refetch };
}

export default useDetail;
