import React, { useEffect, useState } from 'react';
import { ModalComponent } from 'components';
import { IUser } from 'types';
import * as API from 'api/user';

interface Props {
  id: number;
  onClose: () => void;
}

function UserMemoModal({ id, onClose }: Props) {
  const [user, setUser] = useState<IUser | null>(null);

  const fetch = (id: number) => {
    API.get(id).then((res) => {
      if (res?.data) setUser({ ...res.data });
    });
  };

  useEffect(() => {
    fetch(id);
  }, []);

  if (!user) return null;

  return (
    <ModalComponent.Portal>
      <ModalComponent.Background>
        <ModalComponent.Modal
          title={`${user.name} 님의 메모`}
          handleClose={onClose}
          footer={<></>}
        >
          <p>{user.memo}</p>
        </ModalComponent.Modal>
      </ModalComponent.Background>
    </ModalComponent.Portal>
  );
}

export default UserMemoModal;
