import React from 'react';
import { detailWrap } from 'styles/table';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
} from 'chart.js';
import { Pie, Line } from 'react-chartjs-2';
ChartJS.register(
  ArcElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const data1 = {
  labels: ['로열', '뉴비', '일반'],
  datasets: [
    {
      label: '',
      data: [11, 17, 45],
      backgroundColor: ['#f1f3f5', '#868e96', '#828385'],
      borderColor: ['#f1f3f5', '#868e96', '#828385'],
      borderWidth: 1,
    },
  ],
};

const labels = ['20:00', '20:30', '21:00', '21:30', '22:00'];

export const data = {
  labels,
  datasets: [
    {
      label: '',
      data: [41, 20, 10, 5, 10],
      borderColor: '#828385',
      backgroundColor: '#828385',
    },
  ],
};

function Dashboard() {
  return (
    <>
      <div css={[detailWrap, { marginTop: '24px' }]}>
        <h3>현황</h3>
        <div css={{ display: 'flex', gap: '0 40px' }}>
          <div css={{ width: '250px', height: '250px' }}>
            <Pie
              data={data1}
              options={{
                plugins: {
                  legend: {
                    position: 'bottom',
                  },
                },
              }}
            />
          </div>
          <div css={{ width: '500px' }}>
            <Line
              options={{
                responsive: true,
                plugins: {
                  legend: {
                    display: false,
                  },
                },
              }}
              data={data}
            />
          </div>
          <div
            css={{
              flex: 1,
              '> p': {
                marginBottom: '8px',
                fontSize: '14px',
                color: '#8d8d8d',
                fontWeight: 'bold',
                '> span': {
                  marginLeft: '4px',
                  fontSize: '12px',
                },
              },
              '> div': {
                marginBottom: '32px',
              },
            }}
          >
            <p>
              출석률
              <span>70/74</span>
            </p>
            <Progress percent={95} color={'#495057'} />
            <p>
              재방문율
              <span>35/74</span>
            </p>
            <Progress percent={47} color={'#495057'} />
            <p>
              피드백 응답률
              <span>50/74</span>
            </p>
            <Progress percent={67} color={'#495057'} />
          </div>
        </div>
      </div>
    </>
  );
}

const Progress = ({ percent, color }: { percent: number; color: string }) => (
  <div
    css={{
      position: 'relative',
      height: '5px',
      width: '100%',
      background: '#ced4da',
      borderRadius: '8px',
    }}
  >
    <div
      css={{
        position: 'absolute',
        left: 0,
        height: '5px',
        width: `${percent}%`,
        background: color,
        borderRadius: '8px',
      }}
    />
  </div>
);

export default Dashboard;
