import React, { HTMLAttributes, PropsWithChildren } from 'react';
import { item } from './style';
import { ItemAlignType, ItemVariantType } from './types';

interface Props extends PropsWithChildren<HTMLAttributes<HTMLLIElement>> {
  variant?: ItemVariantType;
  align?: ItemAlignType;
}

function Item({
  variant = 'block',
  align = 'center',
  children,
  ...props
}: Props) {
  return (
    <li {...props} css={item({ variant, align })}>
      {children}
    </li>
  );
}

export default Item;
