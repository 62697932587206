import React, { useMemo } from 'react';
import Icon from './Icon';
import { wrap, btn } from './style';
import { IPageOption } from 'types';

const MAX_PAGE_DIFF = 4;

interface Props {
  pageOption: IPageOption;
  totalPage: number;
  handlePage: (option: IPageOption) => void;
}

function Pagination({ pageOption, totalPage, handlePage }: Props) {
  const { page, size } = pageOption;

  const pages = useMemo(() => {
    let start = page - 2;
    if (start + MAX_PAGE_DIFF > totalPage) start = totalPage - MAX_PAGE_DIFF;
    if (start < 1) start = 1;

    return new Array(totalPage > MAX_PAGE_DIFF ? 5 : totalPage)
      .fill('')
      .map((_, i) => i + start);
  }, [totalPage, page]);

  const setPage = (page: number) => {
    handlePage({
      size,
      page,
    });
  };

  return (
    <div css={wrap}>
      <button
        type="button"
        css={{ ...btn, borderRadius: '6px 0 0 6px' }}
        onClick={() => setPage(1)}
      >
        <Icon size={32} direction="left" type="double" />
      </button>
      <button
        type="button"
        css={btn}
        onClick={() => setPage(page - 1)}
        disabled={page - 1 < 1}
      >
        <Icon size={32} direction="left" type="single" />
      </button>
      {pages.map((p, i) => (
        <button
          key={i}
          css={(theme) => ({
            ...btn,
            width: '32px',
            color: pageOption.page === p ? theme.color.primary : 'grey',
          })}
          onClick={() => setPage(p)}
        >
          {p}
        </button>
      ))}
      <button
        type="button"
        css={btn}
        onClick={() => setPage(page + 1)}
        disabled={page + 1 > totalPage}
      >
        <Icon size={32} direction="right" type="single" />
      </button>
      <button
        type="button"
        css={{
          ...btn,
          borderRadius: '0 6px 6px 0',
          borderRight: '1px solid #ddd',
        }}
        onClick={() => setPage(totalPage)}
      >
        <Icon size={32} direction="right" type="double" />
      </button>
    </div>
  );
}

export default Pagination;
