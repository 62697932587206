import React from 'react';
import { wrap, logoWrap, logos, btns } from './style';
import icLogo from '../static/logo-light.svg';
import { useAppDispatch } from 'stores';
import { logout } from 'stores/auth';

function Header() {
  const dispatch = useAppDispatch();

  return (
    <header css={wrap}>
      <div css={logoWrap}>
        <button type="button" css={logos}>
          <img src={icLogo} alt="logo" />
        </button>
      </div>
      <div css={btns}>
        <button type="button" onClick={() => dispatch(logout())}>
          로그아웃
        </button>
      </div>
    </header>
  );
}

export default Header;
