import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { IUser } from 'types';
import * as API from 'api/user';

function useDetail() {
  const { id } = useParams<{ id: string }>();

  const [user, setUser] = useState<IUser | null>(null);

  const fetch = (id: number) => {
    API.get(id).then((res) => {
      if (res?.data) setUser({ ...res.data });
    });
  };

  const refetch = () => {
    if (id) {
      fetch(Number(id));
    }
  };

  useEffect(() => {
    if (id) {
      fetch(Number(id));
    }
  }, []);

  return { user, refetch };
}

export default useDetail;
