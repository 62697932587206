import { Interpolation, Theme } from '@emotion/react';
import React, {
  ButtonHTMLAttributes,
  ForwardedRef,
  PropsWithChildren,
} from 'react';
import { VariantType, button, ColorType } from './style';

interface Props
  extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  variant: VariantType;
  color: ColorType;
  css?: Interpolation<Theme>;
}

function Button(
  { variant, children, type, color, css, ...rest }: Props,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <button
      type={type ?? 'button'}
      {...rest}
      ref={ref}
      css={[button(variant, color), css]}
    >
      {children}
    </button>
  );
}

export default React.forwardRef(Button);
