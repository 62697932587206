import { CSSObject } from '@emotion/react';

const SIDE_DURATION = 0.3;

const wrap = (isOpen: boolean): CSSObject => ({
  position: 'relative',
  display: 'flex',
  flex: 0,
  flexDirection: 'column',
  width: isOpen ? '240px' : '60px',
  background: 'white',
  boxShadow: '0 0 6px 0 rgba(0, 0, 0, 0.1)',
  transition: `width ${SIDE_DURATION}s ease-in-out`,
});

const side = (isOpen: boolean): CSSObject => ({
  position: 'absolute',
  top: '22px',
  right: '17px',
  transform: `rotate(${isOpen ? 0 : 180}deg)`,
  ':hover': {
    cursor: 'pointer',
  },
});

const contentsWrap = (isOpen: boolean): CSSObject => ({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  minWidth: '240px',
  marginTop: '24px',
  visibility: isOpen ? 'unset' : 'hidden',
  transition: isOpen
    ? `visibility ${SIDE_DURATION}s ease-in-out ${SIDE_DURATION}s`
    : 'none',
});

const menuWrap: CSSObject = {
  overflow: 'auto',
  marginTop: '40px',
  '> li': {
    padding: '16px 17px 16px 22px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    userSelect: 'none',
    'div:nth-of-type(1)': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      fontSize: '14px',
    },
    ':hover': {
      cursor: 'pointer',
    },
  },
};

const menuIcon = (icon: string): CSSObject => ({
  marginRight: '4px',
  width: '22px',
  height: '22px',
  background: `url(${icon}) center center no-repeat`,
});

const menuArrow = (isOpen: boolean): CSSObject => ({
  width: '10px',
  height: '6px',
  transform: `rotate(${isOpen ? -180 : 0}deg)`,
  transition: 'transform 0.3s ease-in-out',
});

const subMenuWrap = (isOpen: boolean): CSSObject => ({
  display: 'flex',
  flexDirection: 'column',
  maxHeight: isOpen ? '100vh' : 0,
  transition: 'max-height 0.4s ease-in-out',
  overflow: 'hidden',
});

const subMenu = (isSelected: boolean): CSSObject => ({
  padding: '8px 24px 8px 48px',
  fontSize: '13px',
  fontWeight: isSelected ? 500 : 400,
  textDecoration: 'none',
  color: isSelected ? 'black' : 'grey',
  ':hover': {
    cursor: 'pointer',
    color: 'black',
  },
});

export {
  wrap,
  side,
  contentsWrap,
  menuWrap,
  menuIcon,
  menuArrow,
  subMenu,
  subMenuWrap,
};
