import { CSSObject, Theme } from '@emotion/react';

const tableWrap: CSSObject = {
  maxHeight: '65vh',
  overflow: 'auto',
  marginTop: '30px',
  '> table > tbody': {
    '> tr:has(input[type="checkbox"]:checked)': {
      backgroundColor: '#e3f2fd',
    },
    '> tr > td > label': {
      display: 'inline-block',
    },
  },
};

const tableTop: CSSObject = {
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'space-between',
  marginTop: 16,
  '> div': {
    display: 'flex',
    gap: '0 4px',
  },
};

const detailWrap = (theme: Theme): CSSObject => ({
  background: '#fff',
  padding: '16px',
  borderRadius: '4px',
  userSelect: 'none',

  h3: {
    color: theme.color.grey,

    '& ~ h3': {
      marginTop: 24,
      lineHeight: '18px',
    },

    span: {
      fontSize: '0.8rem',
      fontWeight: 400,
    },
  },

  table: {
    width: '100%',
    minWidth: 800,
    marginTop: '12px',
    borderCollapse: 'collapse',

    'td, th': {
      fontSize: 14,
      padding: '16px',
      textAlign: 'left',
      border: '1px solid #ddd',
      fontWeight: 'normal',

      span: {
        position: 'relative',
        display: 'inline-block',
        marginRight: 24,
        color: theme.color.grey,
        fontWeight: 300,

        '&::after': {
          content: '""',
          position: 'absolute',
          width: 1,
          height: 12,
          right: -12,
          borderRight: 'solid 1px #ddd',
        },
      },
    },

    th: {
      width: 120,
      backgroundColor: '#fafafa',
    },
  },
});

export { tableWrap, tableTop, detailWrap };
