import React from 'react';

import { ContentComponent, UiComponent } from 'components';

function List() {
  return (
    <>
      <UiComponent.PageTitle>콘텐츠 관리</UiComponent.PageTitle>
      <ContentComponent.Filter />
      <ContentComponent.List />
    </>
  );
}

export default List;
