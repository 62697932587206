import { CSSObject } from '@emotion/react';
import { theme } from 'styles';

const wrap: CSSObject = {
  padding: '0 8px',
  border: '1px solid',
  borderColor: '#ccc',
  fontSize: '16px',
  height: '36px',
  borderRadius: '3px',
  boxSizing: 'border-box',
  ':focus': {
    outline: 'none',
    borderColor: '#333',
  },
  '::placeholder': {
    color: '#ccc',
    fontSize: '14px',
    fontWeight: '400',
  },

  ':read-only': {
    color: theme.color.grey,
  },
};

const autoWrap: CSSObject = {
  '.react-autosuggest__container': {
    position: 'relative',
  },
  '.react-autosuggest__suggestions-container--open': {
    display: 'block',
    position: 'absolute',
    top: '36px',
    left: 0,
    right: 0,
    padding: '5px 0px',
    border: '1px solid #ddd',
    backgroundColor: '#fff',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    zIndex: 2,
    maxHeight: '120px',
    overflow: 'scroll',
    boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
  '.react-autosuggest__suggestion--highlighted': {
    background: '#ddd',
  },
  '.react-autosuggest__suggestion': {
    padding: '8px 10px',
    width: '100%',
    boxSizing: 'border-box',
  },
};

export { wrap, autoWrap };
