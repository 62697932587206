import React, { createRef, useEffect, useRef, useState } from 'react';
import { IQuestion } from 'types/forms';
import { ComponentProxyRef } from 'types/proxy';

interface Props {
  title?: string;
  questions?: IQuestion[];
  onSave: (title: string, questions: IQuestion[]) => void;
}

function useForms({
  title: initTitle,
  questions: initQuestions,
  onSave,
}: Props) {
  const refs = useRef<
    Map<string, React.RefObject<ComponentProxyRef<IQuestion>>>
  >(new Map());
  const [title, setTitle] = useState(initTitle || '');
  const [questions, setQuestions] = useState<IQuestion[]>([]);

  const load = (title?: string, questions?: IQuestion[]) => {
    refs.current = new Map();
    const data = questions || [
      {
        id: 1,
        type: '1',
        title: '',
        description: '',
      },
    ];

    data.forEach(({ id }) => {
      refs.current.set(
        id.toString(),
        createRef<ComponentProxyRef<IQuestion>>(),
      );
    });

    setTitle(title || '');
    setQuestions(data);
  };

  const handleAddQuestion = () => {
    const maxId = Math.max(...questions.map((item) => item.id));
    questions.push({
      id: maxId + 1,
      type: '1',
      title: '',
      description: '',
    });

    refs.current.set(
      (maxId + 1).toString(),
      createRef<ComponentProxyRef<IQuestion>>(),
    );
    setQuestions([...questions]);
  };

  const handleRemoveQuestion = (id: number) => {
    if (questions.length === 1) {
      alert('질문은 최소 1개 이상이어야 합니다.');
      return;
    }

    refs.current.delete(id.toString());
    setQuestions(questions.filter((item) => item.id !== id));
  };

  const handleSave = () => {
    if (!title) {
      alert('제목을 입력해주세요.');
      return;
    }

    const questions: IQuestion[] = [];
    let validate = true;
    refs.current.forEach((ref) => {
      if (ref.current?.validate?.()) {
        const data = ref.current?.getData?.();
        if (data) questions.push(data);
      } else {
        validate = false;
      }
    });

    if (questions.length === 0) {
      return;
    }

    if (!validate) return;
    onSave(title, questions);
  };

  useEffect(() => {
    load(initTitle, initQuestions);
  }, []);

  useEffect(() => {
    questions.forEach((item) => {
      const ref = refs.current.get(item.id.toString());
      ref?.current?.setData?.(item);
    });
  }, [questions]);

  return {
    refs,
    title,
    setTitle,
    questions,
    handleAddQuestion,
    handleRemoveQuestion,
    handleSave,
    load,
  };
}

export default useForms;
