import { MENU_URLS } from 'data/menu';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

function useMenus() {
  const { pathname } = useLocation();
  const [current, setCurrent] = useState(-1);

  const [first, setFirst] = useState(-1);
  const [second, setSecond] = useState(-1);

  useEffect(() => {
    MENU_URLS.forEach((list, i) => {
      list.forEach((m, j) => {
        if (pathname.startsWith(m.to)) {
          setFirst(i);
          setCurrent(i);
          setSecond(j);
        }
      });
    });
  }, [pathname]);

  const handleFirst = (key: number) => {
    if (key === first) setFirst(-1);
    else setFirst(key);
  };

  return { first, current, second, pathname, handleFirst };
}

export default useMenus;
