import React from 'react';
import Main from './Main';
import Dashboard from './Dashboard';
import VolunteerList from './VolunteerList';
import Statistics from './Statistics';

function Detail() {
  return (
    <div>
      <Main />
      <Statistics />
      <Dashboard />
      <VolunteerList />
    </div>
  );
}

export default Detail;
