import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { detailWrap } from 'styles/table';
import { UiComponent } from 'components';
import { usePageOption } from 'hooks';
import { tableWrap } from 'styles/table';
import { COUPON_STATUS_NAME, ICoupon } from 'types';
import * as API from 'api/coupon';

const COLS = ['쿠폰번호', '쿠폰유형', '상태', '사용기한'];

function Coupon() {
  const [data, setData] = useState<ICoupon[]>([]);

  const pageState = usePageOption();
  const renderer: Record<
    (typeof COLS)[number],
    (data: ICoupon & { row: number }) => ReactNode
  > = useMemo(
    () => ({
      쿠폰번호: ({ id }: ICoupon) => <>{id}</>,
      쿠폰유형: ({ type }: ICoupon) => <>{type}</>,
      상태: ({ status }: ICoupon) => <>{COUPON_STATUS_NAME[status]}</>,
      사용기한: ({ startAt, endAt }: ICoupon) => (
        <>{startAt && endAt ? `${startAt} - ${endAt}` : '-'}</>
      ),
    }),
    [],
  );

  const fetch = () => {
    API.getAll().then((res) => {
      const coupons = res.data.filter(
        (coupon: ICoupon) => coupon.issuedUserId === 1,
      );
      setData(coupons);
    });
  };

  useEffect(() => fetch(), []);

  return (
    <div css={[detailWrap, { marginTop: '24px' }]}>
      <h3>쿠폰 내역</h3>
      <div css={tableWrap}>
        <UiComponent.Table cols={COLS} renderer={renderer} data={data} sticky />
      </div>
      <UiComponent.Pagination {...pageState} />
    </div>
  );
}

export default Coupon;
