import { CSSObject } from '@emotion/react';

const wrap: CSSObject = {
  paddingBottom: 8,
};

const textarea: CSSObject = {
  padding: '12px 10px 32px 10px',
  resize: 'none',
  border: '1px solid',
  borderColor: '#ccc',
  fontSize: '16px',
  borderRadius: '3px',
  boxSizing: 'border-box',

  ':focus': {
    outline: 'none',
    borderColor: '#333',
  },
  '::placeholder': {
    color: '#ccc',
    fontSize: '13px',
  },
};

const maxParagraph: CSSObject = {
  textAlign: 'right',
  padding: '4px 0',
  fontSize: '12px',
  color: '#333',
  '> span': {
    color: '#ccc',
  },
};

export { wrap, textarea, maxParagraph };
