import React from 'react';
import { THUMBNAIL_DATA } from './data';

interface Props {
  thumbnail: string;
  setThumbnail: (thumnail: string) => void;
}

function ThumbnailSelector({ thumbnail, setThumbnail }: Props) {
  return (
    <>
      <div css={{ display: 'flex', gap: '0 8px', margin: '16px 0' }}>
        {THUMBNAIL_DATA.map((t, index) => (
          <img
            key={index}
            src={t}
            alt="thumbnail"
            css={{
              borderRadius: 8,
              borderStyle: 'solid',
              borderWidth: 2,
              borderColor: String(index) === thumbnail ? 'grey' : 'transparent',
            }}
            width={130}
            onClick={() => setThumbnail(String(index))}
          />
        ))}
      </div>
    </>
  );
}

export default ThumbnailSelector;
