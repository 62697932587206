import React from 'react';

import { AdditionalPage, FeedbackPage, EventPage } from 'pages';
import { EVENT_MENU_URL } from 'data/menu';

const eventApp = [
  {
    path: EVENT_MENU_URL.행사,
    element: <EventPage.List />,
  },
  {
    path: `${EVENT_MENU_URL.행사}/:id`,
    element: <EventPage.Detail />,
  },
  {
    path: `${EVENT_MENU_URL.행사}/:id/edit`,
    element: <EventPage.Edit />,
  },
  {
    path: `${EVENT_MENU_URL.행사}/:id/:type`,
    element: <EventPage.Question />,
  },
  {
    path: `${EVENT_MENU_URL.행사}/:id`,
    element: <EventPage.Detail />,
  },
  {
    path: `${EVENT_MENU_URL.행사}/register`,
    element: <EventPage.Register />,
  },
  {
    path: EVENT_MENU_URL['추가정보 템플릿'],
    element: <AdditionalPage.List />,
  },
  {
    path: `${EVENT_MENU_URL['추가정보 템플릿']}/:id`,
    element: <AdditionalPage.Form />,
  },
  {
    path: `${EVENT_MENU_URL['추가정보 템플릿']}/register`,
    element: <AdditionalPage.Form />,
  },
  {
    path: EVENT_MENU_URL['피드백 템플릿'],
    element: <FeedbackPage.List />,
  },
  {
    path: `${EVENT_MENU_URL['피드백 템플릿']}/:id`,
    element: <FeedbackPage.Form />,
  },
  {
    path: `${EVENT_MENU_URL['피드백 템플릿']}/register`,
    element: <FeedbackPage.Form />,
  },
  {
    path: `${EVENT_MENU_URL['파트너']}`,
    element: <EventPage.Partner />,
  },
];

export { eventApp };
