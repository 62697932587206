import React from 'react';
import { UiComponent, EventComponent } from 'components';

function List() {
  return (
    <>
      <UiComponent.PageTitle>행사목록</UiComponent.PageTitle>
      <EventComponent.Filter />
      <EventComponent.List />
    </>
  );
}

export default List;
