import React from 'react';
import { wrap } from './style';
interface Props {
  title: string;
  required?: boolean;
}

function FilterTitle({ title, required }: Props) {
  return (
    <p css={wrap}>
      {title}
      {required && (
        <span
          css={{
            color: '#ee6a7b',
            paddingLeft: '2px',
          }}
        >
          *
        </span>
      )}
    </p>
  );
}

export default FilterTitle;
