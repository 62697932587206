import { useState } from 'react';

interface Props {
  defaultStart?: string;
  defaultEnd?: string;
}

function useDate({ defaultStart, defaultEnd }: Props) {
  const [start, setStart] = useState(
    defaultStart ? new Date(defaultStart) : new Date(),
  );
  const [end, setEnd] = useState(
    defaultEnd ? new Date(defaultEnd) : new Date(),
  );

  return {
    start,
    end,
    handleStart: setStart,
    handleEnd: setEnd,
    itemLength: 0,
  };
}

export default useDate;
