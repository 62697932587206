import dayjs from 'dayjs';
// import { base } from './';
import { IEvent, EventFormType } from 'types';

// const PATH = '/volunteer';

const MOCK_DATA: IEvent[] = [
  {
    id: 1,
    title: '💸연봉인상💸 6월 22일 서울지방보훈처 6·25 봉사',
    detail: '',
    organization: '연봉인상',
    status: '대기',
    creator: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD'),
    startAt: dayjs().format('YYYY-MM-DD'),
    endAt: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    enlistStatrAt: dayjs().format('YYYY-MM-DD'),
    enlistEndAt: dayjs().format('YYYY-MM-DD'),
    freePass: false,
    thumbnail: '0',
    address: '서울 용산구 한강대로 지하 185 지하철 6호선 삼각지역 인근',
    latitude: 37.3595704,
    longitude: 127.105399,
    ca: ['VMS', '1365'],
    headcount: 100,
    partners: [],
  },
];

const find = (id: number) => MOCK_DATA.find((item) => item.id === id);

// const get = (id: number) => base.get(`${PATH}/${id}`);
const get = (id: number) =>
  new Promise<{ data: IEvent | undefined }>((resolve) =>
    resolve({ data: find(id) }),
  );

// const getAll = () => base.get(PATH);
const getAll = () =>
  new Promise<{ data: IEvent[] }>((resolve) => resolve({ data: MOCK_DATA }));

// const post = (data: IEvent) => base.post(PATH, data);
const post = (data: EventFormType) => {
  const event: IEvent = {
    ...data,
    organization: '연봉인상',
    status: '대기',
    creator: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD'),
    freePass: false,
    id: MOCK_DATA.length + 1,
    partners: [],
  };

  MOCK_DATA.push(event);

  return new Promise((resolve) => resolve(true));
};

const put = ({ id, data }: { data: EventFormType; id: number }) => {
  const idx = MOCK_DATA.findIndex((item) => item.id === id);
  MOCK_DATA[idx] = {
    ...MOCK_DATA[idx],
    ...data,
  };

  return new Promise((resolve) => resolve(true));
};

const putAdditional = ({
  id,
  additionalInfo,
}: {
  id: number;
  additionalInfo: IEvent['additionalInfo'];
}) => {
  const idx = MOCK_DATA.findIndex((item) => item.id === id);
  MOCK_DATA[idx].additionalInfo = additionalInfo;

  return new Promise((resolve) => resolve(true));
};

const putFeedBack = ({
  id,
  feedBackInfo,
}: {
  id: number;
  feedBackInfo: IEvent['feedBackInfo'];
}) => {
  const event = find(id);
  if (event) {
    event.feedBackInfo = feedBackInfo;
  }

  return new Promise((resolve) => resolve(true));
};

const putDescription = ({
  id,
  description,
}: {
  id: number;
  description: string;
}) => {
  const event = find(id);
  if (event) {
    event.description = description;
  }

  return new Promise((resolve) => resolve(true));
};

const putNotice = ({ id, notice }: { id: number; notice: string }) => {
  const event = find(id);
  if (event) {
    event.notice = notice;
  }

  return new Promise((resolve) => resolve(true));
};

const putOpenKakao = ({
  id,
  openKaKao,
}: {
  id: number;
  openKaKao: IEvent['openKaKao'];
}) => {
  const event = find(id);
  if (event) {
    event.openKaKao = openKaKao;
  }

  return new Promise((resolve) => resolve(true));
};

const putPartners = ({ id, partners }: { id: number; partners: number[] }) => {
  const event = find(id);
  if (event) {
    event.partners = partners;
  }

  return new Promise((resolve) => resolve(true));
};

const putStatus = ({
  id,
  status,
}: {
  id: number;
  status: IEvent['status'];
}) => {
  const event = find(id);
  if (event) {
    event.status = status;
  }

  return new Promise((resolve) => resolve(true));
};

export {
  get,
  getAll,
  post,
  put,
  putAdditional,
  putFeedBack,
  putDescription,
  putNotice,
  putOpenKakao,
  putPartners,
  putStatus,
};
