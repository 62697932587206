import React from 'react';

import { FormComponent, UiComponent } from 'components';
import * as BoxStyles from 'components/ui/Box/style/box';

function Filter() {
  return (
    <UiComponent.Box>
      <FormComponent.Form>
        <div css={BoxStyles.rows}>
          <UiComponent.FilterTitle title="검색어" />
          <FormComponent.Input css={{ width: '500px' }} />
        </div>
      </FormComponent.Form>
    </UiComponent.Box>
  );
}

export default Filter;
