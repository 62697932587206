import React from 'react';
import { DATA } from './constants';

interface Props {
  size: number;
  direction: 'right' | 'left';
  type: 'double' | 'single';
}

function Icon({ size, direction, type }: Props) {
  const data = DATA[direction][type];

  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 64 64"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      stroke="#000000"
    >
      {data.map((d, i) => (
        <polyline key={i} points={d} />
      ))}
    </svg>
  );
}

export default Icon;
