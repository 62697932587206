import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'stores';

function Unauthorize() {
  const { login } = useAppSelector((state) => state.auth);

  if (login) {
    return <Navigate to={`/users`} />;
  }

  return <Outlet />;
}

export default Unauthorize;
