import { useEffect, useRef, useState } from 'react';

export interface ILoginForm {
  username: string;
  password: string;
}

function useLoginForm() {
  const idRef = useRef<HTMLInputElement>(null);
  const pwRef = useRef<HTMLInputElement>(null);

  const [user, setUser] = useState<ILoginForm>({
    username: '',
    password: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const validate = () => {
    if (user.username === '') {
      idRef.current?.focus();
      return false;
    }

    if (user.password === '') {
      pwRef.current?.focus();
      return false;
    }

    return true;
  };

  useEffect(() => {
    idRef.current?.focus();
  }, []);

  return { idRef, pwRef, user, handleChange, validate };
}

export default useLoginForm;
