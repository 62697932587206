import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { SectionType } from 'types';

interface Props {
  section: SectionType;
}

function Preview({ section }: Props) {
  const { type } = section;
  if (type === 'main') return <Banner section={section} />;

  return (
    <div css={{ padding: '24px 0 24px 24px' }}>
      <p css={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '8px' }}>
        {section.title}
      </p>
      <h3 css={{ fontSize: '14px', fontWeight: 'normal', marginBottom: '8px' }}>
        {section.subtitle}
      </h3>
      <Swiper slidesPerView={2.2} spaceBetween={16}>
        {section.contents.map((data) => (
          <SwiperSlide key={data.id}>
            <img src={data.thumbnail} alt="" css={{ width: '100%' }} />
            <p css={{ fontSize: '14px', color: '#888', lineHeight: '1.3' }}>
              {data.title}
            </p>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

function Banner({ section }: Props) {
  return (
    <div>
      <Swiper
        loop
        spaceBetween={0}
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        modules={[Autoplay]}
      >
        {section.contents.map((data) => (
          <SwiperSlide key={data.id}>
            <img src={data.thumbnail} alt="" css={{ width: '100%' }} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}

export default Preview;
