import React, {
  ClassAttributes,
  ForwardedRef,
  forwardRef,
  TextareaHTMLAttributes,
} from 'react';
import { CSSObject } from '@emotion/react';
import { textarea as t } from './style';

interface Props
  extends TextareaHTMLAttributes<HTMLTextAreaElement>,
    ClassAttributes<HTMLTextAreaElement> {
  css?: CSSObject;
}

function Textarea(
  { css = {}, ...props }: Props,
  ref: ForwardedRef<HTMLTextAreaElement>,
) {
  return <textarea ref={ref} {...props} css={{ ...t, ...css }} />;
}

export default forwardRef(Textarea);
