import React from 'react';
import { CollectionComponent, UiComponent } from 'components';

function List() {
  return (
    <>
      <UiComponent.PageTitle>컬렉션 관리</UiComponent.PageTitle>
      <CollectionComponent.Filter />
      <CollectionComponent.List />
    </>
  );
}

export default List;
