import React from 'react';

import { NOTI_MENU_URL } from 'data/menu';
import { NoticePage } from 'pages';

const noticeApp = [
  {
    path: NOTI_MENU_URL.공지관리,
    element: <NoticePage.List />,
  },
  {
    path: `${NOTI_MENU_URL.공지관리}/:id`,
    element: <NoticePage.Detail />,
  },
];

export { noticeApp };
