import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { FormComponent, UiComponent } from 'components';
import { usePageOption } from 'hooks';
import { tableWrap, tableTop } from 'styles/table';
import { IEvent } from 'types';
import { Link, useNavigate } from 'react-router-dom';
import { BsPlusLg } from 'react-icons/bs';
import * as API from 'api/event';

const COLS: string[] = [
  'ID',
  '봉사명',
  '단체명',
  '모집상태',
  '모집기간',
  '봉사일시',
  '생성자',
  '생성일',
];

function List() {
  const [data, setData] = useState<IEvent[]>([]);
  const navigate = useNavigate();

  const pageState = usePageOption();
  const renderer: Record<
    (typeof COLS)[number],
    (data: IEvent & { row: number }) => ReactNode
  > = useMemo(
    () => ({
      ID: ({ id }: IEvent) => <Link to={`/event/${id}`}>{id}</Link>,
      봉사명: ({ title }: IEvent) => <>{title}</>,
      단체명: ({ organization }: IEvent) => <>{organization}</>,
      모집상태: ({ status }: IEvent) => <>{status}</>,
      모집기간: ({ enlistEndAt, enlistStatrAt }: IEvent) => (
        <>{`${enlistStatrAt} - ${enlistEndAt}`}</>
      ),
      봉사일시: ({ startAt, endAt }: IEvent) => <>{`${startAt} - ${endAt}`}</>,
      생성자: ({ creator }: IEvent) => <>{creator}</>,
      생성일: ({ createdAt }: IEvent) => <>{createdAt}</>,
    }),
    [],
  );

  const fetch = () => {
    API.getAll().then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => fetch(), []);

  return (
    <>
      <UiComponent.Box>
        <div css={tableTop}>
          <p>총 123,456 건</p>
          <div>
            <UiComponent.Button
              variant="contained"
              color="primary"
              onClick={() => navigate('/event/register')}
            >
              <BsPlusLg size={18} />
            </UiComponent.Button>
            <FormComponent.Select
              options={[
                { name: '가입일', value: '1' },
                { name: '최종접속일', value: '2' },
              ]}
            />
            <FormComponent.Select
              options={[
                { name: '20', value: '20' },
                { name: '50', value: '50' },
                { name: '100', value: '100' },
              ]}
            />
          </div>
        </div>
        <div css={tableWrap}>
          <UiComponent.Table
            cols={COLS}
            renderer={renderer}
            data={data}
            sticky
          />
        </div>
        <UiComponent.Pagination {...pageState} />
      </UiComponent.Box>
    </>
  );
}

export default List;
