import React from 'react';

import { CollectionPage, ContentPage, HomeSectionPage } from 'pages';
import { PLATFORM_MENU_URL } from 'data/menu';

const platformApp = [
  {
    path: PLATFORM_MENU_URL['홈 화면 관리'],
    element: <HomeSectionPage.List />,
  },
  {
    path: `${PLATFORM_MENU_URL.컬렉션}`,
    element: <CollectionPage.List />,
  },
  {
    path: `${PLATFORM_MENU_URL.컬렉션}/:id`,
    element: <CollectionPage.Detail />,
  },
  {
    path: `${PLATFORM_MENU_URL.콘텐츠}`,
    element: <ContentPage.List />,
  },
  {
    path: `${PLATFORM_MENU_URL.콘텐츠}/:id`,
    element: <ContentPage.Detail />,
  },
];

export { platformApp };
