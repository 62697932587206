import { IMenu } from 'types';

export const USER_MENU_URL: Record<string, string> = {
  회원목록: '/users',
  쿠폰목록: '/coupon',
};

export const USER_MENU: IMenu = {
  title: '회원관리',
  key: 0,
  items: [
    ...Object.keys(USER_MENU_URL).map((title) => ({
      title,
      to: USER_MENU_URL[title],
    })),
  ],
};

export const EVENT_MENU_URL: Record<string, string> = {
  행사: '/event',
  '추가정보 템플릿': '/event/additional-info',
  '피드백 템플릿': '/feedback',
  파트너: '/partner',
};

export const EVENT_MENU: IMenu = {
  title: '행사관리',
  key: 1,
  items: [
    ...Object.keys(EVENT_MENU_URL).map((title) => ({
      title,
      to: EVENT_MENU_URL[title],
    })),
  ],
};

export const PLATFORM_MENU_URL: Record<string, string> = {
  '홈 화면 관리': '/platform/home',
  컬렉션: '/collection',
  콘텐츠: '/content',
};

export const PLATFORM_MENU: IMenu = {
  title: '플랫폼관리',
  key: 2,
  items: [
    ...Object.keys(PLATFORM_MENU_URL).map((title) => ({
      title,
      to: PLATFORM_MENU_URL[title],
    })),
  ],
};

export const NOTI_MENU_URL: Record<string, string> = {
  메세지센터: '/message',
  // 공지관리: '/notices',
};

export const NOTI_MENU: IMenu = {
  title: '알림센터',
  key: 3,
  items: [
    ...Object.keys(NOTI_MENU_URL).map((title) => ({
      title,
      to: NOTI_MENU_URL[title],
    })),
  ],
};

export const CUSTOMER_SUPPORT_MENU_URL: Record<string, string> = {
  '1:1문의': '/inquiry',
  자주하는질문: '/faq',
};

export const CUSTOMER_SUPPORT_MENU: IMenu = {
  title: '고객지원',
  key: 4,
  items: [
    ...Object.keys(CUSTOMER_SUPPORT_MENU_URL).map((title) => ({
      title,
      to: CUSTOMER_SUPPORT_MENU_URL[title],
    })),
  ],
};

export const ACCOUNTS_MENU_URL: Record<string, string> = {
  계정목록: '/accounts',
  권한관리: '/permission',
};

export const ACCOUNTS_MENU: IMenu = {
  title: '계정관리',
  key: 5,
  items: [
    ...Object.keys(ACCOUNTS_MENU_URL).map((title) => ({
      title,
      to: ACCOUNTS_MENU_URL[title],
    })),
  ],
};

export const MENU_URLS = [
  USER_MENU.items,
  EVENT_MENU.items,
  PLATFORM_MENU.items,
  NOTI_MENU.items,
  CUSTOMER_SUPPORT_MENU.items,
  ACCOUNTS_MENU.items,
];

export const MENUS: IMenu[] = [
  USER_MENU,
  EVENT_MENU,
  PLATFORM_MENU,
  NOTI_MENU,
  // CUSTOMER_SUPPORT_MENU,
  // ACCOUNTS_MENU,
];
