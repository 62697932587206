import React from 'react';

import Main from './Main';
import Dashboard from './Dashboard';
import Event from './Event';
import Coupon from './Coupon';

function Detail() {
  return (
    <>
      <Main />
      <Dashboard />
      <Event />
      <Coupon />
    </>
  );
}

export default Detail;
