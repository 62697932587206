import React, { useState } from 'react';
import { IPartner } from 'types';
import { itemWrap } from './styles';
import Item from './Item';
import useEdit from '../Modal/useEdit';
import Modal from '../Modal';

interface Props {
  data: IPartner[];
  refetch: () => void;
}

function List({ data, refetch }: Props) {
  const [selectedItem, setSelectedItem] = useState<IPartner | null>(null);
  const [editItem, setEditItem] = useState<IPartner | null>(null);
  const { onSave } = useEdit({
    partner: editItem,
    refetch,
    onDone: () => {
      setEditItem(null);
    },
  });

  return (
    <>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          gap: '4px 8px',
          marginBottom: '40px',
        }}
      >
        {data.map((item) => (
          <div key={item.id} css={itemWrap}>
            <Item
              item={item}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
              setEditItem={setEditItem}
              refetch={refetch}
            />
          </div>
        ))}
      </div>
      {editItem && (
        <Modal
          partner={editItem}
          onClose={() => setEditItem(null)}
          onSave={onSave}
        />
      )}
    </>
  );
}

export default List;
