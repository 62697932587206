import React, { ReactNode, useMemo } from 'react';

import { FormComponent, UiComponent } from 'components';
import { usePageOption } from 'hooks';
import { tableWrap, tableTop } from 'styles/table';
import { data } from './data';
import { IUserListItem } from 'types';
import { Link } from 'react-router-dom';
const COLS: string[] = [
  '회원번호',
  '이름',
  '아이디',
  '이메일',
  '계정상태',
  '가입일',
];

function List() {
  const pageState = usePageOption();
  const renderer: Record<
    (typeof COLS)[number],
    (data: IUserListItem & { row: number }) => ReactNode
  > = useMemo(
    () => ({
      회원번호: ({ id }: IUserListItem) => <Link to={'/users/1'}>{id}</Link>,
      이름: ({ name }: IUserListItem) => <>{name}</>,
      아이디: ({ ID }: IUserListItem) => <>{ID}</>,
      이메일: ({ email }: IUserListItem) => <>{email}</>,
      계정상태: ({ status }: IUserListItem) => (
        <>{status === 'normal' ? '정상' : '이용정지'}</>
      ),
      가입일: ({ signUpDate }: IUserListItem) => <>{signUpDate}</>,
    }),
    [],
  );

  return (
    <UiComponent.Box>
      <div css={tableTop}>
        <p>총 123,456 건</p>
        <div>
          <FormComponent.Select
            options={[
              { name: '가입일', value: '1' },
              { name: '최종접속일', value: '2' },
            ]}
          />
          <FormComponent.Select
            options={[
              { name: '20', value: '20' },
              { name: '50', value: '50' },
              { name: '100', value: '100' },
            ]}
          />
        </div>
      </div>
      <div css={tableWrap}>
        <UiComponent.Table cols={COLS} renderer={renderer} data={data} sticky />
      </div>
      <UiComponent.Pagination {...pageState} />
    </UiComponent.Box>
  );
}

export default List;
