import React, { Fragment, PropsWithChildren } from 'react';
import { CheckBox, CheckRadio } from '../';
import { CheckItemType, CheckItemDataType, CheckItemKeyType } from 'types';

interface Props<T> {
  uniqueKey: CheckItemKeyType;
  isAll: boolean;
  handleAll: (checked: boolean) => void;
  idData: CheckItemDataType<T>[];
  handleCheck: (key: string | number, checked: boolean) => void;
  allCheckViewType?: 'check' | 'radio';
  allText?: string;
  readOnly?: boolean;
}

function CheckList<T extends CheckItemType & { displayValue: string }>({
  isAll,
  handleAll,
  idData,
  uniqueKey,
  handleCheck,
  allCheckViewType = 'check',
  allText = '전체',
  readOnly = false,
  children,
}: PropsWithChildren<Props<T>>) {
  return (
    <>
      {allCheckViewType === 'check' ? (
        <CheckBox checked={isAll} handleCheck={handleAll} readOnly={readOnly}>
          <p css={{ fontSize: '15px' }}>{allText}</p>
        </CheckBox>
      ) : (
        <CheckRadio checked={isAll} handleCheck={handleAll} readOnly={readOnly}>
          <p css={{ fontSize: '15px' }}>{allText}</p>
        </CheckRadio>
      )}
      {children}
      {idData.map((val, i) => (
        <Fragment key={i}>
          <CheckBox
            checked={val.checked}
            handleCheck={(checked) =>
              handleCheck(val[uniqueKey] || '', checked)
            }
            readOnly={readOnly}
          >
            <p css={{ lineHeight: '17px', fontSize: '15px' }}>
              {val.displayValue}
            </p>
          </CheckBox>
        </Fragment>
      ))}
    </>
  );
}

export default CheckList;
