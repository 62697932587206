export const QUESTION_INPUT_OPTION = [
  {
    name: '객관식(단일)',
    value: '1',
  },
  {
    name: '객관식(다중)',
    value: '2',
  },
  {
    name: '선형배율',
    value: '3',
  },
  {
    name: '주관식',
    value: '4',
  },
] as const;

export type QuestionType = 'additional' | 'feedback';

export type InputType = (typeof QUESTION_INPUT_OPTION)[number]['value'];

export interface IQuestion {
  id: number;
  type: InputType;
  title: string;
  description: string;
  options?: string[];
  isRequired?: boolean;
  maxOption?: number;
}
