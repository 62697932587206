import useAutoRemoveAuth from 'hooks/useAutoRemoveAuth';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAppSelector } from 'stores';

function Private() {
  const { login } = useAppSelector((state) => state.auth);
  useAutoRemoveAuth();

  if (login) {
    return <Outlet />;
  }

  return <Navigate to={'/login'} />;
}

export default Private;
