import { ICoupon } from 'types';
// import { base } from './';
import dayjs from 'dayjs';

// const PATH = '/coupon';

const MOCK_DATA: ICoupon[] = [
  {
    id: 1,
    type: '봉켓팅 면제권',
    status: 'created',
    createdAt: dayjs().format('YYYY-MM-DD'),
    creator: '오*진',
  },
];

const find = (id: number) => MOCK_DATA.find((item) => item.id === id);

// const get = (id: number) => base.get(`${PATH}/${id}`);
const get = (id: number) =>
  new Promise((resolve) => resolve({ data: find(id) }));

// const getAll = () => base.get(PATH);
const getAll = () =>
  new Promise<{ data: ICoupon[] }>((resolve) => resolve({ data: MOCK_DATA }));

// const post = () => base.post(PATH);
const post = () => {
  const coupon: ICoupon = {
    id: MOCK_DATA.length + 1,
    type: '봉켓팅 면제권',
    status: 'created',
    createdAt: dayjs().format('YYYY-MM-DD'),
    creator: '오*진',
  };

  MOCK_DATA.push(coupon);
  return new Promise((resolve) => resolve(true));
};

// const put = (id: number) => base.put(`${PATH}/${id}`);
const put = (id: number) => {
  const coupon = find(id);
  if (coupon) {
    coupon.status = 'issued';
    coupon.startAt = dayjs().format('YYYY-MM-DD HH:mm:ss');
    coupon.endAt = dayjs().add(365, 'day').format('YYYY-MM-DD HH:mm:ss');
    coupon.issuedUserId = 1;
    coupon.issuedUserName = '최*수';
  }

  return new Promise((resolve) => resolve(true));
};

export { get, getAll, post, put };
