import React from 'react';

import { createBrowserRouter } from 'react-router-dom';
import { privateApp } from './private';
import { unauthorizedApp } from './unauthorizeApp';
import { LayoutComponent } from 'components';

const router = createBrowserRouter([
  {
    element: <LayoutComponent.Base />,
    children: [unauthorizedApp, privateApp],
  },
]);

export default router;
