import { END_TIME_VALUE, ITEMS, TIME_ITEMS } from './constatns';
import { DateItemType } from 'types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { ISelectOption } from 'types';

interface Props {
  itemLength: number;
  dateFormat: 'yyyy.MM' | 'yyyy.MM.dd';
  time: boolean;
  start: Date | null;
  end: Date | null;
  onlyHour?: boolean;
  checkTodayTimes?: boolean;
}

function useDateRangePicker({
  itemLength,
  dateFormat,
  time,
  start,
  end,
  checkTodayTimes = false,
  onlyHour = false,
}: Props) {
  const [defaultStartTimeItems, setDefaultStartTimeItems] = useState<
    ISelectOption<string> | undefined
  >(undefined);
  const [defaultEndTimeItems, setDefaultEndTimeItems] = useState<
    ISelectOption<string> | undefined
  >(undefined);

  const items = useMemo(() => ITEMS.slice(0, itemLength), [itemLength]);

  const startTimeItems = useMemo(
    () => getTimeItems(start, onlyHour, checkTodayTimes, defaultStartTimeItems),
    [onlyHour, start, checkTodayTimes, defaultStartTimeItems],
  );

  const endTimeItems = useMemo(
    () => getTimeItems(end, onlyHour, checkTodayTimes, defaultEndTimeItems),
    [onlyHour, end, checkTodayTimes, defaultEndTimeItems],
  );

  /**
   *  선택된 빠른 기간 버튼 상태
   */
  const [selectedItem, setSelectedItem] = useState<DateItemType | undefined>(
    undefined,
  );

  /**
   *  선택된 시간 셀렉트 값 ex) 23:00
   */
  const [startTime, setStartTime] = useState<string>(startTimeItems[0].value);
  const [endTime, setEndTime] = useState<string>(
    endTimeItems[endTimeItems.length - 1].value,
  );

  useEffect(() => {
    if (!time) return;

    if (!startTimeItems.find((o) => o.value === startTime)) {
      setStartTime(startTimeItems[0].value);
    }

    if (!endTimeItems.find((o) => o.value === endTime)) {
      setEndTime(endTimeItems[0].value);
    }
  }, [time, startTime, endTime, startTimeItems, endTimeItems]);

  /**
   * 빠른 기간 선택 버튼 클릭 여부
   * @param item
   */
  const checkItemSelected = (item: DateItemType) => {
    if (!selectedItem) return false;

    // 시:분 옵션있을 때는 YYYY-MM-DD HH:mm 까지 일치해야함.
    return (
      item.label === selectedItem.label &&
      (time ? startTime === '0' && endTime === END_TIME_VALUE : true)
    );
  };

  /**
   *  종료일이 시작일보다 앖설 수 없게
   */
  const validate = useCallback((s: Date, e: Date) => {
    if (s.getTime() > e.getTime()) {
      if (
        dateFormat === 'yyyy.MM' &&
        s.getFullYear() === e.getFullYear() &&
        s.getMonth() === e.getMonth()
      )
        return true;
      alert('종료일은 시작일보다 작을 수 없습니다.');
      return false;
    }
    setSelectedItem(undefined);
    return true;
  }, []);

  /**
   * 리셋 핸들러
   */
  const handleReset = useCallback(() => {
    setStartTime(TIME_ITEMS[0].value);
    setEndTime(TIME_ITEMS[0].value);
    setSelectedItem(undefined);
  }, []);

  return {
    items,
    selectedItem,
    setSelectedItem,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    validate,
    handleReset,
    checkItemSelected,
    startTimeItems,
    endTimeItems,
    setDefaultStartTimeItems,
    setDefaultEndTimeItems,
  };
}

const getTimeItems = (
  time: Date | null,
  onlyHour?: boolean,
  checkTodayTimes?: boolean,
  defaultItem?: ISelectOption<string>,
) => {
  const items = [...TIME_ITEMS];
  if (onlyHour) items.pop();

  if (checkTodayTimes && time) {
    return filterTodayTimeItems(items, time, defaultItem);
  }

  return items;
};

const filterTodayTimeItems = (
  items: ISelectOption<string>[],
  time: Date,
  defaultItem?: ISelectOption<string>,
) => {
  const today = new Date();

  if (dayjs(today).startOf('day').isSame(dayjs(time).startOf('day'))) {
    const MAX_DEL_COUNT = 23;
    const delCnt = today.getHours() + 1;
    const timeItems = items.slice(23 > MAX_DEL_COUNT ? MAX_DEL_COUNT : delCnt);
    const existDefault = timeItems.find((t) => t.value === defaultItem?.value);

    return defaultItem && !existDefault
      ? [defaultItem].concat(timeItems)
      : timeItems;
  }

  return items;
};

export default useDateRangePicker;
