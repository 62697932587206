import { useEffect, useMemo } from 'react';

import { ITEMS } from './constatns';
import { checkIsInvalidDate, createInvalidDate } from './utils';
import { DateItemType } from 'types';

interface Props {
  start: Date | null;
  end: Date | null;
  items: DateItemType[];
  handleStart: (date: Date) => void;
  handleEnd: (date: Date) => void;
  selectedItem: DateItemType | undefined;
  setSelectedItem: (item: DateItemType | undefined) => void;
  isWholeRangeAvailable?: boolean;
}

function useWholeDateRange({
  start,
  end,
  items,
  isWholeRangeAvailable,
  handleStart,
  handleEnd,
  setSelectedItem,
}: Props) {
  const startShouldBeDisabled = useMemo(
    () => start !== null && checkIsInvalidDate(start),
    [start],
  );

  const endShouldBeDisabled = useMemo(
    () => end !== null && checkIsInvalidDate(end),
    [end],
  );

  useEffect(() => {
    if (!isWholeRangeAvailable || !start || !end) return;

    const WHOLE_RANGE_ITEM = ITEMS.find(
      ({ label }) => label === '전체',
    ) as DateItemType;
    if (
      WHOLE_RANGE_ITEM &&
      checkIsInvalidDate(start) &&
      checkIsInvalidDate(end)
    ) {
      setSelectedItem(WHOLE_RANGE_ITEM);
      return;
    }
  }, [start, end, isWholeRangeAvailable]);

  useEffect(() => {
    if (!start || !end) return;

    if (
      !isWholeRangeAvailable &&
      (checkIsInvalidDate(start) || checkIsInvalidDate(end)) &&
      items.length > 1
    ) {
      const end = new Date(new Date().setHours(23, 59, 59, 999));
      const next = ITEMS[ITEMS.length - 2];

      handleStart(next.getStart(end));
      handleEnd(end);
    }
  }, [start, end, isWholeRangeAvailable, items]);

  /**
   * start, end 중 한쪽이라도 Invalid Date 일 때 모두 Invalid Date 로 초기화한다.
   */
  useEffect(() => {
    if (!isWholeRangeAvailable || !start || !end) return;

    if (checkIsInvalidDate(start) && !checkIsInvalidDate(end)) {
      handleEnd(createInvalidDate());
      return;
    }

    if (!checkIsInvalidDate(start) && checkIsInvalidDate(end)) {
      handleStart(createInvalidDate());
      return;
    }
  }, [start, end, isWholeRangeAvailable]);

  return {
    startShouldBeDisabled,
    endShouldBeDisabled,
  };
}

export default useWholeDateRange;
