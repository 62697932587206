import React, { ReactNode, useMemo, useState } from 'react';

import {
  AdditionalComponent,
  FeedbackComponent,
  FormComponent,
  UiComponent,
} from 'components';
import { usePageOption } from 'hooks';
import { tableWrap } from 'styles/table';
import { data } from './data';
import { IUserListItem } from 'types';
import { Link } from 'react-router-dom';
import { detailWrap } from 'styles/table';
import { CheckBox } from 'components/form';
import UserMemoModal from 'components/user/UserMemoModal';

const COLS = [
  '신청상태',
  '이름',
  '성별',
  '등급',
  '연락처',
  '신청일',
  '최근참여일',
  '출결상태',
  '쿠폰사용정보',
  '피드백작성여부',
  '추가정보',
  '메모',
];

function VolunteerList() {
  const [isOpenUserMemo, setIsOpenUserMemo] = useState(false);
  const [isOpenAdditional, setIsOpenAdditional] = useState(false);
  const [isOpenFeedback, setIsOpenFeedback] = useState(false);

  const pageState = usePageOption();
  const cols = useMemo(
    () => [
      {
        key: 'checkbox',
        render: () => <CheckBox />,
      },
      ...COLS,
    ],
    [],
  );

  const renderer: Record<
    (typeof COLS)[number],
    (data: IUserListItem & { row: number }) => ReactNode
  > = useMemo(
    () => ({
      checkbox: () => <CheckBox />,
      신청상태: () => '신청완료',
      이름: ({ name }: IUserListItem) => <Link to={'/users/1'}>{name}</Link>,
      성별: () => '남',
      등급: () => '일반',
      연락처: ({ phoneNum }: IUserListItem) => <>{phoneNum}</>,
      신청일: ({ signUpDate }: IUserListItem) => <>{signUpDate}</>,
      최근참여일: ({ signUpDate }: IUserListItem) => <>{signUpDate}</>,
      출결상태: () => (
        <div css={{ width: '100px' }}>-{/* 출석 / 결석 / 지각 / 노쇼 */}</div>
      ),
      쿠폰사용정보: () => '-',
      피드백작성여부: () => (
        <button onClick={() => setIsOpenFeedback(true)}>상세보기</button>
      ),
      추가정보: () => (
        <button onClick={() => setIsOpenAdditional(true)}>상세보기</button>
      ),
      메모: () => (
        <button onClick={() => setIsOpenUserMemo(true)}>상세보기</button>
      ),
    }),
    [],
  );

  return (
    <>
      <div css={[detailWrap, { marginTop: '24px' }]}>
        <h3>참석자 정보</h3>
        <div
          css={{
            marginTop: '24px',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0 4px',
            }}
          >
            <FormComponent.Select
              options={[
                { name: '출석', value: '출석' },
                { name: '지각', value: '지각' },
                { name: '노쇼', value: '노쇼' },
              ]}
            />
            <UiComponent.Button
              color="primary"
              variant="contained"
              css={{ height: '35px' }}
            >
              출결상태 변경
            </UiComponent.Button>
          </div>
          <div
            css={{
              display: 'flex',
              flexDirection: 'row',
              gap: '0 4px',
            }}
          >
            <UiComponent.Button
              color="primary"
              variant="border"
              css={{ height: '35px' }}
            >
              엑셀 다운로드
            </UiComponent.Button>
            <UiComponent.Button
              color="primary"
              variant="border"
              css={{ height: '35px' }}
            >
              출석부 생성
            </UiComponent.Button>
          </div>
        </div>
        <div css={tableWrap}>
          <UiComponent.Table
            cols={cols}
            renderer={renderer}
            data={data}
            sticky
          />
        </div>
        <UiComponent.Pagination {...pageState} />
      </div>
      {isOpenUserMemo && (
        <UserMemoModal id={1} onClose={() => setIsOpenUserMemo(false)} />
      )}
      {isOpenAdditional && (
        <AdditionalComponent.ViewModal
          id={1}
          onClose={() => setIsOpenAdditional(false)}
        />
      )}
      {isOpenFeedback && (
        <FeedbackComponent.ViewModal
          id={1}
          onClose={() => setIsOpenFeedback(false)}
        />
      )}
    </>
  );
}

export default VolunteerList;
