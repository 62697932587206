import { ModalComponent, UiComponent } from 'components';
import React, { useEffect, useState } from 'react';
import { ICollection, SectionType } from 'types';
import * as API from 'api/collection';
import * as ContentAPI from 'api/content';

interface Props {
  handleSelect: (section: SectionType) => void;
  handleClose: () => void;
}

function CollectionModal({ handleSelect, handleClose }: Props) {
  const [data, setData] = useState<ICollection[]>([]);

  const handleSelectCollection = (collection: ICollection) => {
    ContentAPI.filter(collection.contentIds).then((r) => {
      handleSelect({
        contents: r.data,
        title: collection.title,
        subtitle: collection.subtitle,
        id: collection.id,
        type: collection.type,
      });
      handleClose();
    });
  };

  const fetch = () => {
    API.getAll().then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => fetch(), []);
  return (
    <ModalComponent.Portal>
      <ModalComponent.Background>
        <ModalComponent.Modal title="" handleClose={handleClose}>
          <ul
            css={{
              width: '600px',
              overflowY: 'scroll',
              '> li': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 0',
                borderBottom: '1px solid #ddd',
              },
            }}
          >
            {data.map((c) => (
              <li key={c.id}>
                <p>{c.name}</p>
                <UiComponent.Button
                  color="primary"
                  variant="border"
                  onClick={() => handleSelectCollection(c)}
                >
                  선택
                </UiComponent.Button>
              </li>
            ))}
          </ul>
        </ModalComponent.Modal>
      </ModalComponent.Background>
    </ModalComponent.Portal>
  );
}

export default CollectionModal;
