import React, { Fragment, useEffect, useState } from 'react';

import { ModalComponent } from 'components';
import * as API from 'api/event';
import * as PartnerAPI from 'api/partner';
import { IPartner } from 'types';

interface Props {
  id: number;
  partners: number[];
  onClose: () => void;
}

function Partner({ id, partners, onClose }: Props) {
  const [partnerList, setPartnerList] = useState<IPartner[]>([]);
  const [selectedPartners, setSelectedPartners] = useState<IPartner[]>([]);

  const fetch = () => {
    PartnerAPI.getAll().then((res) => {
      const partnerList: IPartner[] = [];
      const selectedPartners: IPartner[] = [];
      res.data.forEach((item) => {
        if (partners.includes(item.id)) {
          selectedPartners.push(item);
        } else {
          partnerList.push(item);
        }
      });
      setPartnerList(partnerList);
      setSelectedPartners(selectedPartners);
    });
  };

  const handleSelect = (partner: IPartner) => {
    const newPartners = [...selectedPartners, partner];
    API.putPartners({ id, partners: newPartners.map((p) => p.id) }).then(() => {
      setSelectedPartners(newPartners);
      setPartnerList(partnerList.filter((item) => item.id !== partner.id));
    });
  };

  const handleRemove = (partner: IPartner) => {
    const newPartners = selectedPartners.filter(
      (item) => item.id !== partner.id,
    );

    API.putPartners({ id, partners: newPartners.map((p) => p.id) }).then(() => {
      setSelectedPartners(newPartners);
      setPartnerList([...partnerList, partner]);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <ModalComponent.Portal>
        <ModalComponent.Background>
          <ModalComponent.Modal title="" handleClose={onClose} footer={<></>}>
            <div
              css={{
                display: 'flex',
                flexDirection: 'column',
                gap: '24px 0',
                '> p': {
                  marginTop: '12px',
                  fontWeight: 'bold',
                },
                '> div': {
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  gap: '8px',
                  '> div': {
                    position: 'relative',
                    display: 'flex',
                    width: '80px',
                    height: '80px',
                    background: '#f5f5f5',
                    borderRadius: '50%',
                    userSelect: 'none',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    '> div': {
                      position: 'absolute',
                      width: '80px',
                      height: '80px',
                      background: 'black',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      opacity: 0.7,
                    },
                  },
                },
              }}
            >
              {selectedPartners.length > 0 && (
                <>
                  <p>선택 목록</p>
                  <div>
                    {selectedPartners.map((partner) => (
                      <Fragment key={partner.id}>
                        <PartnerItem
                          partner={partner}
                          hoverTitle="제거"
                          handleSelect={handleRemove}
                        />
                      </Fragment>
                    ))}
                  </div>
                </>
              )}
              <p>파트너 목록</p>
              <div>
                {partnerList.map((partner) => (
                  <Fragment key={partner.id}>
                    <PartnerItem
                      partner={partner}
                      hoverTitle="선택"
                      handleSelect={handleSelect}
                    />
                  </Fragment>
                ))}
              </div>
            </div>
          </ModalComponent.Modal>
        </ModalComponent.Background>
      </ModalComponent.Portal>
    </>
  );
}

function PartnerItem({
  partner,
  hoverTitle,
  handleSelect,
}: {
  hoverTitle: string;
  partner: IPartner;
  handleSelect: (partner: IPartner) => void;
}) {
  const [isHover, setIsHover] = useState(false);

  return (
    <div
      onClick={() => !partner.disabled && handleSelect(partner)}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      css={{
        '> div > p': {
          color: '#fff',
          fontWeight: 'bold',
        },
      }}
    >
      <img src={partner.thumbnail} alt="" width={60} />
      {isHover && (
        <div>
          <p>{hoverTitle}</p>
        </div>
      )}
      {partner.disabled && (
        <div>
          <p>사용중지</p>
        </div>
      )}
    </div>
  );
}

export default Partner;
