import { CSSObject, Theme } from '@emotion/react';

const wrap = (theme: Theme): CSSObject => ({
  width: '100vw',
  height: '100vh',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#f7f7f7',
  '> h1': {
    fontSize: '28px',
    fontWeight: 400,
  },
  '> p': {
    marginTop: '20px',
    color: '#999',
    fontSize: '13px',
    lineHeight: '20px',
  },
  '> div': {
    marginTop: '30px',
    padding: '20px 30px 60px',
    display: 'flex',
    flexDirection: 'column',
    background: 'white',
    '> div': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: '20px',
      '> a': {
        width: '50%',
        color: '#333',
        fontSize: '14px',
        textAlign: 'center',
        textDecoration: 'none',
      },
      '> span': {
        fontSize: '14px',
        color: '#d8d8d8',
      },
    },
    '> form': {
      display: 'flex',
      flexDirection: 'column',
      '> input + input': {
        marginTop: '10px',
      },
      '> button': {
        width: '340px',
        height: '56px',
        borderRadius: '3px',
        fontSize: '16px',
        margin: '30px 0 10px 0',
        background: theme.color.primary,
        color: theme.color.white,
      },
    },
  },
});

const inpuyStyle: CSSObject = {
  padding: '0 20px',
  border: '1px solid',
  borderColor: '#ccc',
  fontSize: '16px',
  height: '54px',
  borderRadius: '3px',
  ':focus': {
    outline: 'none',
    borderColor: '#333',
  },
  '::placeholder': {
    color: '#ccc',
  },
};

export { wrap, inpuyStyle };
