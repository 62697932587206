import React, { useState } from 'react';
import { detailWrap } from 'styles/table';

import Action from './Action';
import useDetail from '../useDetail';
import { THUMBNAIL_DATA } from '../ThumbnailSelector/data';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';
import { useNavigate } from 'react-router-dom';
import { EVENT_MENU_URL } from 'data/menu';
import { detailPtagStyles } from './styles';
import Editor from './Editor';
import useEditor from './useEditor';
import useOpenKakao from './useOpenKakao';
import OpenKakao from './OpenKakao';
import Partner from './Partner';

dayjs.locale('ko');
const FORMAT = 'MM월 DD일 (ddd)';

function Main() {
  const navigate = useNavigate();
  const { event, refetch } = useDetail();
  const {
    title,
    value,
    isOpenEditor,
    handleOpen,
    handleClose,
    handleSaveEditor,
  } = useEditor({ event });
  const { isOpenKakao, setIsOpenKakao, handleSave } = useOpenKakao({ event });
  const [isOpenPartner, setIsOpenPartner] = useState(false);

  const handleQuestion = (type: 'additional' | 'feedback') => {
    navigate(`${EVENT_MENU_URL.행사}/${event?.id}/${type}`);
  };

  if (!event) return null;

  return (
    <>
      <div
        css={(theme) => [
          detailWrap(theme),
          { marginTop: '24px', display: 'flex', position: 'relative' },
        ]}
      >
        <Action event={event} refetch={refetch} />
        <img
          src={THUMBNAIL_DATA[Number(event.thumbnail || '1')]}
          alt="thumnail"
          width={200}
          height={200}
          css={{ borderRadius: 8, marginRight: '16px' }}
        />
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            padding: '8px 0',
          }}
        >
          <div>
            <p css={{ fontWeight: 'bold', fontSize: '16px' }}>{event.title}</p>
            <ul
              css={{
                marginTop: '20px',
                display: 'flex',
                flexDirection: 'row',
                gap: '0 32px',
                '> div > li': {
                  display: 'flex',
                  fontSize: '13px',
                  marginBottom: '12px',
                  alignItems: 'center',
                  'p:nth-of-type(1)': {
                    fontWeight: 'bold',
                    color: '#8d8d8d',
                    textAlign: 'justify',
                    width: '100px',
                  },
                },
              }}
            >
              <div>
                <li>
                  <p>상 태</p>
                  <p>{event.status}</p>
                </li>
                <li>
                  <p>모집기간</p>
                  <p>{`${dayjs(event.enlistStatrAt).format(FORMAT)} ~ ${dayjs(event.enlistEndAt).format(FORMAT)}`}</p>
                </li>
                <li>
                  <p>일 시</p>
                  <p>{`${dayjs(event.startAt).format(FORMAT)} ~ ${dayjs(event.endAt).format(FORMAT)}`}</p>
                </li>
                <li>
                  <p>장 소</p>
                  <p>{event.address}</p>
                </li>
                <li>
                  <p>모집인원</p>
                  <p>{`${event.headcount}명`}</p>
                </li>
                <li>
                  <p>인증기관</p>
                  <p>{event.ca.join(', ')}</p>
                </li>
              </div>
              <div>
                <li>
                  <p>추가정보</p>
                  <p
                    css={detailPtagStyles}
                    onClick={() => handleQuestion('additional')}
                  >
                    {event.additionalInfo ? '수정' : '등록'}
                  </p>
                </li>
                <li>
                  <p>피드백</p>
                  <p
                    css={detailPtagStyles}
                    onClick={() => handleQuestion('feedback')}
                  >
                    {event.feedBackInfo ? '수정' : '등록'}
                  </p>
                </li>
                <li>
                  <p>행사 상세</p>
                  <p css={detailPtagStyles} onClick={() => handleOpen('desc')}>
                    {event.description ? '수정' : '등록'}
                  </p>
                </li>
                <li>
                  <p>행사 유의사항</p>
                  <p
                    css={detailPtagStyles}
                    onClick={() => handleOpen('notice')}
                  >
                    {event.notice ? '수정' : '등록'}
                  </p>
                </li>
                <li>
                  <p>행사 후원사</p>
                  <p
                    css={detailPtagStyles}
                    onClick={() => setIsOpenPartner(true)}
                  >
                    {event.partners.length ? '수정' : '등록'}
                  </p>
                </li>
                <li>
                  <p>오픈채팅방 링크</p>
                  <p
                    css={detailPtagStyles}
                    onClick={() => setIsOpenKakao(true)}
                  >
                    {event.openKaKao ? '수정' : '등록'}
                  </p>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
      {isOpenEditor && (
        <Editor
          title={title}
          value={value}
          onSave={handleSaveEditor}
          onClose={handleClose}
        />
      )}
      {isOpenKakao && (
        <OpenKakao
          openKakao={event.openKaKao}
          onSave={handleSave}
          onClose={() => setIsOpenKakao(false)}
        />
      )}
      {isOpenPartner && (
        <Partner
          id={event.id}
          partners={event.partners}
          onClose={() => setIsOpenPartner(false)}
        />
      )}
    </>
  );
}

export default Main;
