import dayjs from 'dayjs';
// import { base } from './';
import { ICollection } from 'types';

// const PATH = '/collection';
const MOCK_DATA: ICollection[] = [
  {
    id: 1,
    name: '봉터뷰',
    title: '봉터뷰',
    subtitle: '나에게 연봉인상은? 🤔',
    type: 'default',
    contentIds: [10001, 10002, 10003, 10004, 10005, 10006, 10007],
    creator: '최지수',
    updater: '최지수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 2,
    name: '메인 배너',
    title: '메인 배너 용',
    subtitle: '메인 배너 용',
    type: 'main',
    contentIds: [20001, 20002, 20003],
    creator: '최지수',
    updater: '최지수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 3,
    name: '연봉인상',
    title: 'YVIS',
    subtitle: '연마다 봉사를 늘린다 ❤️',
    type: 'default',
    contentIds: [30001, 30002, 30003],
    creator: '최지수',
    updater: '최지수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
];

// const get = (id: number) => base.get(`${PATH}/${id}`);
const get = (id: number) =>
  new Promise<{ data?: ICollection }>((resolve) =>
    resolve({ data: MOCK_DATA.find((item) => item.id === id) }),
  );

//   const getAll = () => base.get(PATH);
const getAll = () =>
  new Promise<{ data: ICollection[] }>((resolve) =>
    resolve({ data: MOCK_DATA }),
  );

export { get, getAll };
