import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IModalState, IUiStore, IConfirmModal } from 'types';

const initialState: IUiStore = {
  loading: false,
  isOpenAlert: false,
  alertContent: '',
  isOpenConfirm: false,
  confirmContent: '',
  modals: [],
};

const {
  reducer,
  actions: {
    loaded,
    loading,
    openAlert,
    closeAlert,
    openConfirm,
    confirm,
    closeConfirm,
    openModal,
    closeModal,
    clearAll,
    clearModal,
  },
} = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    loading: (state) => {
      state.loading = true;
    },
    loaded: (state) => {
      state.loading = false;
    },
    openAlert: (
      state,
      action: PayloadAction<Pick<IUiStore, 'alertContent' | 'alertNext'>>,
    ) => {
      state.isOpenAlert = true;
      state.alertContent = action.payload.alertContent;
      state.alertNext = action.payload.alertNext;
    },
    closeAlert: (state) => {
      state.isOpenAlert = false;
      state.alertNext?.();
      state.alertNext = undefined;
    },
    openConfirm: (state, action: PayloadAction<IConfirmModal>) => {
      state.isOpenConfirm = true;
      state.confirmContent = action.payload.confirmContent;
      state.yesTitle = action.payload.yesTitle;
      state.noTitle = action.payload.noTitle;
      state.confirmNext = action.payload.confirmNext;
      state.confirmClose = action.payload.confirmClose;
    },
    confirm: (state) => {
      state.isOpenConfirm = false;
      state.confirmNext?.();
      state.confirmNext = undefined;
      state.confirmClose = undefined;
    },
    closeConfirm: (state) => {
      state.isOpenConfirm = false;
      state.confirmClose?.();
      state.confirmNext = undefined;
      state.confirmClose = undefined;
    },
    openModal: (state, action: PayloadAction<IModalState>) => {
      state.modals.push(action.payload);
    },
    closeModal: (state) => {
      state.modals.pop();
    },
    clearModal: (state) => {
      state.modals = [];
    },
    clearAll: (state) => {
      state.loading = false;
      state.isOpenAlert = false;
      state.alertContent = '';
      state.isOpenConfirm = false;
      state.confirmContent = '';
      state.modals = [];
    },
  },
});

export {
  initialState,
  loaded,
  loading,
  openAlert,
  closeAlert,
  openConfirm,
  confirm,
  closeConfirm,
  openModal,
  closeModal,
  clearAll,
  clearModal,
};
export default reducer;
