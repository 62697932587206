import React, {
  ClassAttributes,
  InputHTMLAttributes,
  forwardRef,
  ForwardedRef,
} from 'react';
import { Interpolation, Theme } from '@emotion/react';
import { wrap } from './style/default';

interface Props
  extends InputHTMLAttributes<HTMLInputElement>,
    ClassAttributes<HTMLInputElement> {
  css?: Interpolation<Theme>;
}

function Input(
  { css = {}, ...props }: Props,
  ref: ForwardedRef<HTMLInputElement>,
) {
  return <input ref={ref} {...props} css={[wrap, css]} />;
}

export default forwardRef(Input);
