import { CSSObject } from '@emotion/react';

const wrap: CSSObject = {
  paddingTop: '12px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

const btn: CSSObject = {
  background: '#fff',
  border: '1px solid #ddd',
  borderRight: 'none',
};

export { wrap, btn };
