import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import icArrow from '../static/arrow.svg';
import { menuWrap, menuArrow, subMenu, subMenuWrap } from './style';
import { MENUS } from 'data/menu';
import useMenus from './useMenus';

function Menus() {
  const { first, current, second, handleFirst } = useMenus();
  const [isOpenCurrent, setIsOpenCurrent] = useState(true);

  const isOpenSubMenu = (key: number) => {
    if (key === current) return isOpenCurrent;
    return first === key;
  };

  const handleClickMenu = (key: number) => {
    if (key === current) setIsOpenCurrent((prev) => !prev);
    else handleFirst(key);
  };

  useEffect(() => {
    setIsOpenCurrent(true);
  }, [current]);

  return (
    <ul css={menuWrap}>
      {MENUS.map((m, i) => (
        <React.Fragment key={i}>
          <li onClick={() => handleClickMenu(m.key)}>
            <div>{m.title}</div>
            <img
              src={icArrow}
              alt=""
              width={10}
              height={6}
              css={menuArrow(m.key === first)}
            />
          </li>
          <div css={subMenuWrap(isOpenSubMenu(m.key))}>
            {m.items.map((d, i) => (
              <NavLink
                key={i}
                to={`${d.to}`}
                css={subMenu(current === m.key && second === i)}
              >
                {d.title}
              </NavLink>
            ))}
          </div>
        </React.Fragment>
      ))}
    </ul>
  );
}

export default Menus;
