import React from 'react';

import { FeedbackComponent, UiComponent } from 'components';

function List() {
  return (
    <>
      <UiComponent.PageTitle>피드백 템플릿 목록</UiComponent.PageTitle>
      <FeedbackComponent.Filter />
      <FeedbackComponent.List />
    </>
  );
}

export default List;
