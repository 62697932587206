import { theme } from 'styles';
import { CSSObject } from '@emotion/react';

type VariantType = 'contained' | 'border' | 'text';
type ColorType = keyof typeof theme.color;

const contained = (color: ColorType): CSSObject => ({
  backgroundColor: theme.color[color],
  ...(color === 'white'
    ? { color: theme.color.black }
    : { color: theme.color.white }),
  ...(color === 'background' && { color: theme.color.grey }),
});

const border = (color: ColorType): CSSObject => ({
  border: `solid 1px currentColor`,
  color: theme.color[color],
});

const text = (color: ColorType): CSSObject => ({
  color: theme.color[color],
});

const button = (variant: VariantType, color: ColorType): CSSObject => {
  const common = {
    padding: '4px 8px',
    fontSize: 'inherit',
    color: 'inherit',
    cursor: 'pointer',
    borderRadius: 3,
    transition: 'opacity 0.1s ease-out',
    fontWeight: 500,

    '&:hover, &:active': {
      opacity: 0.7,
    },

    '&:disabled': {
      cursor: 'not-allowed',
      ...(variant === 'border' && { borderColor: theme.color.disabled }),
      color: theme.color.white,
      backgroundColor: `${theme.color.disabled} !important`,
      opacity: 0.5,
    },
  };

  if (variant === 'contained') {
    return { ...common, ...contained(color) };
  }

  if (variant === 'border') {
    return { ...common, ...border(color) };
  }

  if (variant === 'text') {
    return { ...common, ...text(color) };
  }

  return common;
};

const iconButton: CSSObject = {
  border: '1px solid #eee',
  height: '36px',
  padding: '0 8px',
  marginRight: '4px',
  borderRadius: '4px',
};

const listBtnStyle = (color: string): CSSObject => ({
  color,
  background: '#f6f6f6',
  borderRadius: '3px',
  height: '22px',
  padding: '0 8px',
});

export type { VariantType, ColorType };
export { button, listBtnStyle, iconButton };
