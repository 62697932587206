import React, {
  ForwardedRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  Container as MapDiv,
  NaverMap,
  useNavermaps,
  Marker,
} from 'react-naver-maps';
import Form from '../Form';
import { FormComponent, UiComponent } from 'components';
import { IMapPosition } from 'types';

const X = 37.3595704;
const Y = 127.105399;

interface IAddress {
  roadAddress: string;
  x: string;
  y: string;
}

interface Props {
  latitude?: number;
  longitude?: number;
  mapMarker?: IMapPosition;
}

function SearchMap(
  { latitude, longitude, mapMarker }: Props,
  ref: ForwardedRef<{ getData: () => void }>,
) {
  const [mapRef, setMapRef] = useState<{
    setCenter: (point: unknown) => void;
    center: IMapPosition;
  } | null>(null);
  const navermaps = useNavermaps();
  const [markerPosition, setMarkerPosition] = useState<IMapPosition | null>(
    mapMarker || null,
  );
  const [errorMessage, setErrorMessage] = useState('');

  const handleClick = (e: { latlng: IMapPosition }) => {
    setMarkerPosition(e.latlng);
  };

  useEffect(() => {
    if (mapRef) {
      navermaps.Event.addListener(mapRef, 'click', handleClick);
    }

    return () => {
      if (mapRef) {
        navermaps.Event.clearListeners(mapRef, 'click');
      }
    };
  }, [mapRef]);

  const handleSearch = (
    status: number,
    response: {
      v2: {
        addresses: IAddress[];
        meta: { totalCount: number };
      };
    },
  ) => {
    if (status !== 200) {
      return;
    }

    const {
      v2: {
        addresses,
        meta: { totalCount },
      },
    } = response;

    if (totalCount < 1 || addresses.length < 1) {
      setErrorMessage('도로명 주소로 입력해주세요');
      return;
    }

    const point = new navermaps.Point(addresses[0].x, addresses[0].y);
    mapRef?.setCenter(point);
  };

  const handleSubmit = (data: { query: string }) => {
    if (!data.query) {
      setErrorMessage('검색어를 입력해주세요');
      return;
    }

    setErrorMessage('');
    navermaps.Service.geocode(data, handleSearch);
  };

  useImperativeHandle(ref, () => ({
    getData: () => {
      if (!mapRef) return { _lng: X, _lat: Y };
      return { ...mapRef.center, mapMarker: markerPosition };
    },
  }));

  return (
    <>
      <Form submit={handleSubmit}>
        <FormComponent.Input
          type="text"
          name="query"
          autoComplete="off"
          css={{ height: '28px' }}
        />
        <UiComponent.Button
          type="submit"
          variant="contained"
          color="primary"
          css={{ marginLeft: '4px' }}
        >
          주소검색
        </UiComponent.Button>
      </Form>
      <p css={{ fontSize: '11px', color: 'red', marginTop: '4px' }}>
        {errorMessage}
      </p>
      <br />
      <MapDiv
        css={{
          width: '500px',
          height: '400px',
        }}
      >
        <NaverMap
          ref={setMapRef}
          defaultCenter={
            new navermaps.LatLng(
              latitude || 37.3595704,
              longitude || 127.105399,
            )
          }
          defaultZoom={15}
        >
          {markerPosition && (
            <Marker
              position={
                new navermaps.LatLng(markerPosition._lat, markerPosition._lng)
              }
            />
          )}
        </NaverMap>
      </MapDiv>
    </>
  );
}

export default forwardRef(SearchMap);
