import dayjs from 'dayjs';
import { useCallback, useEffect } from 'react';
import { END_TIME_VALUE } from './constatns';
import { DateItemType } from 'types';
import { checkIsInvalidDate, createTimeArgs } from './utils';

interface Props {
  start: Date | null;
  end: Date | null;
  startTime: string;
  endTime: string;
  checkTodayTimes?: boolean;
  time?: boolean;
  handleStart: (date: Date) => void;
  handleEnd: (date: Date) => void;
  validate: (s: Date, e: Date) => boolean;
  setStartTime: (t: string) => void;
  setEndTime: (t: string) => void;
}

function useDateHandler({
  start,
  end,
  startTime,
  endTime,
  checkTodayTimes,
  time,
  handleStart,
  handleEnd,
  validate,
  setStartTime,
  setEndTime,
}: Props) {
  /**
   * DatePicker 시작일 변경 핸들러
   */
  const handleChangeStart = useCallback(
    (s: Date | null) => {
      if (!s) return;
      const startDate = new Date(s);

      if (time) {
        startDate.setHours(...createTimeArgs(Number(startTime)));
      }

      const h = new Date().getHours() + 1;

      if (checkTodayTimes && checkIsToday(start) && Number(startTime) < h) {
        startDate.setHours(h);
        setStartTime(`${h}`);
      }

      if (end) {
        const endDate = new Date(end);
        endDate.setHours(Number(endTime));
        if (!validate(startDate, endDate)) return;
      }

      handleStart(startDate);
    },
    [end, time, checkTodayTimes],
  );

  /**
   * DatePicker 종료일 변경 핸들러
   */
  const handleChangeEnd = useCallback(
    (e: Date | null) => {
      if (!e) return;
      const endDate = new Date(e);

      if (time) {
        endDate.setHours(...createTimeArgs(Number(endTime)));
      }

      const h = new Date().getHours() + 1;

      if (checkTodayTimes && checkIsToday(e) && Number(endTime) < h) {
        endDate.setHours(h);
        setEndTime(`${h}`);
      }

      if (start) {
        const startDate = new Date(start);
        startDate.setHours(Number(startTime));

        if (!validate(startDate, endDate)) return;
      }

      handleEnd(endDate);
    },
    [start, time, checkTodayTimes],
  );

  /**
   * 시작 시,분 옵션 변경 핸들러
   */
  useEffect(() => {
    if (!start) return;
    const d = new Date(start);
    d.setHours(Number(startTime || '0'));
    handleStart(d);
  }, [startTime]);

  /**
   * 종료 시,분 옵션 변경 핸들러
   */
  useEffect(() => {
    if (!end) return;
    const d = new Date(end);
    if (endTime === END_TIME_VALUE) {
      d.setHours(23, 59, 59, 999);
    } else {
      d.setHours(Number(endTime) || 0, 0, 0, 0);
    }

    handleEnd(d);
  }, [endTime]);

  const handleClickRangeButton = (item: DateItemType) => {
    const end =
      item.getEnd?.() ?? new Date(new Date().setHours(23, 59, 59, 999));
    const start = item.getStart(end);

    if (!(checkIsInvalidDate(end) || checkIsInvalidDate(start))) {
      if (time) {
        setStartTime(String(start.getHours()));
        setEndTime(END_TIME_VALUE);
      }
    }

    handleStart(start);
    handleEnd(end);
  };

  return { handleChangeStart, handleChangeEnd, handleClickRangeButton };
}

const checkIsToday = (time: Date | null) => {
  if (!time) return false;
  const now = new Date();

  return dayjs(now).startOf('day').isSame(dayjs(time).startOf('day'));
};

export default useDateHandler;
