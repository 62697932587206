import ic625 from 'static/event/625.svg';
import icBriquette from 'static/event/briquette.svg';
import icChildren from 'static/event/children.svg';
import icDisability from 'static/event/disability.svg';
import icDog from 'static/event/dog1.svg';
import icFlogging from 'static/event/flogging.svg';
import icFood from 'static/event/food.svg';

export const THUMBNAIL_DATA = [
  ic625,
  icBriquette,
  icChildren,
  icDisability,
  icDog,
  icFlogging,
  icFood,
];
