import React from 'react';

import { UiComponent, CouponComponent } from 'components';

function List() {
  return (
    <>
      <UiComponent.PageTitle>쿠폰목록</UiComponent.PageTitle>
      <CouponComponent.Filter />
      <CouponComponent.List />
    </>
  );
}

export default List;
