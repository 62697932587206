import dayjs from 'dayjs';
import { IUserListItem } from 'types';

export const data: IUserListItem[] = [
  {
    id: 1,
    ID: 'jisu1***',
    name: '최*수',
    email: 'jisu1***@gmail.com',
    signUpDate: dayjs().format('YYYY-MM-DD HH:mm'),
    status: 'normal',
  },
];
