import React, { Fragment } from 'react';

import { FormComponent, ModalComponent, UiComponent } from 'components';
import { BsPlusLg } from 'react-icons/bs';
import { IQuestion, QuestionType } from 'types/forms';
import Question from './Question';
import { inputBase, modalWrap } from './style';

import useForms from './useForms';
import useLoadForms from './useLoadForms';

interface Props {
  title?: string;
  questions?: IQuestion[];
  type: QuestionType;
  onSave: (title: string, questions: IQuestion[]) => void;
}

function Forms(props: Props) {
  const {
    refs,
    title,
    setTitle,
    questions,
    handleAddQuestion,
    handleSave,
    handleRemoveQuestion,
    load,
  } = useForms(props);

  const { data, isOpenModal, handleClose, handleOpen, handleSelect } =
    useLoadForms({
      load,
      type: props.type,
    });

  return (
    <div
      css={{
        marginTop: '24px',
        width: '800px',
        margin: '24px 0',
        position: 'relative',
      }}
    >
      <FormComponent.Form>
        <UiComponent.Box>
          <input
            type="text"
            css={[inputBase, { height: '48px', fontSize: '24px' }]}
            placeholder="제목을 입력해주세요"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </UiComponent.Box>
        {questions.map((item) => (
          <Fragment key={item.id}>
            <Question
              ref={refs.current.get(item.id.toString())}
              question={item}
              handleRemoveQuestion={handleRemoveQuestion}
            />
          </Fragment>
        ))}
      </FormComponent.Form>
      <UiComponent.Button
        variant="contained"
        color="primary"
        css={{ position: 'absolute', bottom: '4px', right: '-36px' }}
        onClick={handleAddQuestion}
      >
        <BsPlusLg size={12} />
      </UiComponent.Button>
      <div
        css={{
          position: 'absolute',
          top: '4px',
          right: '-256px',
          display: 'flex',
          gap: '0 4px',
        }}
      >
        <UiComponent.Button
          variant="contained"
          color="primary"
          css={{
            height: '36px',
            width: '120px',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
          onClick={handleSave}
        >
          저장하기
        </UiComponent.Button>
        <UiComponent.Button
          variant="border"
          color="primary"
          css={{
            height: '36px',
            width: '120px',
            fontSize: '16px',
            fontWeight: 'bold',
          }}
          onClick={handleOpen}
        >
          불러오기
        </UiComponent.Button>
      </div>
      {isOpenModal && (
        <ModalComponent.Portal>
          <ModalComponent.Background>
            <ModalComponent.Modal
              title=""
              handleClose={handleClose}
              footer={<></>}
            >
              <ul css={modalWrap}>
                {data.map((d, i) => (
                  <li key={i}>
                    <p>{d.title}</p>
                    <UiComponent.Button
                      color="primary"
                      variant="border"
                      onClick={() => handleSelect(d)}
                    >
                      선택
                    </UiComponent.Button>
                  </li>
                ))}
              </ul>
            </ModalComponent.Modal>
          </ModalComponent.Background>
        </ModalComponent.Portal>
      )}
    </div>
  );
}

export default Forms;
