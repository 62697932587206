import { useState } from 'react';

function usePageOption() {
  const [pageOption, setPageOption] = useState({ page: 1, size: 50 });

  return {
    pageOption,
    totalPage: 10,
    handlePage: setPageOption,
  };
}

export default usePageOption;
