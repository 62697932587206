import React, {
  MutableRefObject,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { ReactDatePickerProps } from 'react-datepicker';

import DatePicker from '../DatePicker';
import Select from '../Select';
import useDateRangePicker from './useDateRangePicker';

import { wrap, btns, btn } from './style';

import { checkSameDate, checkToday } from './utils';
import { DateItemType, IDateRangePickerImperative } from 'types';
import useWholeDateRange from './useWholeDateRange';
import useDateHandler from './useDateHandler';

interface Props
  extends Pick<ReactDatePickerProps, 'showMonthYearPicker' | 'dateFormat'> {
  start: Date | null;
  end: Date | null;
  handleStart: (date: Date) => void;
  handleEnd: (date: Date) => void;
  itemLength?: number;
  time?: boolean;
  min?: Date;
  max?: Date;
  startPlaceholderText?: string;
  endPlaceholderText?: string;
  onlyHour?: boolean;
  checkTodayTimes?: boolean;
  dateRef?: MutableRefObject<IDateRangePickerImperative | undefined>;
  readOnly?: boolean;
  showAllItems?: boolean;
  isWholeRangeAvailable?: boolean;
}

function DateRangePicker({
  start,
  end,
  handleStart,
  handleEnd,
  time = false,
  itemLength = 6,
  min,
  max,
  startPlaceholderText,
  endPlaceholderText,
  onlyHour,
  checkTodayTimes,
  dateRef,
  readOnly,
  showAllItems = false,
  isWholeRangeAvailable,
  ...datePickerProps
}: Props) {
  const {
    items,
    handleReset,
    selectedItem,
    setSelectedItem,
    startTime,
    setStartTime,
    endTime,
    setEndTime,
    validate,
    checkItemSelected,
    startTimeItems,
    endTimeItems,
    setDefaultStartTimeItems,
    setDefaultEndTimeItems,
  } = useDateRangePicker({
    itemLength,
    dateFormat: 'yyyy.MM.dd',
    time,
    start,
    end,
    onlyHour,
    checkTodayTimes,
  });

  const { handleChangeEnd, handleChangeStart, handleClickRangeButton } =
    useDateHandler({
      start,
      end,
      startTime,
      endTime,
      checkTodayTimes,
      time,
      handleStart,
      handleEnd,
      validate,
      setStartTime,
      setEndTime,
    });

  useEffect(() => {
    if (!start || !end) return;
    if (start.getTime() > end.getTime()) return;

    if (!checkToday(end)) {
      setSelectedItem(undefined);
      return;
    }

    const a = items.find((d) => {
      const e = d.getStart(end);
      return checkSameDate(start, e);
    });

    setSelectedItem(a);
  }, [start, end]);

  const { startShouldBeDisabled, endShouldBeDisabled } = useWholeDateRange({
    start,
    end,
    items,
    handleStart,
    handleEnd,
    selectedItem,
    setSelectedItem,
    isWholeRangeAvailable,
  });

  useImperativeHandle(dateRef, () => ({
    setTime: (s, e, defaultStartTimeItems, defaultEndTimeItems) => {
      defaultStartTimeItems && setDefaultStartTimeItems(defaultStartTimeItems);
      defaultEndTimeItems && setDefaultEndTimeItems(defaultEndTimeItems);
      setStartTime(String(s));
      setEndTime(String(e));
    },
    getTime: () => {
      return { start: Number(startTime), end: Number(endTime) };
    },
  }));

  const dateRangeItems: (DateItemType & { disabled?: boolean })[] =
    useMemo(() => {
      if (!showAllItems) {
        return items;
      }

      return items.map((o) =>
        o.label === '전체' && !isWholeRangeAvailable
          ? { ...o, disabled: true }
          : o,
      );
    }, [showAllItems, items, isWholeRangeAvailable]);

  return (
    <div css={wrap(time)} onReset={handleReset} onResetCapture={handleReset}>
      {dateRangeItems.length > 0 && (
        <div css={btns}>
          {dateRangeItems.map((item, i) => (
            <button
              key={i}
              type="button"
              css={btn(checkItemSelected(item))}
              onClick={() => handleClickRangeButton(item)}
              disabled={item.disabled}
            >
              {item.label}
            </button>
          ))}
        </div>
      )}
      <div>
        <DatePicker
          date={startShouldBeDisabled ? null : start}
          handleChange={handleChangeStart}
          minDate={min}
          placeholderText={startPlaceholderText || ''}
          readOnly={readOnly}
          disabled={startShouldBeDisabled}
          {...datePickerProps}
        />
        {time && (
          <Select
            options={startTimeItems}
            value={startTime}
            handleChange={setStartTime}
            css={{ width: '90px', height: 34, margin: '0 4px' }}
            readonly={readOnly}
          />
        )}
        <p>~</p>
        <DatePicker
          date={endShouldBeDisabled ? null : end}
          handleChange={handleChangeEnd}
          minDate={start ? start : min}
          maxDate={max}
          placeholderText={endPlaceholderText || ''}
          readOnly={readOnly}
          disabled={endShouldBeDisabled}
          {...datePickerProps}
        />
        {time && (
          <Select
            options={endTimeItems}
            value={endTime}
            handleChange={setEndTime}
            css={{ width: '90px', height: 34, margin: '0 4px' }}
            readonly={readOnly}
          />
        )}
      </div>
    </div>
  );
}

export type { IDateRangePickerImperative };
export default DateRangePicker;
