import React from 'react';
import { FormComponent } from 'components';
import { wrap, inpuyStyle } from './style';
import useLogin from './useLogin';

function Login() {
  const { handleSubmit, handleChange, idRef, pwRef, user } = useLogin();

  return (
    <div css={wrap}>
      <h1>연봉인상 어드민 로그인</h1>
      <div>
        <form onSubmit={handleSubmit}>
          <FormComponent.Input
            ref={idRef}
            css={inpuyStyle}
            autoComplete="off"
            type="text"
            placeholder="아이디를 입력해주세요"
            name="username"
            value={user.username}
            onChange={handleChange}
            maxLength={16}
          />
          <FormComponent.Input
            ref={pwRef}
            css={{
              ...inpuyStyle,
              WebkitTextSecurity: 'disc',
              textSecurity: 'disc',
            }}
            autoComplete="off"
            type="text"
            placeholder="비밀번호를 입력해주세요"
            name="password"
            value={user.password}
            onChange={handleChange}
            maxLength={30}
          />
          <button type="submit">로그인</button>
        </form>
      </div>
    </div>
  );
}

export default Login;
