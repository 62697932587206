import React, { ReactNode } from 'react';
import { IRenderCol, ITableProps } from 'types';
import * as TableStyle from './style/table';

const emptyRow = (colSpan: number, empty?: ReactNode) => {
  return (
    <tr>
      <td
        colSpan={colSpan}
        css={{ fontSize: '14px !important', color: '#B2B2B4 !important' }}
      >
        {empty ?? '조회하신 정보가 없습니다'}
      </td>
    </tr>
  );
};

function Table<T, R>({
  data,
  cols,
  renderer,
  sticky,
  checkSelected,
  onClickRow,
  empty,
  interSectionNode,
}: ITableProps<T, R>) {
  return (
    <table css={TableStyle.wrap}>
      <thead
        css={{ background: '#fafafa', ...(sticky && TableStyle.stickyHead) }}
      >
        <tr>
          {cols.map((c, i) => {
            if (typeof c === 'string') {
              return <td key={i}>{c}</td>;
            }
            return <td key={i}>{c.render()}</td>;
          })}
        </tr>
      </thead>
      <tbody>
        {data.length === 0 && emptyRow(cols.length, empty)}

        {data.map((value, i) => (
          <tr
            key={value.rowKey ?? i}
            onClick={() => onClickRow?.(value)}
            css={[
              onClickRow && TableStyle.hover,
              checkSelected?.(value) && TableStyle.selected,
            ]}
          >
            {cols.map((c, j) => (
              <React.Fragment key={j}>
                <td>
                  {typeof c === 'string' && renderer[c as unknown as keyof R]
                    ? (
                        renderer[
                          c as unknown as keyof R
                        ] as unknown as React.FC<T>
                      )({
                        ...value,
                        idx: j,
                        row: i,
                      })
                    : (
                        renderer[(c as IRenderCol).key as keyof R] &&
                        (renderer[
                          (c as IRenderCol).key as keyof R
                        ] as unknown as React.FC<T>)
                      )({ ...value, ...{ idx: j }, row: i })}
                </td>
              </React.Fragment>
            ))}
          </tr>
        ))}
        {interSectionNode}
      </tbody>
    </table>
  );
}

export default Table;
