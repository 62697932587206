import React from 'react';
import { wrap, buttonArea, content } from './style';

interface Props {
  onConfirm: () => void;
  onClose: () => void;
  yesTitle?: string;
  noTitle?: string;
}

function Confirm({
  children,
  yesTitle,
  noTitle,
  onConfirm,
  onClose,
}: React.PropsWithChildren<Props>) {
  return (
    <div css={wrap}>
      <div css={content}>{children}</div>
      <hr />
      <div css={buttonArea}>
        <button type="button" onClick={onConfirm}>
          {yesTitle ? yesTitle : '확인'}
        </button>
        <button type="button" onClick={onClose}>
          {noTitle ? noTitle : '취소'}
        </button>
      </div>
    </div>
  );
}

export default Confirm;
