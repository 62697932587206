import { PartnerComponent } from 'components';
import React, { useEffect, useState } from 'react';
import { IPartner } from 'types';
import * as API from 'api/partner';

function Partner() {
  const [data, setData] = useState<IPartner[]>([]);

  const fetch = () => {
    API.getAll().then((res) => {
      setData([...res.data]);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <PartnerComponent.Filter refetch={fetch} />
      <PartnerComponent.List data={data} refetch={fetch} />
    </>
  );
}

export default Partner;
