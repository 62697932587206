import React from 'react';

import { UiComponent, UserComponent } from 'components';

function List() {
  return (
    <>
      <UiComponent.PageTitle>회원목록</UiComponent.PageTitle>
      <UserComponent.Filter />
      <UserComponent.List />
    </>
  );
}

export default List;
