import React, { useEffect } from 'react';
import { UiComponent } from 'components';
import * as API from 'api/feedback';
import { IFeedback, IQuestion } from 'types';
import { useNavigate, useParams } from 'react-router-dom';
import { EVENT_MENU_URL } from 'data/menu';

function Form() {
  const { id } = useParams();
  const [isLoaded, setIsLoaded] = React.useState(false);
  const [feedback, setFeedback] = React.useState<IFeedback | null>(null);
  const navigate = useNavigate();

  const onCreate = (title: string, questions: IQuestion[]) => {
    API.post({ title, questions }).then(() =>
      navigate(`${EVENT_MENU_URL['피드백 템플릿']}`),
    );
  };

  const onUpdate = (title: string, questions: IQuestion[]) => {
    if (!feedback) return;

    API.put({ ...feedback, title, questions }).then(() =>
      navigate(`${EVENT_MENU_URL['피드백 템플릿']}`),
    );
  };

  const onSave = (title: string, questions: IQuestion[]) => {
    if (id === undefined) onCreate(title, questions);
    else onUpdate(title, questions);
  };

  useEffect(() => {
    if (!id) {
      setIsLoaded(true);
      return;
    }

    API.get(Number(id))
      .then((res) => {
        if (res.data) setFeedback(res.data);
      })
      .finally(() => setIsLoaded(true));
  }, []);

  if (!isLoaded) return null;

  return (
    <div>
      <UiComponent.Forms
        title={feedback?.title}
        questions={feedback?.questions}
        type="feedback"
        onSave={onSave}
      />
    </div>
  );
}

export default Form;
