import { css, keyframes } from '@emotion/react';

const rotate = keyframes`
  from { 
    transform: rotate(0); 
  }
  to { 
    transform: rotate(360deg); 
  }
`;

const wrap = css`
  width: 50px;
  height: 50px;
  border: 3px solid rgba(168, 100, 216, 0.3);
  border-radius: 50%;
  border-top-color: #5f0080;
  animation: ${rotate} 0.8s linear infinite;
`;

export { wrap };
