import ic1 from 'static/partner/1.svg';
import ic2 from 'static/partner/2.svg';
import ic3 from 'static/partner/3.svg';
import ic4 from 'static/partner/4.svg';
import ic5 from 'static/partner/5.svg';
import ic6 from 'static/partner/6.svg';
import ic7 from 'static/partner/7.svg';
import ic8 from 'static/partner/8.svg';
import ic9 from 'static/partner/9.svg';
import ic10 from 'static/partner/10.svg';
import ic11 from 'static/partner/11.svg';
import ic12 from 'static/partner/12.svg';
import ic13 from 'static/partner/13.svg';
import ic14 from 'static/partner/14.svg';
import ic15 from 'static/partner/15.svg';
import ic16 from 'static/partner/16.svg';
import ic17 from 'static/partner/17.svg';
import ic18 from 'static/partner/18.svg';
import ic19 from 'static/partner/19.svg';
import { IPartner } from 'types';

export const MOCK_DATA: IPartner[] = [
  {
    id: 1,
    thumbnail: ic3,
    name: '다날',
    description: '다날',
  },
  {
    id: 2,
    thumbnail: ic1,
    name: '사랑의연탄',
    description: '사랑의연탄',
  },
  {
    id: 3,
    thumbnail: ic2,
    name: '빠니보틀',
    description: '빠니보틀',
  },
  {
    id: 4,
    thumbnail: ic4,
    name: '차호랑',
    description: '차호랑',
  },
  {
    id: 5,
    thumbnail: ic5,
    name: 'FCMM',
    description: 'FCMM',
  },
  {
    id: 6,
    thumbnail: ic6,
    name: 'Your Side',
    description: 'Your Side',
  },
  {
    id: 7,
    thumbnail: ic7,
    name: 'YG Plus',
    description: 'YG Plus',
  },
  {
    id: 8,
    thumbnail: ic8,
    name: 'CJ 제일제당',
    description: 'CJ 제일제당',
  },
  {
    id: 9,
    thumbnail: ic9,
    name: '서울지방보훈청',
    description: '서울지방보훈청',
  },
  {
    id: 10,
    thumbnail: ic10,
    name: '한강사업본부',
    description: '한강사업본부',
  },
  {
    id: 11,
    thumbnail: ic11,
    name: '반보종합사회복지관',
    description: '반보종합사회복지관',
  },
  {
    id: 12,
    thumbnail: ic12,
    name: 'V세상',
    description: 'V세상',
  },
  {
    id: 13,
    thumbnail: ic13,
    name: '고아권익연대',
    description: '고아권익연대',
  },
  {
    id: 14,
    thumbnail: ic14,
    name: '은평노인종합복지관',
    description: '은평노인종합복지관',
  },
  {
    id: 15,
    thumbnail: ic15,
    name: '건우나눔자리',
    description: '건우나눔자리',
  },
  {
    id: 16,
    thumbnail: ic16,
    name: '시도',
    description: '시도',
  },
  {
    id: 17,
    thumbnail: ic17,
    name: 'Family Town',
    description: 'Family Town',
  },
  {
    id: 18,
    thumbnail: ic18,
    name: '싸움의고수',
    description: '싸움의고수',
  },
  {
    id: 19,
    thumbnail: ic19,
    name: '미니쉬',
    description: '미니쉬',
  },
];
