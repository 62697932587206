import { CSSObject } from '@emotion/react';
import { DirectionType, ItemAlignType, ItemVariantType } from './types';

const list = ({
  direction,
  gap,
}: {
  direction: DirectionType;
  gap: number;
}): CSSObject => ({
  display: 'flex',
  flexDirection: direction,
  gap: gap,
});

const item = ({
  variant,
  align,
}: {
  variant: ItemVariantType;
  align: ItemAlignType;
}): CSSObject => {
  if (variant === 'labeled') {
    return {
      display: 'flex',
      alignItems: align,
      gap: 48,

      '& > *': {
        flex: 4,
      },

      '& > :first-of-type:not(:last-child)': {
        ...(align === 'start' && { marginTop: 8 }),
        flex: '0 0 auto',
      },
    };
  }

  return {
    display: 'block',
  };
};

export { list, item };
