import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { UiComponent } from 'components';
import { usePageOption } from 'hooks';
import { tableWrap, tableTop } from 'styles/table';
import { IFeedback } from 'types';
import * as API from 'api/feedback';
import { BsPlusLg } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { EVENT_MENU_URL } from 'data/menu';

const COLS: string[] = ['ID', '타이틀', '생성자', '생성일'];

function List() {
  const navigate = useNavigate();
  const [data, setData] = useState<IFeedback[]>([]);

  const handleView = (id: number) => {
    navigate(`${EVENT_MENU_URL['피드백 템플릿']}/${id}`);
  };

  const pageState = usePageOption();
  const renderer: Record<
    (typeof COLS)[number],
    (data: IFeedback & { row: number }) => ReactNode
  > = useMemo(
    () => ({
      ID: ({ id }: IFeedback) => (
        <button type="button" onClick={() => handleView(id)}>
          {id}
        </button>
      ),
      타이틀: ({ title }: IFeedback) => <>{title}</>,
      생성자: ({ creator }: IFeedback) => <>{creator}</>,
      생성일: ({ createdAt }: IFeedback) => <>{createdAt}</>,
    }),
    [],
  );

  const fetch = () => {
    API.getAll().then((res) => {
      setData(res.data as unknown as IFeedback[]);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <>
      <UiComponent.Box>
        <div css={tableTop}>
          <p>{`총 ${data.length} 건`}</p>
          <div>
            <UiComponent.Button
              variant="contained"
              color="primary"
              onClick={() =>
                navigate(`${EVENT_MENU_URL['피드백 템플릿']}/register`)
              }
            >
              <BsPlusLg size={18} />
            </UiComponent.Button>
          </div>
        </div>
        <div css={tableWrap}>
          <UiComponent.Table
            cols={COLS}
            renderer={renderer}
            data={data}
            sticky
          />
        </div>
        <UiComponent.Pagination {...pageState} />
      </UiComponent.Box>
    </>
  );
}

export default List;
