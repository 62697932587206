import React, { useCallback, useEffect, useState } from 'react';
import { MdOutlineMoreVert } from 'react-icons/md';
import { usePopper } from 'react-popper';
import { IUser } from 'types';
import * as API from 'api/user';
import useEdit from './useEdit';
import Edit from './Edit';
import Password from './Password';
import useUserMemo from './useUserMemo';
import UserMemo from './UserMemo';

interface Props {
  user: IUser;
  refetch: () => void;
}

function Action({ user, refetch }: Props) {
  const { isOpenEdit, setIsOpenEdit, handleSave } = useEdit({ user, refetch });
  const {
    isOpenUserMemo,
    setIsOpenUserMemo,
    handleSave: handleSaveUserMemo,
  } = useUserMemo({ user, refetch });
  const [isOpenPassword, setIsOpenPassword] = useState(false);

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleOff = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    window.document.addEventListener('click', handleOff);

    () => window.document.removeEventListener('click', handleOff);
  }, []);

  const handleStatus = (status: IUser['status']) => {
    API.putStatus({ id: user.id, status }).then(() => {
      handleOff();
      refetch();
    });
  };

  return (
    <>
      <div
        css={{
          position: 'absolute',
          right: '12px',
          top: '12px',
        }}
        ref={setReferenceElement}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <MdOutlineMoreVert size={32} />
      </div>
      {isOpen && (
        <div
          onClick={(e) => e.stopPropagation()}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          css={{
            backgroundColor: 'white',
            padding: '12px 8px',
            borderRadius: '4px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <ul
            css={{
              '> li': {
                padding: '8px 40px 8px 8px',
                cursor: 'pointer',
                fontSize: '12px',
                '&:hover': {
                  backgroundColor: '#f7f7f7',
                },
              },
            }}
          >
            <li onClick={() => setIsOpenEdit(true)}>회원정보 수정</li>
            <li
              onClick={() =>
                handleStatus(user.status === 'normal' ? 'block' : 'normal')
              }
            >
              {user.status === 'block' ? '이용정지 해제' : '이용정지'}
            </li>
            <li onClick={() => setIsOpenPassword(true)}>비빌번호 초기화</li>
            <li onClick={() => setIsOpenUserMemo(true)}>메모</li>
          </ul>
        </div>
      )}
      {isOpenEdit && (
        <Edit
          user={user}
          onClose={() => setIsOpenEdit(false)}
          onSave={handleSave}
        />
      )}
      {isOpenPassword && <Password onClose={() => setIsOpenPassword(false)} />}
      {isOpenUserMemo && (
        <UserMemo
          user={user}
          onClose={() => setIsOpenUserMemo(false)}
          onSave={handleSaveUserMemo}
        />
      )}
    </>
  );
}

export default Action;
