import { CSSObject } from '@emotion/react';
import React from 'react';
import { wrap } from '../Radio/style';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  handleCheck?: (checked: boolean) => void;
  css?: CSSObject;
  readOnly?: boolean;
}

function CheckRadio({
  handleCheck,
  children,
  checked,
  css,
  readOnly,
  ...props
}: React.PropsWithChildren<Props>) {
  return (
    <label css={wrap}>
      <input
        {...props}
        css={css}
        type="checkbox"
        checked={checked}
        onClick={() => {
          if (readOnly) return;
          handleCheck?.(!checked);
        }}
        readOnly
      />
      {children}
    </label>
  );
}

export default CheckRadio;
