import React from 'react';
import { detailWrap } from 'styles/table';

function Dashboard() {
  return (
    <div css={[detailWrap, { marginTop: '24px' }]}>
      <h3>활동정보</h3>
      <ul
        css={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '24px',
          '> li': {
            width: '20%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            'p:nth-of-type(1)': {
              fontWeight: 'bold',
              marginBottom: '8px',
              color: '#8d8d8d',
            },
            'p:nth-of-type(2)': {
              '> span': {
                fontSize: '32px',
                fontWeight: 'bold',
              },
            },
          },
          'li + li': {
            borderLeft: '1px solid grey',
          },
        }}
      >
        <li>
          <p>봉사 참여 횟수</p>
          <p>
            <span>2</span>회
          </p>
        </li>
        <li>
          <p>총 봉사 시간</p>
          <p>
            <span>8</span>시간
          </p>
        </li>
        <li>
          <p>지각 횟수</p>
          <p>
            <span>2</span>회
          </p>
        </li>
        <li>
          <p>결석 횟수</p>
          <p>
            <span>0</span>회
          </p>
        </li>
        <li>
          <p>노쇼 횟수</p>
          <p>
            <span>0</span>회
          </p>
        </li>
      </ul>
    </div>
  );
}

export default Dashboard;
