import { CSSObject } from '@emotion/react';
import icOn from '../static/check_on.svg';
import icOff from '../static/check_off.svg';

const checkbox: CSSObject = {
  display: 'flex',
  alignItems: 'center',
  lineHeight: '19px',
  fontSize: '18px',
  '> input': {
    appearance: 'none',
    width: '24px',
    height: '24px',
    margin: '0 4px 0 0',
    background: `url(${icOff}) no-repeat`,
    outline: 'none',

    ':not([disabled])': {
      cursor: 'pointer',
    },

    ':checked': {
      background: `url(${icOn}) no-repeat`,
    },
  },
};

export { checkbox };
