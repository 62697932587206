import { CSSObject, Theme } from '@emotion/react';

const wrap =
  (size: 'small' | 'medium') =>
  (theme: Theme): CSSObject => ({
    maxWidth: '80vw',
    minWidth: 320,
    maxHeight: '80vh',
    background: theme.color.white,
    padding: size === 'medium' ? '30px' : 20,
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
  });

const head = (size: 'small' | 'medium') => (): CSSObject => {
  return {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
    '> p': {
      color: '#495057',
      fontSize: '24px',
      fontWeight: 500,
    },

    ...(size === 'small' && {
      '> button': {
        position: 'absolute',
        width: 24,
        height: 24,
        top: 0,
        right: 0,

        img: {
          width: '100%',
          height: '100%',
          objectFit: 'contain',
        },
      },
    }),
  };
};

const contents = (size: 'small' | 'medium') => (): CSSObject => {
  return {
    flex: 1,
    overflowX: 'auto',
    overflowY: 'auto',
    marginBottom: size === 'medium' ? '30px' : '16px',
    ...(size === 'small' && { marginTop: 8 }),
  };
};

const foot = (theme: Theme): CSSObject => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  '> button': {
    background: theme.color.primary,
    color: theme.color.white,
    height: '44px',
    width: '150px',
    borderRadius: '3px',
  },
});

export { wrap, head, contents, foot };
