import dayjs from 'dayjs';
// import { base } from './';
import { IFeedback, IQuestion } from 'types';

// const PATH = '/feedback';
const MOCK_DATA: IFeedback[] = [
  {
    id: 1,
    title: '연봉인상 피드백템플릿1',
    questions: [
      {
        id: 1,
        type: '1',
        title: '올해(2024년 기준) 연봉인상 봉사 몇 번 참여하셨나요?',
        description: '',
        options: ['1회', '2회', '3회', '4회', '5회 이상'],
      },
      {
        id: 2,
        type: '3',
        title:
          '호국보훈 봉사 진행, 운영 등 전반적인 활동 과정은 만족스러우셨나요?',
        description: '',
        options: ['매우 만족스럽지 않다', '매우 만족스럽다'],
      },
      {
        id: 3,
        type: '3',
        title: '호국보훈 봉사 강도는 어땠나요?',
        description: '',
        options: ['매우 힘들었다', '매우 쉬웠다'],
      },
      {
        id: 4,
        type: '3',
        title: '전체 진행 시간은 어떠셨나요?',
        description: '',
        options: ['매우 길었다', '매우 짧았다'],
      },
      {
        id: 5,
        type: '3',
        title: '연봉인상 호국보훈 봉사를 지인에게 추천해주고 싶으신가요?',
        description: '',
        options: ['매우 길었다', '매우 짧았다'],
      },
    ],
    creator: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
];
const find = (id: number) => MOCK_DATA.find((item) => item.id === id);

// const get = (id: number) => base.get(`${PATH}/${id}`);
const get = (id: number) =>
  new Promise<{ data?: IFeedback }>((resolve) => resolve({ data: find(id) }));

// const getAll = () => base.get(PATH);
const getAll = () =>
  new Promise<{ data: IFeedback[] }>((resolve) => resolve({ data: MOCK_DATA }));

// const post = (data: IFeedback) => base.post(PATH, data);
const post = ({
  title,
  questions,
}: {
  title: string;
  questions: IQuestion[];
}) => {
  const feedback: IFeedback = {
    id: MOCK_DATA.length + 1,
    title,
    questions,
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    creator: '최*수',
  };

  MOCK_DATA.push(feedback);

  return new Promise((resolve) => resolve(true));
};
const put = (data: IFeedback) => {
  const idx = MOCK_DATA.findIndex((item) => item.id === data.id);
  MOCK_DATA[idx] = { ...data };
  return new Promise((resolve) => resolve(true));
};

export { get, getAll, post, put };
