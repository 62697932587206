import { useState, useTransition, useDeferredValue, useMemo } from 'react';
import { CheckItemType, CheckItemKeyType, CheckItemDataType } from 'types';

interface Props<T> {
  uniqueKey: CheckItemKeyType;
  data: T[];
  isAllChecked?: boolean;
  defaultChecks?: (string | number | undefined)[];
}

function useCheck<T extends CheckItemType>({
  uniqueKey,
  data,
  isAllChecked,
  defaultChecks,
}: Props<T>) {
  const [, startTransition] = useTransition();
  const [idData, setIdData] = useState<CheckItemDataType<T>[]>(
    data.map((d, i) => ({
      ...d,
      checked:
        isAllChecked ||
        (defaultChecks
          ? defaultChecks.indexOf(data[i][uniqueKey]) !== -1
          : false),
    })),
  );
  const deferredIdData = useDeferredValue(idData);

  const handleCheck = (key: number | string, checked: boolean) => {
    startTransition(() => {
      const data = idData.find((v) => v[uniqueKey] === key);
      if (!data) return;
      data.checked = checked;
      setIdData([...idData]);
    });
  };

  const isAll = useMemo(
    () =>
      Object.values(deferredIdData)
        .map((v) => v.checked)
        .every((d) => d),
    [deferredIdData],
  );

  const handleAll = (checked: boolean) => {
    setIdData(data.map((d) => ({ ...d, checked, disabled: false })));
  };

  const resetData = (newData: T[], checkedIdx?: number) => {
    setIdData(
      newData.map((d, i) => ({
        ...d,
        checked: isAllChecked || (i === checkedIdx ? true : false),
      })),
    );
  };

  return {
    isAll,
    handleAll,
    idData,
    resetData,
    handleCheck,
  };
}

export default useCheck;
