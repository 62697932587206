import React from 'react';
import { detailWrap } from 'styles/table';

function Statistics() {
  return (
    <div css={[detailWrap, { marginTop: '24px' }]}>
      <h3>행사참여정보</h3>
      <ul
        css={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '24px',
          '> li': {
            width: '25%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            'p:nth-of-type(1)': {
              fontWeight: 'bold',
              marginBottom: '8px',
              color: '#8d8d8d',
            },
            'p:nth-of-type(2)': {
              '> span': {
                fontSize: '32px',
                fontWeight: 'bold',
              },
            },
          },
          'li + li': {
            borderLeft: '1px solid grey',
          },
        }}
      >
        <li>
          <p>신청인원</p>
          <p>
            <span>70</span>명
          </p>
        </li>
        <li>
          <p>취소인원</p>
          <p>
            <span>0</span>명
          </p>
        </li>
        <li>
          <p>피드백 작성 인원</p>
          <p>
            <span>50</span>명
          </p>
        </li>
        <li>
          <p>지각/노쇼</p>
          <p>
            <span>5</span>명
          </p>
        </li>
      </ul>
    </div>
  );
}

export default Statistics;
