import { CSSObject } from '@emotion/react';

const wrap: CSSObject = {
  position: 'relative',
  minWidth: '100%',
  boxSizing: 'border-box',
  'th, td': {
    padding: '16px 10px',
    border: '1px solid #ddd',
    textAlign: 'center',
    verticalAlign: 'middle',
    fontSize: '14px',
    whiteSpace: 'nowrap',

    '&:has(input, select)  *': {
      margin: 0,
    },

    'input:not([type=checkbox]),select': {
      height: 36,
    },
  },
  th: {
    background: '#fafafa',
  },
};

const stickyHead: CSSObject = {
  position: 'sticky',
  top: 0,
  zIndex: 1,
  boxShadow: 'inset 0 1px 0 #ddd, inset 0 -1px 0 #ddd',
};

const stickyBottom: CSSObject = {
  position: 'sticky',
  bottom: 0,
  zIndex: 1,
  boxShadow: 'inset 0 1px 0 #ddd, inset 0 -1px 0 #ddd',
};

const hover: CSSObject = {
  '&:hover': {
    cursor: 'pointer',
    backgroundColor: '#fafafa',
  },
};

const selected: CSSObject = {
  backgroundColor: '#e3f2fd !important',
};

const ellipsisCell: CSSObject = {
  display: '-webkit-box',
  wordWrap: 'break-word',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineHeight: '1.31',
};

export { wrap, stickyHead, hover, selected, stickyBottom, ellipsisCell };
