import React from 'react';

import { FormComponent, ModalComponent, UiComponent } from 'components';
import { IUser } from 'types';

interface Props {
  user: IUser;
  onClose: () => void;
  onSave: (data: { memo: string }) => void;
}

function UserMemo({ user, onClose, onSave }: Props) {
  return (
    <ModalComponent.Portal>
      <ModalComponent.Background>
        <ModalComponent.Modal title="" handleClose={onClose} footer={<></>}>
          <FormComponent.Form
            css={{
              display: 'flex',
              flexDirection: 'column',
              width: '500px',
              gap: '12px 0',
            }}
            submit={onSave}
          >
            <p>메모</p>
            <FormComponent.Textarea
              name="memo"
              rows={10}
              defaultValue={user.memo}
            />

            <UiComponent.Button
              type="submit"
              color="primary"
              variant="contained"
              css={{ width: '120px', margin: '0 auto' }}
            >
              저장
            </UiComponent.Button>
          </FormComponent.Form>
        </ModalComponent.Modal>
      </ModalComponent.Background>
    </ModalComponent.Portal>
  );
}

export default UserMemo;
