import React from 'react';
import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { wrap } from './style';

interface Props extends Omit<ReactDatePickerProps, 'onChange'> {
  date: Date | null;
  handleChange: (date: Date | null) => void;
  minDate?: Date | null;
  maxDate?: Date | null;
}

function Component({
  date,
  handleChange,
  minDate,
  maxDate,
  dateFormat = 'yyyy.MM.dd',
  ...props
}: Props) {
  return (
    <div css={wrap}>
      <DatePicker
        selected={date}
        dateFormat={dateFormat}
        onChange={handleChange}
        minDate={minDate}
        maxDate={maxDate}
        {...props}
      />
    </div>
  );
}

export default Component;
