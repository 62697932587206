import React from 'react';

import { FormComponent, UiComponent } from 'components';
import * as BoxStyles from 'components/ui/Box/style/box';
import { useDate } from 'hooks';

function Filter() {
  const dateState = useDate({});

  return (
    <UiComponent.Box>
      <FormComponent.Form>
        <div css={BoxStyles.rows}>
          <UiComponent.FilterTitle title="검색어" />
          <FormComponent.Select options={[{ name: '타이틀', value: '1' }]} />
          <FormComponent.Input css={{ width: '500px' }} />
        </div>
        <div css={BoxStyles.rows}>
          <UiComponent.FilterTitle title="기간" />
          <FormComponent.Select options={[{ name: '등록일', value: '1' }]} />
          <FormComponent.DateRangePicker {...dateState} />
        </div>
      </FormComponent.Form>
    </UiComponent.Box>
  );
}

export default Filter;
