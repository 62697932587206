import React from 'react';

import { AdditionalComponent, UiComponent } from 'components';

function List() {
  return (
    <>
      <UiComponent.PageTitle>추가 정보 템플릿 목록</UiComponent.PageTitle>
      <AdditionalComponent.Filter />
      <AdditionalComponent.List />
    </>
  );
}

export default List;
