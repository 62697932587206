import { CSSObject } from '@emotion/react';
import * as zIndex from 'styles/zIndex';

const wrap: CSSObject = {
  minWidth: '1050px',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  zIndex: zIndex.HEADER,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0 28px',
  height: '68px',
  background: 'black',
  boxShadow:
    '0 2px 5px -1px rgba(51, 51, 51, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.1)',
};

const logoWrap: CSSObject = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '> p': {
    marginLeft: '20px',
    color: 'hsla(0,0%,100%,.8)',
    fontSize: '13px',
  },
};

const logos: CSSObject = {
  ...logoWrap,
  '> p': {
    marginLeft: '20px',
    color: 'white',
    fontWeight: 400,
    fontSize: '20px',
  },
};

const myInfoButton: CSSObject = {
  fontSize: '13px',
  marginLeft: '20px',
  padding: '12px 10px',
  color: 'white',
  textDecoration: 'none',
  backgroundColor: 'rgba(0, 0, 0, 0.2)',
  borderRadius: '14px',
};

const btns: CSSObject = {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  '> p': {
    fontSize: '16px',
    color: 'white',
    fontWeight: 'normal',
  },
  '> button': {
    fontSize: '13px',
    marginLeft: '20px',
    color: 'rgba(255, 255, 255, 0.8)',
  },
};

export { wrap, logoWrap, logos, btns, myInfoButton };
