const checkToday = (date: Date) => {
  const today = new Date();

  return (
    date.getDate() == today.getDate() &&
    date.getMonth() == today.getMonth() &&
    date.getFullYear() == today.getFullYear()
  );
};

const checkSameDate = (dateA: Date, dateB: Date) => {
  return (
    dateA.getFullYear() === dateB.getFullYear() &&
    dateA.getMonth() === dateB.getMonth() &&
    dateA.getDate() === dateB.getDate()
  );
};

const InitialDateRange = (diff: number, unit: 'day' | 'month' | 'year') => {
  if (diff < 0) {
    throw new Error("diff argument can't be negative value");
  }

  return {
    getStart() {
      const baseDate = new Date();
      baseDate.setHours(0, 0, 0, 0);
      baseDate.setDate(baseDate.getDate() + 1);

      // ex) 일주일: 2023-06-14 ~ 2023-06-20
      switch (unit) {
        case 'day':
          baseDate.setDate(baseDate.getDate() - diff);
          break;
        case 'month':
          baseDate.setMonth(baseDate.getMonth() - diff);
          break;
        case 'year':
          baseDate.setFullYear(baseDate.getFullYear() - diff);
      }

      return baseDate;
    },
    getEnd() {
      return new Date(new Date().setHours(23, 59, 59, 99));
    },
  };
};

const checkIsInvalidDate = (date: Date) => {
  return Number.isNaN(date.valueOf());
};

const createInvalidDate = () => {
  return new Date(NaN);
};

const createTimeArgs = (time: number): [number, number, number, number] => {
  if (time === 24) {
    return [23, 59, 59, 999];
  }

  return [time, 0, 0, 0];
};

export {
  checkToday,
  checkSameDate,
  InitialDateRange,
  checkIsInvalidDate,
  createInvalidDate,
  createTimeArgs,
};
