import React from 'react';
import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import router from 'router';
import { NavermapsProvider } from 'react-naver-maps';

function App() {
  return (
    <Suspense fallback={null}>
      <NavermapsProvider
        ncpClientId={process.env.REACT_APP_NAVER_MAP_PUBLIC_KEY || ''}
        submodules={['geocoder']}
      >
        <RouterProvider router={router} />
      </NavermapsProvider>
    </Suspense>
  );
}

export default App;
