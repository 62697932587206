import { SectionType } from 'types';
// import { base } from './';

// const PATH = '/home';

const MOCK_DATA: SectionType[] = [];

// const add = (data: SectionType) => base.post(PATH, data);
const add = (data: SectionType) => {
  MOCK_DATA.push(data);
  return new Promise((resolve) => resolve(true));
};

// const del = (id: number) => base.delete(`${PATH}/${id}`);
const del = (id: number) => {
  MOCK_DATA.splice(
    MOCK_DATA.findIndex((item) => item.id === id),
    1,
  );

  return new Promise((resolve) => resolve(true));
};

// const get = () => base.get(PATH);
const get = () =>
  new Promise<{ data: SectionType[] }>((resolve) =>
    resolve({ data: MOCK_DATA }),
  );

export { add, del, get };
