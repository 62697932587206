import { CSSObject } from '@emotion/react';
import { zIndex } from 'styles';
import * as InputStyle from '../Input/style/default';

const wrap: CSSObject = {
  display: 'flex',
  minWidth: '173px',
  position: 'relative',
  height: '34px',
  input: { ...InputStyle.wrap, height: '34px' },

  '.react-datepicker-popper': {
    zIndex: zIndex.DATEPICKER,
  },
};

export { wrap };
