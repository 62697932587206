import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import { IAuthStore } from 'types';

const initialState: IAuthStore = {
  login: false,
};

const {
  reducer,
  actions: { login, logout },
} = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {
      state.login = true;
      state.loginAt = dayjs(new Date()).format('YYYY-MM-DD HH:mm:ss');
    },
    logout: (state) => {
      state.login = false;
      state.loginAt = '';
    },
  },
});

export { initialState, login, logout };
export default reducer;
