import { CSSObject } from '@emotion/react';

const wrap: CSSObject = {
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  borderRadius: '6px',
  boxShadow: '2px 2px 4px 0 rgb(0 0 0 / 6%)',
  marginBottom: '12px',
};

const row: CSSObject = {
  display: 'flex',
  flexDirection: 'row',
  padding: '12px 0',
};

const rows: CSSObject = {
  ...row,
  alignItems: 'center',
  gap: '0 4px',
};

const startRows: CSSObject = {
  ...row,
  alignItems: 'flex-start',
};

const col: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  gap: '12px 0',
};

const btns = (): CSSObject => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '40px 0 30px 0',
  gap: '0 10px',
  '> button': {
    width: '150px',
    borderRadius: '3px',
    border: '1px solid',
    height: '44px',
  },
  'button:nth-of-type(1)': {
    color: '#333',
    borderColor: '#ddd',
    background: '#fff',
  },
  'button:nth-of-type(2)': {
    color: 'white',
    // borderColor: theme.color.primary,
    // background: theme.color.primary,
  },
});

const inputStyle: CSSObject = {
  width: '450px',
  height: '36px',
  fontSize: '14px',
  marginLeft: '5px',
};

export { wrap, rows, startRows, col, btns, inputStyle, row };
