import React, { PropsWithChildren } from 'react';
import icClose from '../static/close.png';
import { wrap, head, contents, foot } from './style';

interface Props {
  title: React.ReactNode;
  footer?: React.ReactNode;
  handleClose: () => void;
  size?: 'small' | 'medium';
}

function Modal({
  children,
  title,
  footer,
  handleClose,
  size = 'medium',
}: PropsWithChildren<Props>) {
  return (
    <div css={wrap(size)}>
      <div css={head(size)}>
        <p>{title}</p>
        <button type="button" onClick={handleClose}>
          <img src={icClose} alt="" width="32" height="32" />
        </button>
      </div>
      <div css={contents(size)}>{children}</div>
      {footer ? (
        footer
      ) : (
        <div css={foot}>
          <button type="button" onClick={handleClose}>
            확인
          </button>
        </div>
      )}
    </div>
  );
}

export default Modal;
