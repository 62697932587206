import React from 'react';

interface Props {
  title: string;
  subText?: string;
}

function BoxTitle({ title, subText }: Props) {
  return (
    <h2 css={{ fontSize: '18px', fontWeight: 500 }}>
      {title}{' '}
      {subText ? (
        <small css={{ fontSize: 14, color: 'grey' }}>{subText}</small>
      ) : null}
    </h2>
  );
}

export default BoxTitle;
