import React, { useRef } from 'react';
import { ModalComponent, UiComponent } from 'components';
import ReactQuill from 'react-quill';

interface Props {
  title: string;
  value: string;
  onClose: () => void;
  onSave: (data: string) => void;
}

function Editor({ title, value, onClose, onSave }: Props) {
  const ref = useRef<ReactQuill>(null);

  const handleSave = () => {
    onSave(String(ref.current?.value || ''));
  };

  return (
    <>
      <ModalComponent.Portal>
        <ModalComponent.Background>
          <ModalComponent.Modal
            title={title}
            handleClose={onClose}
            footer={
              <>
                <UiComponent.Button
                  color="primary"
                  variant="contained"
                  css={{ width: '120px', margin: '0 auto' }}
                  onClick={handleSave}
                >
                  저장
                </UiComponent.Button>
              </>
            }
          >
            <div css={{ height: '500px' }}>
              <ReactQuill
                ref={ref}
                value={value}
                style={{ width: '800px', height: '450px' }}
                modules={{
                  toolbar: [
                    [{ header: [1, 2, 3, 4, 5, 6, false] }],
                    ['bold', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }],
                    [{ color: [] }, { background: [] }],
                  ],
                }}
              />
            </div>
          </ModalComponent.Modal>
        </ModalComponent.Background>
      </ModalComponent.Portal>
    </>
  );
}

export default Editor;
