import { useState } from 'react';
import { IEvent } from 'types';
import * as API from 'api/event';
import useModal from 'hooks/useModal';

interface Props {
  event: IEvent | null;
}

function useOpenKakao({ event }: Props) {
  const { handleAlert } = useModal();
  const [isOpenKakao, setIsOpenKakao] = useState(false);

  const handleSave = (data: IEvent['openKaKao']) => {
    API.putOpenKakao({ id: event?.id || 0, openKaKao: data }).then(() => {
      handleAlert('저장되었습니다.');
      setIsOpenKakao(false);
    });
  };

  return { isOpenKakao, setIsOpenKakao, handleSave };
}

export default useOpenKakao;
