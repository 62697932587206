import dayjs from 'dayjs';
import { IUser } from 'types';

const MOCK_DATA: IUser[] = [
  {
    id: 1,
    ID: 'jisu123',
    name: '최지수',
    email: 'abcd@abcd.com',
    gender: 'male',
    birth: '92.12.27',
    vms: 'jisu9921',
    id1365: 'jisu9921',
    instagram: '',
    joinPath: '',
    terms: '',
    signUpDate: dayjs().format('YYYY-MM-DD HH:mm'),
    status: 'normal',
    phoneNum: '010-1234-1234',
    memo: '',
  },
];

const find = (id: number) => MOCK_DATA.find((item) => item.id === id);

// const get = (id: number) => base.get(`${PATH}/${id}`);
const get = (id: number) =>
  new Promise<{ data: IUser | undefined }>((resolve) =>
    resolve({ data: find(id) }),
  );

// const getAll = () => base.get(PATH);
const getAll = () =>
  new Promise<{ data: IUser[] }>((resolve) => resolve({ data: MOCK_DATA }));

const put = ({
  id,
  vms,
  id1365,
  instagram,
}: Pick<IUser, 'id' | 'vms' | 'instagram' | 'id1365'>) => {
  const idx = MOCK_DATA.findIndex((item) => item.id === id);
  MOCK_DATA[idx] = {
    ...MOCK_DATA[idx],
    vms,
    id1365,
    instagram,
  };

  return new Promise((resolve) => resolve(true));
};

const putStatus = ({ id, status }: { id: number; status: IUser['status'] }) => {
  const idx = MOCK_DATA.findIndex((item) => item.id === id);
  MOCK_DATA[idx].status = status;

  return new Promise((resolve) => resolve(true));
};

const putMemp = ({ id, memo }: { id: number; memo: IUser['memo'] }) => {
  const idx = MOCK_DATA.findIndex((item) => item.id === id);
  MOCK_DATA[idx].memo = memo;

  return new Promise((resolve) => resolve(true));
};

export { get, getAll, put, putStatus, putMemp };
