import React from 'react';
import { EventComponent } from 'components';

function Detail() {
  return (
    <div>
      <EventComponent.Detail />
    </div>
  );
}

export default Detail;
