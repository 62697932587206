import dayjs from 'dayjs';
import { IContent } from 'types';
import { getImageUrl } from 'utils';

const bannerContent: IContent[] = [
  {
    id: 20001,
    title: '연봉인상 운영진 모집',
    pageTitle: '',
    thumbnail: getImageUrl('banner1.jpeg', 2),
    desc: new Array(4)
      .fill('')
      .map((_, i) => getImageUrl(`banner1-${i + 1}.jpeg`, 2)),
    type: 'internal',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 20002,
    title: '연봉인상 봉사자들이 무료로 봉사를 즐길 수 있는 이유',
    pageTitle: '',
    thumbnail: getImageUrl('banner2.jpeg', 2),
    desc: new Array(7)
      .fill('')
      .map((_, i) => getImageUrl(`banner2-${i + 1}.jpeg`, 2)),
    type: 'internal',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 20003,
    title: '연봉인상과 빠니보틀',
    pageTitle: '',
    thumbnail: getImageUrl('banner3.jpeg', 2),
    desc: new Array(6)
      .fill('')
      .map((_, i) => getImageUrl(`banner3-${i + 1}.jpeg`, 2)),
    type: 'internal',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
  {
    id: 20004,
    title: '연탄봉사 가이드',
    pageTitle: '',
    thumbnail: getImageUrl('banner4.jpeg', 2),
    desc: new Array(5)
      .fill('')
      .map((_, i) => getImageUrl(`banner4-${i + 1}.jpeg`, 2)),
    type: 'internal',
    creator: '최*수',
    updater: '최*수',
    createdAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    updatedAt: dayjs().format('YYYY-MM-DD HH:mm:ss'),
  },
];

export { bannerContent };
