import React, { useRef, useState } from 'react';
import { EventComponent, FormComponent, UiComponent } from 'components';
import { useCheck, useDate } from 'hooks';
import dayjs from 'dayjs';
import { CAType, EventFormType, IEvent, IMapPosition } from 'types';
import * as API from 'api/event';
import { useNavigate } from 'react-router-dom';

const CHECK_LIST: {
  id: string;
  displayValue: string;
}[] = [
  { id: '1', displayValue: 'VMS' },
  { id: '2', displayValue: '1365' },
];

interface Props {
  event?: IEvent | null;
}

function Form({ event }: Props) {
  const navigate = useNavigate();
  const mapRef = useRef<{
    getData: () => { _lng: number; _lat: number; mapMarker?: IMapPosition };
    setData: () => void;
  }>(null);

  const [thumbnail, setThumbnail] = useState(event?.thumbnail || '0');
  const [title, setTitle] = useState(event?.title || '');
  const [detail, setDetail] = useState(event?.detail || '');
  const [headcount, setHeadcount] = useState(event?.headcount || '');
  const [address, setAddress] = useState(event?.address || '');

  const dateState = useDate({
    defaultStart: event?.startAt,
    defaultEnd: event?.endAt,
  });
  const enlistDateState = useDate({
    defaultStart: event?.enlistStatrAt,
    defaultEnd: event?.enlistEndAt,
  });

  const checkListProps = useCheck({
    uniqueKey: 'id',
    data: CHECK_LIST,
    isAllChecked: true,
  });

  const handleSubmit = () => {
    if (!mapRef.current) return;
    const { _lat, _lng, mapMarker } = mapRef.current.getData();
    if (!headcount || isNaN(Number(headcount))) return;
    const formData: EventFormType = {
      title,
      detail,
      thumbnail,
      headcount: Number(headcount),
      startAt: dayjs(dateState.start).format('YYYY-MM-DD'),
      endAt: dayjs(dateState.end).format('YYYY-MM-DD'),
      enlistStatrAt: dayjs(enlistDateState.start).format('YYYY-MM-DD'),
      enlistEndAt: dayjs(enlistDateState.end).format('YYYY-MM-DD'),
      ca: checkListProps.idData
        .filter((v) => v.checked)
        .map((v) => v.displayValue as CAType),
      address,
      latitude: _lat,
      longitude: _lng,
      mapMarker,
    };

    if (event) {
      API.put({ id: event.id, data: formData }).then(() => navigate(-1));
    } else {
      API.post(formData).then(() => navigate('/event'));
    }
  };

  return (
    <div css={{ padding: '16px 0' }}>
      <h2 css={{ marginBottom: '24px', fontSize: '18px' }}>행사 등록</h2>
      <UiComponent.Box>
        <div
          css={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <b>기본정보</b>
          <UiComponent.Button
            variant="contained"
            color="primary"
            type="submit"
            css={{ width: '120px' }}
            onClick={handleSubmit}
          >
            {event ? '수정' : '등록'}
          </UiComponent.Button>
        </div>
        <EventComponent.ThumbnailSelector
          thumbnail={thumbnail}
          setThumbnail={setThumbnail}
        />
        <div css={{ display: 'flex', gap: '0 32px' }}>
          <FormComponent.Form
            submit={handleSubmit}
            css={{
              '> input': { width: '100%' },
              '> textarea': { width: '100%' },
              '> p': { margin: '16px 0', fontWeight: '600' },
            }}
          >
            <p>타이틀</p>
            <FormComponent.Input
              name="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
            <p>개요</p>
            <FormComponent.Textarea
              name="detail"
              value={detail}
              onChange={(e) => setDetail(e.target.value)}
            />

            <p>일시</p>
            <FormComponent.DateRangePicker {...dateState} time />

            <p>모집기간</p>
            <FormComponent.DateRangePicker {...enlistDateState} time />

            <p>인증기관</p>
            <div css={{ display: 'flex', gap: '0 24px' }}>
              <FormComponent.CheckList {...checkListProps} uniqueKey="id" />
            </div>

            <p>인원</p>
            <FormComponent.Input
              type="number"
              name="headcount"
              value={headcount}
              onChange={(e) => setHeadcount(e.target.value)}
            />
          </FormComponent.Form>
          <div>
            <p
              css={{
                margin: '16px 0',
                fontWeight: '600',
              }}
            >
              장소
            </p>
            <FormComponent.SearchMap
              ref={mapRef}
              latitude={event?.latitude}
              longitude={event?.longitude}
              mapMarker={event?.mapMarker}
            />
            <p
              css={{
                margin: '16px 0',
                fontWeight: '600',
              }}
            >
              주소입력
            </p>
            <FormComponent.Input
              name="title"
              css={{ width: '500px' }}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </div>
        </div>
      </UiComponent.Box>
    </div>
  );
}

export default Form;
