import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { FormComponent, UiComponent } from 'components';
import { IQuestion, InputType, QUESTION_INPUT_OPTION } from 'types/forms';
import { inputBase } from './style';
import { IoMdClose } from 'react-icons/io';
import { MdDeleteForever } from 'react-icons/md';
import { ComponentProxyRef } from 'types/proxy';

interface Props {
  question: IQuestion;
  handleRemoveQuestion: (id: number) => void;
}

function Question(
  { question, handleRemoveQuestion }: Props,
  ref?: ForwardedRef<ComponentProxyRef<IQuestion>>,
) {
  const titleRef = useRef<HTMLInputElement>(null);
  const [title, setTitle] = useState(question.title);
  const [description, setDescription] = useState(question.description);
  const [isRequired, setIsRequired] = useState(question.isRequired || false);
  const [options, setOptions] = useState(question.options || ['옵션 1']);
  const [type, setType] = useState(question.type);
  const handleRemove = (index: number) => {
    if (options.length === 1) {
      alert('옵션은 최소 1개 이상이어야 합니다.');
      return;
    }
    setOptions(options.filter((_, i) => i !== index));
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => {
    const { value } = e.target;
    options[index] = value;
    setOptions([...options]);
  };

  const handleBlur = (index: number) => {
    if (options[index].length < 1) {
      options[index] = `옵션 ${index + 1}`;
      setOptions([...options]);
    }
  };

  const validate = () => {
    if (!title) {
      titleRef.current?.focus();
      alert('질문 제목을 입력해주세요.');
      return false;
    }

    return true;
  };

  const handleChangeType = (nextType: InputType) => {
    if (nextType === '4') {
      setOptions([]);
    }

    if (nextType === '3') {
      setOptions(['옵션', '옵션']);
    }

    setType(nextType);
  };

  const setData = (data: IQuestion) => {
    setTitle(data.title);
    setDescription(data.description);
    setIsRequired(data.isRequired || false);
    setType(data.type);
    setOptions(data.options || ['옵션 1']);
  };

  useImperativeHandle(ref, () => ({
    setData,
    getData: () => {
      return {
        id: question.id,
        description,
        type,
        title,
        options,
        isRequired,
      };
    },
    validate,
  }));

  return (
    <>
      <UiComponent.Box>
        <div css={{ display: 'flex', gap: '0 8px' }}>
          <input
            ref={titleRef}
            type="text"
            css={[inputBase, { height: '36px', fontSize: '18px' }]}
            placeholder="제목없는 질문"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <FormComponent.Select
            value={type}
            options={[...QUESTION_INPUT_OPTION]}
            handleChange={handleChangeType}
          />
        </div>
        <textarea
          css={[
            inputBase,
            {
              marginTop: '16px',
              resize: 'none',
              boxSizing: 'border-box',
              fontSize: '16px',
            },
          ]}
          rows={3}
          placeholder="질문 설명을 입력해주세요"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        {type !== '4' && (
          <div css={{ marginTop: '32px' }}>
            {options.map((item, index) => (
              <div
                key={index}
                css={{
                  display: 'flex',
                  gap: '0 8px',
                  alignItems: 'center',
                  marginBottom: '8px',
                }}
              >
                <span>{index + 1}</span>
                <input
                  css={[inputBase, { height: '32px', fontSize: '16px' }]}
                  value={item}
                  onChange={(e) => handleChange(e, index)}
                  onBlur={() => handleBlur(index)}
                />
                <button type="button" onClick={() => handleRemove(index)}>
                  <IoMdClose size={20} />
                </button>
              </div>
            ))}
            {type !== '3' && (
              <div
                css={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: '16px',
                }}
              >
                <UiComponent.Button
                  variant="border"
                  color="black"
                  onClick={() => {
                    if (options.length > 4) {
                      alert('옵션은 최대 5개까지만 추가할 수 있습니다.');
                      return;
                    }

                    setOptions([...options, `옵션 ${options.length + 1}`]);
                  }}
                >
                  옵션추가
                </UiComponent.Button>
              </div>
            )}
          </div>
        )}
        <div
          css={{
            display: 'flex',
            gap: '0 8px',
            alignItems: 'center',
            marginTop: '12px',
          }}
        >
          <FormComponent.CheckBox
            checked={isRequired}
            handleCheck={setIsRequired}
          >
            필수
          </FormComponent.CheckBox>
          <div css={{ height: '18px', width: '1px', background: '#eee' }} />
          <button
            type="button"
            css={{ display: 'flex', alignItems: 'center', fontSize: '18px' }}
            onClick={() => handleRemoveQuestion(question.id)}
          >
            <MdDeleteForever size={24} />
            삭제
          </button>
        </div>
      </UiComponent.Box>
    </>
  );
}

export default forwardRef(Question);
