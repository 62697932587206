import React from 'react';

import { Outlet } from 'react-router-dom';
import Header from '../ui/Header';
import SideNav from '../ui/SideNav';
import { wrap, contentsWrap } from './style';

function Main() {
  return (
    <>
      <Header />
      <main css={wrap}>
        <SideNav />
        <section css={contentsWrap}>
          <div>
            <Outlet />
          </div>
        </section>
      </main>
    </>
  );
}

export default Main;
