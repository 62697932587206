import React, { useCallback, useEffect, useState } from 'react';
import { MdOutlineMoreVert } from 'react-icons/md';
import { usePopper } from 'react-popper';
import * as API from 'api/event';
import { IEvent } from 'types';
import { useNavigate } from 'react-router-dom';
import { EVENT_MENU_URL } from 'data/menu';

interface Props {
  event: IEvent;
  refetch: () => void;
}

function Action({ event, refetch }: Props) {
  const navigate = useNavigate();
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleOff = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    window.document.addEventListener('click', handleOff);

    () => window.document.removeEventListener('click', handleOff);
  }, []);

  const put = (status: IEvent['status']) => {
    API.putStatus({ id: event.id, status }).then(() => {
      setIsOpen(false);
      refetch();
    });
  };

  if (event.status === '행사취소') return null;

  return (
    <>
      <div
        css={{ position: 'absolute', right: '12px' }}
        ref={setReferenceElement}
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen(!isOpen);
        }}
      >
        <MdOutlineMoreVert size={32} />
      </div>
      {isOpen && (
        <div
          onClick={(e) => e.stopPropagation()}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          css={{
            backgroundColor: 'white',
            padding: '12px 8px',
            borderRadius: '4px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
          }}
        >
          <ul
            css={{
              '> li': {
                padding: '8px 40px 8px 8px',
                cursor: 'pointer',
                fontSize: '12px',
                '&:hover': {
                  backgroundColor: '#f7f7f7',
                },
              },
            }}
          >
            <li
              onClick={() =>
                navigate(`${EVENT_MENU_URL['행사']}/${event.id}/edit`)
              }
            >
              기본정보 수정
            </li>
            {event.status === '대기' && (
              <li onClick={() => put('모집예정')}>행사 승인</li>
            )}
            {event.status.startsWith('모집') && (
              <li onClick={() => put('진행완료')}>진행 완료</li>
            )}
            {event.status !== '진행완료' && (
              <li onClick={() => put('행사취소')}>행사 취소</li>
            )}
          </ul>
        </div>
      )}
    </>
  );
}

export default Action;
