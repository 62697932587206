import React from 'react';
import { EventComponent } from 'components';

function Register() {
  return (
    <>
      <EventComponent.Form />
    </>
  );
}

export default Register;
