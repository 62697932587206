import React from 'react';

import { UserPage, CouponPage } from 'pages';
import { USER_MENU_URL } from 'data/menu';

const userApp = [
  {
    path: USER_MENU_URL.회원목록,
    element: <UserPage.List />,
  },
  {
    path: `${USER_MENU_URL.회원목록}/:id`,
    element: <UserPage.Detail />,
  },
  {
    path: USER_MENU_URL.쿠폰목록,
    element: <CouponPage.List />,
  },
];

export { userApp };
