import React, { useCallback, useEffect, useState } from 'react';
import { MdOutlineMoreVert } from 'react-icons/md';
import { usePopper } from 'react-popper';
import * as API from 'api/partner';

import { IPartner } from 'types';

interface Props {
  item: IPartner;
  selectedItem: IPartner | null;
  setSelectedItem: (item: IPartner | null) => void;
  setEditItem: (item: IPartner | null) => void;
  refetch: () => void;
}

function Item({
  item,
  selectedItem,
  refetch,
  setSelectedItem,
  setEditItem,
}: Props) {
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null,
  );
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom-end',
  });

  const handleOff = useCallback(() => setSelectedItem(null), []);

  const handleDisable = (disabled: boolean) => {
    API.put({ ...item, disabled }).then(() => {
      handleOff();
      refetch();
    });
  };

  useEffect(() => {
    window.document.addEventListener('click', handleOff);

    () => window.document.removeEventListener('click', handleOff);
  }, []);

  return (
    <>
      <div
        css={{
          position: 'relative',
          width: '160px',
          height: '160px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          background: '#f5f5f5',
          borderRadius: '20px',
          userSelect: 'none',
        }}
      >
        <img src={item.thumbnail} alt="" width={120} />
        {item.disabled && (
          <div
            css={{
              position: 'absolute',
              width: '160px',
              height: '160px',
              background: 'black',
              borderRadius: '20px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              opacity: 0.9,
            }}
          >
            <p css={{ color: '#fff', fontWeight: 'bold' }}>사용중지</p>
          </div>
        )}
      </div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div>
          <p
            css={{
              fontSize: '14px',
              userSelect: 'none',
              fontWeight: 'bold',
              marginTop: '12px',
            }}
          >
            {item.name}
          </p>
          <p
            css={{
              fontSize: '13px',
              userSelect: 'none',
              marginTop: '4px',
              color: 'grey',
            }}
          >
            {item.description}
          </p>
        </div>
        <div
          ref={setReferenceElement}
          onClick={(e) => {
            e.stopPropagation();
            setSelectedItem(item);
          }}
        >
          <MdOutlineMoreVert size={24} />
        </div>
      </div>
      {selectedItem?.id === item.id && (
        <div
          onClick={(e) => e.stopPropagation()}
          ref={setPopperElement}
          style={styles.popper}
          {...attributes.popper}
          css={{
            backgroundColor: 'white',
            padding: '12px 8px',
            borderRadius: '4px',
            boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
            zIndex: 1,
          }}
        >
          <ul
            css={{
              '> li': {
                padding: '8px 40px 8px 8px',
                cursor: 'pointer',
                fontSize: '12px',
                '&:hover': {
                  backgroundColor: '#f7f7f7',
                },
              },
            }}
          >
            <li
              onClick={() => {
                setSelectedItem(null);
                setEditItem(item);
              }}
            >
              수정
            </li>
            {item.disabled ? (
              <li onClick={() => handleDisable(false)}>사용중지 해제</li>
            ) : (
              <li onClick={() => handleDisable(true)}>사용중지</li>
            )}
          </ul>
        </div>
      )}
    </>
  );
}

export default Item;
