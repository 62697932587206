import React from 'react';
import { wrap } from './style';

interface Props {
  alertTitle?: string;
  onClose: () => void;
}

function Alert({
  alertTitle,
  children,
  onClose,
}: React.PropsWithChildren<Props>) {
  return (
    <div css={wrap}>
      <div
        css={{
          whiteSpace: 'pre-wrap',
          textAlign: 'center',
          lineHeight: '1.4',
        }}
      >
        {children}
      </div>
      <hr />
      <button type="button" onClick={onClose}>
        {alertTitle || '확인'}
      </button>
    </div>
  );
}

export default Alert;
