import { IPartner } from 'types';
import { MOCK_DATA } from 'data/partner';

const find = (id: number) => MOCK_DATA.find((item) => item.id === id);

const get = (id: number) =>
  new Promise<{ data: IPartner | undefined }>((resolve) =>
    resolve({ data: find(id) }),
  );

// const getAll = () => base.get(PATH);
const getAll = () =>
  new Promise<{ data: IPartner[] }>((resolve) => resolve({ data: MOCK_DATA }));

const post = (data: Omit<IPartner, 'id'>) => {
  const partner: IPartner = {
    ...data,
    id: Math.max(...MOCK_DATA.map((item) => item.id)) + 1,
  };

  MOCK_DATA.push(partner);

  return new Promise((resolve) => resolve(true));
};

const put = (data: IPartner) => {
  const idx = MOCK_DATA.findIndex((item) => item.id === data.id);
  MOCK_DATA[idx] = data;

  return new Promise((resolve) => resolve(true));
};

export { get, getAll, post, put };
