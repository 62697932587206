import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { FormComponent, UiComponent } from 'components';
import { usePageOption } from 'hooks';
import { tableWrap, tableTop } from 'styles/table';
import { ICollection } from 'types';
import { Link } from 'react-router-dom';
import * as API from 'api/collection';
import { BsPlusLg } from 'react-icons/bs';

const COLS: string[] = [
  'ID',
  '컬렉션명',
  '타이틀',
  '서브타이틀',
  '유형',
  '등록자',
  '등록일',
];

function List() {
  const [data, setData] = useState<ICollection[]>([]);

  const pageState = usePageOption();
  const renderer: Record<
    (typeof COLS)[number],
    (data: ICollection & { row: number }) => ReactNode
  > = useMemo(
    () => ({
      ID: ({ id }: ICollection) => <Link to={'/users/1'}>{id}</Link>,
      컬렉션명: ({ name }: ICollection) => <>{name}</>,
      타이틀: ({ title }: ICollection) => <>{title}</>,
      서브타이틀: ({ subtitle }: ICollection) => <>{subtitle}</>,
      유형: ({ type }: ICollection) => <>{getTypeText(type)}</>,
      등록자: ({ creator }: ICollection) => <>{creator}</>,
      등록일: ({ createdAt }: ICollection) => <>{createdAt}</>,
    }),
    [],
  );

  const fetch = () => {
    API.getAll().then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => {
    fetch();
  }, []);

  return (
    <UiComponent.Box>
      <div css={tableTop}>
        <p>총 256 건</p>
        <div>
          <UiComponent.Button variant="contained" color="primary">
            <BsPlusLg size={18} />
          </UiComponent.Button>
          <FormComponent.Select
            options={[
              { name: '20', value: '20' },
              { name: '50', value: '50' },
              { name: '100', value: '100' },
            ]}
          />
        </div>
      </div>
      <div css={tableWrap}>
        <UiComponent.Table cols={COLS} renderer={renderer} data={data} sticky />
      </div>
      <UiComponent.Pagination {...pageState} />
    </UiComponent.Box>
  );
}

const getTypeText = (type: ICollection['type']) => {
  switch (type) {
    case 'main':
      return '메인배너';
    case 'belt':
      return '띠배너';
    case 'default':
      return '기본';
    default:
      return '';
  }
};

export default List;
