import { Interpolation, Theme } from '@emotion/react';
import React, {
  ButtonHTMLAttributes,
  ForwardedRef,
  PropsWithChildren,
} from 'react';
import { listBtnStyle } from './style';

type ColorType = '1' | '2' | '3';

const COLOR: Record<ColorType, string> = {
  '1': '#cba3e9',
  '2': '#e8a828',
  '3': '#666',
};

interface Props
  extends PropsWithChildren<ButtonHTMLAttributes<HTMLButtonElement>> {
  colorType: ColorType;
  css?: Interpolation<Theme>;
}

function ListButton(
  { children, colorType, css, ...rest }: Props,
  ref: ForwardedRef<HTMLButtonElement>,
) {
  return (
    <button
      type="button"
      {...rest}
      ref={ref}
      css={[listBtnStyle(COLOR[colorType]), css]}
    >
      {children}
    </button>
  );
}

export default React.forwardRef(ListButton);
