import React, { Fragment, useEffect, useState } from 'react';

import { BsPlusLg, BsTrash } from 'react-icons/bs';
import { detailWrap } from 'styles/table';
import CollectionModal from './CollectionModal';
import { SectionType } from 'types';
import * as API from 'api/home';
import Preview from './Preview';

function Manage() {
  const [data, setData] = useState<SectionType[]>([]);
  const [isOpened, setIsOpened] = React.useState(false);

  const handleRemove = (id: number) => {
    API.del(id).then(() => fetch());
  };

  const handleAdd = (section: SectionType) => {
    API.add(section).then(() => fetch());
  };

  const fetch = () => {
    API.get().then((res) => setData(res.data));
  };

  useEffect(() => fetch(), []);

  return (
    <>
      <div
        css={[
          detailWrap,
          { marginTop: ' 24px', display: 'flex', gap: '0 24px' },
        ]}
      >
        <div
          css={{
            width: '325px',
            display: 'flex',
            flexDirection: 'column',
            height: '650px',
            border: '8px solid black',
            borderRadius: '8px',
            overflowY: 'scroll',
          }}
        >
          {data.map((d) => (
            <Fragment key={d.id}>
              <Preview section={d} />
            </Fragment>
          ))}
        </div>
        <div css={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
          <div
            css={{
              display: 'flex',
              alignItems: 'center',
              gap: '0 8px',
              '> p': { fontWeight: 'bold', fontSize: '18px' },
            }}
          >
            <p>컬렉션</p>
            <BsPlusLg
              size={16}
              css={{ cursor: 'pointer' }}
              onClick={() => setIsOpened(true)}
            />
          </div>
          <div
            css={{
              width: '100%',
              '> div': {
                padding: '8px 16px',
                border: '1px solid black',
                borderRadius: '4px',
                marginTop: '12px',
              },
            }}
          >
            {data.map((d) => (
              <div
                key={d.id}
                css={{ display: 'flex', justifyContent: 'space-between' }}
              >
                <p>{d.title}</p>
                <BsTrash
                  css={{ cursor: 'pointer' }}
                  onClick={() => handleRemove(d.id)}
                />
              </div>
            ))}
          </div>
        </div>
      </div>

      {isOpened && (
        <CollectionModal
          handleClose={() => setIsOpened(false)}
          handleSelect={handleAdd}
        />
      )}
    </>
  );
}

export default Manage;
