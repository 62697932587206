import React, { ReactNode, useEffect, useMemo, useState } from 'react';

import { detailWrap } from 'styles/table';
import { UiComponent } from 'components';
import { usePageOption } from 'hooks';
import { tableWrap } from 'styles/table';
import { IEvent } from 'types';
import { Link } from 'react-router-dom';
import * as API from 'api/event';

const COLS = ['ID', '단체명', '봉사명', '봉사시간'];

function Volunteer() {
  const [data, setData] = useState<IEvent[]>([]);
  const pageState = usePageOption();
  const renderer: Record<
    (typeof COLS)[number],
    (data: IEvent & { row: number }) => ReactNode
  > = useMemo(
    () => ({
      ID: ({ id }: IEvent) => <Link to={'/event/1'}>{id}</Link>,
      단체명: ({ organization }: IEvent) => <>{organization}</>,
      봉사명: ({ title }: IEvent) => <>{title}</>,
      봉사시간: () => 4,
    }),
    [],
  );

  const fetch = () => {
    API.getAll().then((res) => {
      setData(res.data);
    });
  };

  useEffect(() => fetch(), []);

  return (
    <div css={[detailWrap, { marginTop: '24px' }]}>
      <h3>봉사활동 참여내역</h3>
      <div css={tableWrap}>
        <UiComponent.Table cols={COLS} renderer={renderer} data={data} sticky />
      </div>
      <UiComponent.Pagination {...pageState} />
    </div>
  );
}

export default Volunteer;
