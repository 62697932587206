import React from 'react';
import { detailWrap } from 'styles/table';
import imgProfile from 'static/user/profile.svg';
import dayjs from 'dayjs';
import Action from './Action';
import useDetail from '../useDetail';

function Main() {
  const { user, refetch } = useDetail();
  if (!user) return null;

  return (
    <div css={[detailWrap, { marginTop: '24px', position: 'relative' }]}>
      <h3>회원정보</h3>
      <Action user={user} refetch={refetch} />
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: '24px',
          gap: '0 48px',
        }}
      >
        <div
          css={{
            width: '140px',
            height: '140px',
            background: '#FFF4DE',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img
            src={imgProfile}
            alt="thumbnail"
            css={{ borderRadius: '50%', width: '120px', height: '120px' }}
          />
        </div>

        <ul
          css={{
            '> li': {
              display: 'flex',
              marginBottom: '12px',
            },
            '> li > div': {
              width: '200px',
              display: 'flex',
              gap: '0 8px',
              'p:nth-of-type(1)': {
                fontWeight: 'bold',
              },
              '> p': {
                fontSize: '14px',
              },
            },
          }}
        >
          <li>
            <div>
              <p>회원상태:</p>
              <p>{user.status === 'block' ? '이용정지' : '정상'}</p>
            </div>
            <div>
              <p>이름:</p>
              <p>{user.name}</p>
            </div>
          </li>

          <li>
            <div>
              <p>아이디:</p>
              <p>{user.ID}</p>
            </div>
            <div>
              <p>이메일:</p>
              <p>{user.email}</p>
            </div>
          </li>

          <li>
            <div>
              <p>생년월일:</p>
              <p>{user.birth}</p>
            </div>
            <div>
              <p>성별:</p>
              <p>{user.gender === 'male' ? '남' : '여'}</p>
            </div>
          </li>
          <li>
            <div>
              <p>휴대폰번호:</p>
              <p>{user.phoneNum || '-'}</p>
            </div>
            <div>
              <p>인스타ID</p>
              <p>{user.instagram || '-'}</p>
            </div>
          </li>
          <li>
            <div>
              <p>vms 아이디:</p>
              <p>{user.vms || '-'}</p>
            </div>
            <div>
              <p>1365 아이디:</p>
              <p>{user.id1365 || '-'}</p>
            </div>
          </li>
          <li>
            <div>
              <p>가입일</p>
              <p>{dayjs(user.signUpDate).format('YYYY-MM-DD')}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Main;
