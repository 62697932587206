import { Theme } from '@emotion/react';

const theme: Theme = {
  color: {
    white: '#FFFFFF',
    black: '#000000',
    primary: '#000000',
    grey: '#666666',
    success: '#2196f3',
    error: '#ed4337',
    disabled: '#d3d3d3',
    background: '#f7f7f7',
    border: '#ddd',
  } as const,
};

export default theme;
