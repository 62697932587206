import React from 'react';
import { RouteObject } from 'react-router-dom';
import { LayoutComponent } from 'components';
import { userApp } from './userApp';
import { eventApp } from './eventApp';
import { platformApp } from './platformApp';
import { noticeApp } from './noticeApp';

export const privateApp: RouteObject = {
  element: <LayoutComponent.Private />,
  children: [
    {
      element: <LayoutComponent.Main />,
      children: [
        ...userApp,
        ...eventApp,
        ...platformApp,
        ...noticeApp,
        {
          path: '*',
          element: (
            <>
              <h1 css={{ marginTop: '24px' }}>준비중...</h1>
            </>
          ),
        },
      ],
    },
  ],
};
