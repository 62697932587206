import { keyframes, CSSObject } from '@emotion/react';

const rotate = keyframes`
  from { 
    transform: rotate(0); 
  }
  to { 
    transform: rotate(360deg); 
  }
`;

const wrap = (isFetching?: boolean): CSSObject => ({
  animation: isFetching ? `${rotate} 0.8s linear infinite` : 'none',
});

export { wrap };
