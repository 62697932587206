import { CSSObject, Theme } from '@emotion/react';

const wrap = (time: boolean): CSSObject => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '10px 5px',
  '> div': {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '> p': {
      margin: '0 4px',
    },
    '> button': {
      width: time ? '66px' : '90px',
    },
  },
});

const btns: CSSObject = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '0 5px',
  marginTop: '2px',
};

const btn =
  (isSelected: boolean) =>
  (theme: Theme): CSSObject => ({
    height: '36px',
    textAlign: 'center',
    marginRight: '5px',
    fontSize: '14px',
    border: '1px solid',
    borderColor: isSelected ? theme.color.primary : '#eee',
    borderRadius: '3px',
  });

export { wrap, btns, btn };
