export interface ICoupon {
  id: number;
  type: string;
  status: 'created' | 'issued' | 'used';
  issuedUserId?: number;
  issuedUserName?: string;
  usedId?: number;
  endAt?: string;
  startAt?: string;
  createdAt: string;
  creator: string;
}

export const COUPON_STATUS_NAME: Record<ICoupon['status'], string> = {
  created: '발행완료',
  issued: '지급완료',
  used: '사용완료',
};
