import React from 'react';

import { FormComponent, ModalComponent, UiComponent } from 'components';
import { IPartner } from 'types';
import useModal from 'hooks/useModal';

interface Props {
  partner?: IPartner;
  onClose: () => void;
  onSave: (partner: IPartner) => void;
}

function Modal({ partner, onClose, onSave }: Props) {
  const { handleAlert } = useModal();
  const [fileUrl, setFileUrl] = React.useState(partner?.thumbnail || '');
  const handleFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setFileUrl(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const submit = (data: IPartner) => {
    if (!fileUrl) {
      handleAlert('로고를 업로드해주세요');
      return;
    }

    onSave({ ...data, thumbnail: fileUrl });
  };

  return (
    <ModalComponent.Portal>
      <ModalComponent.Background>
        <ModalComponent.Modal title="" handleClose={onClose} footer={<></>}>
          <FormComponent.Form
            submit={submit}
            css={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px 0',
              width: '500px',
            }}
          >
            <p>로고</p>
            <input type="file" name="thumbnail" onChange={handleFile} />
            {fileUrl && (
              <div
                css={{
                  width: '160px',
                  height: '160px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#f5f5f5',
                  borderRadius: '20px',
                  userSelect: 'none',
                }}
              >
                <img src={fileUrl} alt="thumbnail" width={120} />
              </div>
            )}
            <p>링크</p>
            <FormComponent.Input
              type="text"
              name="link"
              defaultValue={partner?.link}
            />
            <p>이름</p>
            <FormComponent.Input
              type="text"
              name="name"
              defaultValue={partner?.name}
            />
            <p>설명</p>
            <FormComponent.Input
              type="text"
              name="description"
              defaultValue={partner?.description}
            />
            <UiComponent.Button
              type="submit"
              variant="contained"
              color="primary"
              css={{ width: '120px', margin: '0 auto' }}
            >
              저장
            </UiComponent.Button>
          </FormComponent.Form>
        </ModalComponent.Modal>
      </ModalComponent.Background>
    </ModalComponent.Portal>
  );
}

export default Modal;
