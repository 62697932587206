import { useAppDispatch, useAppSelector } from 'stores';
import dayjs from 'dayjs';
import { useCallback, useEffect, useRef } from 'react';
import { logout } from 'stores/auth';

const checkIsExpired = (expireTime: number, loginAt: string) => {
  return dayjs().isAfter(dayjs(loginAt).add(expireTime, 'milliseconds'));
};

const getDiffFromNow = (loginAt: string, after: number) => {
  const now = new Date();
  return Math.abs(dayjs(now).diff(dayjs(loginAt).add(after, 'milliseconds')));
};

const DEFAULT_TIMEOUT = 4 * 60 * 60 * 1000; // 4시간

/**
 *
 * @param expireTime 토큰 만료시간
 * @description 토큰 refresh 기능이 존재하지 않으므로 브라우저에서 사용자의 로그인 시각에 따라
 * 임의로 로그아웃 시킨다.
 */
function useAutoRemoveAuth(expireTime = DEFAULT_TIMEOUT) {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const dispatch = useAppDispatch();
  const { loginAt } = useAppSelector((state) => state.auth);

  const setSessionExpire = useCallback((diff: number) => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    timeoutRef.current = setTimeout(() => {
      dispatch(logout());
    }, diff);
  }, []);

  const clearSessionExpire = useCallback(() => {
    timeoutRef.current && clearTimeout(timeoutRef.current);
  }, []);

  useEffect(() => {
    if (!loginAt) {
      clearSessionExpire();
      return;
    }

    if (checkIsExpired(expireTime, loginAt)) {
      dispatch(logout());
      return;
    }

    setSessionExpire(getDiffFromNow(loginAt, expireTime));

    return () => {
      clearSessionExpire();
    };
  }, [expireTime, loginAt]);
}

export default useAutoRemoveAuth;
