import React, { ForwardedRef, Ref, SelectHTMLAttributes } from 'react';
import { ISelectOption } from 'types';
import { wrap } from './style';

interface Props<T extends string>
  extends SelectHTMLAttributes<HTMLSelectElement> {
  options: ISelectOption<T>[];
  value?: T;
  defaultValue?: T;
  defaultTitle?: string;
  handleChange?: (option: T, e?: React.ChangeEvent<HTMLSelectElement>) => void;
  readonly?: boolean;
}

function Select<T extends string>(
  { options, defaultTitle, handleChange, readonly, ...props }: Props<T>,
  ref: ForwardedRef<HTMLSelectElement>,
) {
  return (
    <select
      {...props}
      css={wrap({ readonly })}
      onChange={(e) => handleChange?.(e.target.value as T, e)}
      ref={ref}
    >
      {defaultTitle !== undefined && (
        <option value="">{defaultTitle || '전체'}</option>
      )}
      {options.map((option, i) => (
        <React.Fragment key={i}>
          <option value={option.value} disabled={option.disabled}>
            {option.name}
          </option>
        </React.Fragment>
      ))}
    </select>
  );
}

export default React.forwardRef(Select) as <T extends string>(
  p: Props<T> & { ref?: Ref<HTMLSelectElement> },
) => JSX.Element;
