import React from 'react';

import { FormComponent, ModalComponent, UiComponent } from 'components';

interface Props {
  onClose: () => void;
}

function Password({ onClose }: Props) {
  return (
    <ModalComponent.Portal>
      <ModalComponent.Background>
        <ModalComponent.Modal title="" handleClose={onClose} footer={<></>}>
          <FormComponent.Form
            css={{
              display: 'flex',
              flexDirection: 'column',
              width: '500px',
              gap: '12px 0',
            }}
            submit={onClose}
          >
            <p>신규 비밀번호</p>
            <FormComponent.Input type="password" autoComplete="off" />

            <UiComponent.Button
              type="submit"
              color="primary"
              variant="contained"
              css={{ width: '120px', margin: '0 auto' }}
            >
              저장
            </UiComponent.Button>
          </FormComponent.Form>
        </ModalComponent.Modal>
      </ModalComponent.Background>
    </ModalComponent.Portal>
  );
}

export default Password;
