import { useState } from 'react';
import { IUser } from 'types';
import * as API from 'api/user';
import useModal from 'hooks/useModal';

interface Props {
  user: IUser;
  refetch: () => void;
}

function useUserMemo({ user, refetch }: Props) {
  const { handleAlert } = useModal();
  const [isOpenUserMemo, setIsOpenUserMemo] = useState(false);

  const handleSave = ({ memo }: { memo: string }) => {
    API.putMemp({ id: user.id, memo }).then(() => {
      handleAlert('저장되었습니다.');
      setIsOpenUserMemo(false);
      refetch();
    });
  };

  return { isOpenUserMemo, setIsOpenUserMemo, handleSave };
}

export default useUserMemo;
